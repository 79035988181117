import * as t from '../types';
import {GetPackage} from '../../services'


export const getPackage = (slug) => async (dispatch) => {

    dispatch({
        type: t.GET_PACKAGE_REQUEST
    });

    return await GetPackage(slug)

        .then((data) => {
            dispatch({
                type: t.GET_PACKAGE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_PACKAGE_FAILED,
                payload: err.response
            });
        });
};
