import apiRequest from "../../helpers/apiRequest";

export default async (email) => {
    const params = {
        email: email
    }
    return await apiRequest({
        method: 'post',
        url: `accounts/custom-password-reset/`,
        data: params
    })
}
