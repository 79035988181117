import * as t from '../types';
import {MySessionsOneToOne} from '../../services'


export const mySessionsOneToOne = (slug, passedDate) => async (dispatch) => {

    dispatch({
        type: t.MY_SESSIONS_ONE_TO_ONE_REQUEST
    });

    return await MySessionsOneToOne(slug, passedDate)

        .then((data) => {
            dispatch({
                type: t.MY_SESSIONS_ONE_TO_ONE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.MY_SESSIONS_ONE_TO_ONE_FAILED,
                payload: err.response
            });
        });
};
