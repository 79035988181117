import * as t from '../types';
import {UnAvailableTutor} from '../../services'

export const unAvailableTutorAction = (tutor) => async (dispatch) => {
    dispatch({
        type: t.GET_UNAVAILABLE_TUTOR_REQUEST
    });

    return await UnAvailableTutor(tutor)
        .then((data) => {
            dispatch({
                type: t.GET_UNAVAILABLE_TUTOR_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_UNAVAILABLE_TUTOR_FAILED,
                payload: err.response
            });
        });
};
