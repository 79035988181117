import apiRequest from "../../helpers/apiRequest";

export default async (degree) => {
    if (degree) {
        return await apiRequest({
            method: 'get',
            url: `studies/studymodules/?degree_id=${degree}`,
        })
    } else {
        return await apiRequest({
            method: 'get',
            url: `studies/studymodules/`,
        })
    }

}
