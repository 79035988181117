import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Xarrow from "react-xarrows";
import Container from "../../components/container";
import CheckBoxQuestion from "../../components/CheckBoxQuestion";
import Button from "../../components/EmptyButton";
import { createErrorAlert, getCatalogue } from "../../redux/actions";
import { apiRequest, getApiKey, getUser, slugToText } from "../../helpers";
import { getQuizQuestion, getUserInfo, quizResults } from "../../redux/actions";
import {
  catalogueData,
  quizQuestionList,
  quizQuestionLoading,
  userData,
} from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";
import MatchCard from "../../components/Quiz/MatchCards";
import BreadCrumb from "../../components/BreadCrumb";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import _ from "lodash";
let shuffleVar;

const PaidUsers = ({
  match,
  getQuizQuestion,
  quizQuestionList,
  loading,
  user_detail,
  getUserInfo,
  location,
  quizResults,
  dataCatalogue,
  getCatalogue
}) => {
  const [arrows, setArrows] = useState([]);
  const [quizData, setQuizData] = useState();
  const [user, setUser] = useState(JSON.parse(getUser()));
  const [score, setScore] = useState();
  const [answers, setAnswers] = useState([]);
  const [userAnswersSingle, setUserAnswers] = useState([]);
  const [userAnswersMulti, setUserAnswersMulti] = useState([]);
  const [quiz_ids, setQuizIds] = useState();
  const [questCount, setQuestCount] = useState([]);
  const [shuffledAnswers, setShuffledAnswers] = useState([])
  const [match_answers, setMatchAnswers] = useState([]);
  const [paidModules, setPaidModules] = useState([])

  const history = useHistory();
  const [ids, setId] = useState(false);
  const addArrow = ({ start, end }) => {
    setArrows([...arrows, { start, end }]);
  };
  const dispatch = useDispatch();
  const param = useParams()
  const slug1 = location?.state?.value;
  const slug2 = location?.state?.state;
  let matches = JSON.parse(localStorage.getItem("matchAnswer"));

  const breadCrumbItem = [
    { title: "Get study modules", link: "/" },
    {
      title: slugToText(slug1),
      link: `/study-module/${slug1}`,
    },
    { title: "Quiz", link: `/${slug1}/quizzes/` },
  ];
  useEffect(() => {
    getCatalogue()
    if (quizQuestionList.length > 0) {
      window.location.reload();
    }
    getQuizQuestion(match?.params?.slug);
    getUserInfo();
    if (window.location.pathname === `/quiz/${slug2}`) {
      localStorage.removeItem("matchAnswer");
    }
  }, []);

  useEffect(() => {
    for(let i=0; i<dataCatalogue?.data?.length; i++){
        if(dataCatalogue?.data[i]?.is_paid === true){
            if(!paidModules.includes(dataCatalogue?.data[i]?.study_module?.slug)){
                paidModules.push(dataCatalogue?.data[i]?.study_module?.slug)
            }            
        }
    }

},[dataCatalogue])

  useEffect(() => {
    if(quizData?.length > 0){
      for (let i = 0; i < quizData?.length; i++) {
        for (let j = 0; j < quizData[i]?.match_cases.length; j++) {
          for (
            let k = 0;
            k < quizData[i].match_cases[j].sub_matches.length;
            k++
          ) {
            match_answers.push({
              match_answer:
                quizData[i].match_cases[j].sub_matches[k].match_answer,
              status: quizData[i].match_cases[j].sub_matches[k].status,
              match_question:
                quizData[i].match_cases[j].sub_matches[k].match_question,
            });
          }
        }
      }
      const shuffledArray = shuffleArray(match_answers);
      setShuffledAnswers(shuffledArray);
    }
  }, [quizData]);
  useEffect(() => {
    if (user_detail) {
      setUser(user_detail);
    }
    if (quizQuestionList?.length > 0) {
      for (let i = 0; i < quizQuestionList.length; i++) {
        if (quizQuestionList[i].options.length > 0) {
          for (let j = 0; j < quizQuestionList[i].options.length; j++) {
            if (
              quizQuestionList[i].question &&
              quizQuestionList[i].options[j].is_correct === true
            ) {
              answers.push({
                quesion: quizQuestionList[i].question,
                correct: quizQuestionList[i].options[j],
              });
            }
          }
        }
      }

      quizQuestionList?.map((obj) => {
        obj.match_cases.map((item) => {
          item.selected = false;
        });
      });
      setQuizData(quizQuestionList);
    }
    if (
      !getApiKey() ||
      (getApiKey() &&
        (user.subscription_name === "Basic Subscription" || user?.subscription_name.includes('STARTER')) &&
        user?.free_trial === false &&
        user?.user_type !== "TEACHER" && !paidModules.includes(slug1))
    ) {
      idsCheck("blur_quiz");
    } else {
      idsCheck("");
    }
  }, [quizQuestionList]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const resultShow = () => {
    let arr;
    for (let i = 0; i < quizData.length; i++) {
      arr = quizData.filter(
        (obj) =>
          !(obj.question.includes("Match") || obj.question.includes("match")) &&
          quizData[i].match_cases.length === 0
      );
      if (arr.length > questCount.length) {
        dispatch(
          createErrorAlert(201, "Error", "Please submit all answers", 201)
        );
        return;
      }
    }
    quizResults(userAnswersSingle, userAnswersMulti, matches, quiz_ids).then(
      (res) => {
        if (res.data) {
          history.push({
            pathname: `/quiz-results`,
            state: {
              userAnswers: res.data,
              total: arr.length,
              current: slugToText(slug2),
            },
          });
        }
      }
    );
  };

  const handleMulti = (
    checked,
    statements,
    title,
    count,
    quiz_id,
    quiz_question_id
  ) => {
    if (!questCount.includes(title)) {
      questCount.push(title);
    }
    setQuizIds(quiz_id);

    if (userAnswersMulti.length === 0) {
      userAnswersMulti.push({
        multiple_option_id: count,
        quiz_id: quiz_id,
        multiple_question_statement: title,
        quiz_question_id: quiz_question_id,
      });
    } else if (userAnswersMulti.length !== 0) {
      for (let i = 0; i < userAnswersMulti.length; i++) {
        if (statements === userAnswersMulti[i]?.statements) {
          userAnswersMulti.push({
            multiple_option_id: count,
            quiz_id: quiz_id,
            multiple_question_statement: title,
            quiz_question_id: quiz_question_id,
          });
          setScore(count);
          return;
        }
      }
      setScore(count);
      userAnswersMulti.push({
        multiple_option_id: count,
        quiz_id: quiz_id,
        multiple_question_statement: title,
      });
    }
  };

  const handleCheckMCQS = (
    selected,
    option_id,
    quiz_id,
    question_id,
    count,
    match_cases_val,
    question
  ) => {
    if (!questCount.includes(question_id)) {
      questCount.push(question_id);
    }
    setQuizIds(quiz_id);
    if (userAnswersSingle.length === 0) {
      userAnswersSingle.push({
        option_id: option_id,
        quiz_id: quiz_id,
        question_id: question_id,
      });
    } else if (userAnswersSingle.length !== 0) {
      for (let i = 0; i < userAnswersSingle.length; i++) {
        if (question_id === userAnswersSingle[i]?.question_id) {
          userAnswersSingle[i] = {
            option_id: option_id,
            quiz_id: quiz_id,
            question_id: question_id,
          };
          setScore(count);
          return;
        }
      }
      setScore(count);
      userAnswersSingle.push({
        option_id: option_id,
        quiz_id: quiz_id,
        question_id: question_id,
      });
    }
  };

  const idsCheck = (ids) => {
    if (ids === "blur_quiz") {
      setId(true);
    } else {
      setId(false);
    }
  };

  return (
    <div>
      {loading ? (
        <FullLoader />
      ) : (
        <>
          <div className="custom2:hidden text-center">
            <Helmet>
              <meta charSet="utf-8" />
              <title>Simple Studying | Quiz | {slugToText(param.slug)}</title>
              <meta name="description" content="Profile Description" />
              <link
                rel="canonical"
                href="http://localhost:3000/account/paid-users"
              />
              <meta name="robots" content="index, follow" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:locale:alternate" content="en_gb" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Simple Studying | Profile" />
              <meta property="og:description" content="Profile Description" />
              <meta
                property="og:url"
                content={"https://test.aimplestudying.com/account/profile"}
              />
              <meta property="og:site_name" content="Simple Studying" />
            </Helmet>
          </div>
          <div className=" custom2:block bg-white overflow-hidden relative">
            <Container lessPadding={true}>
              {getApiKey() ? (
                <div className="pt-2 md:pt-6 lg:pt-10 custom:pt-14">
                  <BreadCrumb
                    items={breadCrumbItem}
                    current={slugToText(param.slug)}
                  />
                </div>
              ) : null}
              <div>
                <p className="paidUserHeadText text-36 font-bold py-10 pt-2 md:pt-6 lg:pt-16 pb-2 md:pb-6 lg:pb-12">
                  {slugToText(param.slug)}
                </p>
              </div>
            </Container>
          </div>
          <div
            id={
              !getApiKey() ||
              (getApiKey() &&
                (user?.subscription_name === "Basic Subscription" || user?.subscription_name.includes('STARTER')) &&
                user?.free_trial === false &&
                user?.user_type !== "TEACHER" && !paidModules.includes(slug1))
                ? "blur_quiz"
                : null
            }
          >
            {quizData?.length > 0
              ? quizData?.map((item, index) => {
                  const {
                    options,
                    question,
                    match_cases,
                    multiple_choices,
                    quiz,
                    id,
                  } = item;
                  for (let i = 0; i < match_cases.length; i++) {
                    shuffleVar = _.shuffle(match_cases[i].sub_matches);
                  }
                  return (
                    <div
                      className=" custom2:block bg-white overflow-hidden relative"
                      style={ids ? { pointerEvents: "none" } : {}}
                    >
                      <Container lessPadding={true}>
                        {options.length > 0 ? (
                          <div>
                            <CheckBoxQuestion
                              title={
                                options.length > 0
                                  ? item.question
                                      .replace(/(<([^>]+)>)/gi, "")
                                      .replace("&amp;", "")
                                  : null
                              }
                              answers={options.length > 0 ? options : null}
                              correct={item.options}
                              type={"radio"}
                              paidModule={paidModules}
                              studyModule={slug1}
                              quiz_id={item.quiz}
                              question_id={item.id}
                              checkSelect={handleCheckMCQS}
                            />
                          </div>
                        ) : null}

                        {multiple_choices.length > 0
                          ? multiple_choices?.map((item, index) => {
                              return (
                                <CheckBoxQuestion
                                  statement_title={
                                    index === 0
                                      ? item?.question_statement
                                          .replace(/(<([^>]+)>)/gi, "")
                                          .replace("&amp;", "")
                                      : null
                                  }
                                  multiAnswers={
                                    multiple_choices.length > 0
                                      ? multiple_choices
                                      : null
                                  }
                                  statement={item?.statement
                                    .replace(/(<([^>]+)>)/gi, "")
                                    .replace("&amp;", "")}
                                  statementId={item.id}
                                  quiz_id_multi={quiz}
                                  correct={item.checkbox}
                                  quiz_question_id={id}
                                  type={"checkbox"}
                                  paidModule={paidModules}
                                  studyModule={slug1}
                                  checkSelectMulti={handleMulti}
                                />
                              );
                            })
                          : null}
                        {match_cases?.length > 0
                          ? match_cases.map((items) => {
                              return (
                                <>
                                  {items?.sub_matches.length > 0 ? (
                                    <p className="text-36 font-bold pt-2 md:pt-8 lg:pt-14 pb-2 md:pb-6 lg:pb-10">
                                      {items?.match_head}
                                    </p>
                                  ) : null}

                                  <div className="flex justify-between  overflow-x-auto">
                                    <div>
                                      {items?.sub_matches?.length
                                        ? items?.sub_matches?.map((elem) => {
                                            elem.selected = false
                                            return (
                                              <MatchCard
                                                textQuestion={elem?.match_question}
                                                items={elem}
                                                boxId={elem?.match_question}
                                                handler={"right"}
                                                color={elem.status}
                                                selected={elem.selected}
                                                addArrow={addArrow}
                                                match_id={elem.id}
                                              />
                                            );
                                          })
                                        : null}
                                    </div>
                                    <div className="mb-20 md:mb-30">
                                      {shuffledAnswers?.length > 0 &&
                                        shuffledAnswers?.map((elem) => {
                                          const questions =
                                            items?.sub_matches.filter(
                                              (subItem) =>
                                                subItem.match_question ===
                                                elem.match_question
                                            );

                                          return questions.map((question) => (
                                            <MatchCard
                                              key={question.id}
                                              textAnswer={elem?.match_answer}
                                              items={match_cases}
                                              addArrow={addArrow}
                                              handler={"left"}
                                              color={elem.status}
                                              selected={elem.selected}
                                              boxId={elem?.match_answer}
                                            />
                                          ));
                                        })}
                                    </div>
                                    {arrows?.map((ar) => {
                                      return (
                                        <Xarrow
                                          start={ar.start}
                                          end={ar.end}
                                          dashness
                                          color="#B4B4B4"
                                          strokeWidth={2}
                                          curveness={1}
                                          showHead={false}
                                        />
                                      );
                                    })}
                                  </div>
                                </>
                              );
                            })
                          : null}
                      </Container>
                    </div>
                  );
                })
              : "No Data To Display.Sorry!"}

            {((user?.subscription_name === "Basic Subscription" || user?.subscription_name.includes('STARTER')) && user?.free_trial === false && !paidModules.includes(slug1)) ||
            !getApiKey() ? (
              <div className="quizBlurSection onBlurSec px-4 md:px-0 w-full md:w-full py-4 lg:ml-10 flex-set">
                <div className="onBlurSubSec bg-white py-4 w-full box-set">
                  <p className="text-15  font-normal mb-3 text-center">
                    Quiz is designed specifically to help you practice your
                    knowledge and memorize for exams
                  </p>
                  <p className="text-15  font-normal mb-3 text-center">
                    Unlock for just £10/month
                  </p>
                  <p className="text-15  font-normal mb-3 text-center">
                    Upgrade your membership now
                  </p>
                </div>
              </div>
            ) : null}
          </div>

          <div className=" custom2:block bg-white overflow-hidden relative">
            <Container lessPadding={true}>
              <div className="flex items-center w-60 md:w-36 mb-6 md:mb-14 lg:mb-22 mt-2 md:mt-6 lg:mt-12">
                <Button
                  filled
                  onClick={resultShow}
                  style={{ cursor: "pointer" }}
                  disabled={
                    (user?.subscription_name === "Basic Subscription" || user?.subscription_name.includes('STARTER')) &&
                    user?.free_trial === false &&
                    user?.user_type !== "TEACHER" && !paidModules.includes(slug1)
                  }
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Submit
                  </p>
                </Button>
              </div>
            </Container>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  quizQuestionList: quizQuestionList(state),
  loading: quizQuestionLoading(state),
  user_detail: userData(state),
  dataCatalogue: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getQuizQuestion: (slug1) => dispatch(getQuizQuestion(slug1)),
  getUserInfo: () => dispatch(getUserInfo()),
  quizResults: (userAnswersSingle, userAnswersMulti, matches, quiz_ids) =>
    dispatch(
      quizResults(userAnswersSingle, userAnswersMulti, matches, quiz_ids)
    ),
    getCatalogue: (id) => dispatch(getCatalogue(id)),

});
export default connect(mapStateToProps, mapDispatchToProps)(PaidUsers);