import * as t from '../types';
import {AddEmail, GetGroupParticipants, GetGroupSession, GetHoliday, GetTutorGroupSessions, PostHoliday} from '../../services'


export const postMailChimpAction = (obj, study) => async (dispatch) => {

    dispatch({
        type: t.POST_MAILCHIMP_REQUEST
    });

    return await AddEmail(obj, study)

        .then((data) => {
            dispatch({
                type: t.POST_MAILCHIMP_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.POST_MAILCHIMP_FAILED,
                payload: err.response
            });
            return err.response.status
        });
};
