import * as t from '../types';
import {GetTutorSession, CreateGroupSession, CreateOneToOneSession} from '../../services'
import {createErrorAlert} from "./errorAlertAction";


export const getTutorSession = (slug) => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_SESSION_REQUEST
    });

    return await GetTutorSession(slug)

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_SESSION_SUCCESS, payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_SESSION_FAILED, payload: err.response
            });
        });
};
export const createGroupSession = (body) => async (dispatch) => {

    dispatch({
        type: t.CREATE_TUTOR_GROUP_SESSION_REQUEST
    });

    return await CreateGroupSession(body)

        .then((data) => {
            dispatch({
                type: t.CREATE_TUTOR_GROUP_SESSION_SUCCESS, payload: data
            });
            dispatch(createErrorAlert(201, 'Congratulations', 'Session has been created', 201));
            window.location.reload()
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_TUTOR_GROUP_SESSION_FAILED, payload: err.response
            });
        });
};
export const createOneToOneSession = (body) => async (dispatch) => {

    dispatch({
        type: t.CREATE_TUTOR_ONE_TO_ONE_SESSION_REQUEST
    });

    return await CreateOneToOneSession(body)

        .then((data) => {
            dispatch({
                type: t.CREATE_TUTOR_ONE_TO_ONE_SESSION_SUCCESS, payload: data
            });
            dispatch(createErrorAlert(201, 'Congratulations', 'Session has been created', 201));
            window.location.reload()
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_TUTOR_ONE_TO_ONE_SESSION_FAILED, payload: err.response
            });
        });
};
