import * as t from '../types';
import {GET_QUIZ_RESULTS_FAILED, GET_QUIZ_RESULTS_REQUEST, GET_QUIZ_RESULTS_SUCCESS} from "../types";

const initialState = {
    data: '',
    loading: false,
    error: '',
};

export default function quizResultsReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.GET_QUIZ_RESULTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case t.GET_QUIZ_RESULTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
            };
        case t.GET_QUIZ_RESULTS_FAILED:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}
