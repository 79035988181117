import * as t from '../types';

const initialState = {
    data: '',
    loading: false,
    error: ''
};

export function studyModulesReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.GET_STUDY_MODULES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.GET_STUDY_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            };
        case t.GET_STUDY_MODULES_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}


const initialStateForStudyModule = {
    data: '',
    loading: false,
    error: ''
};

export function specificReducer(state = initialStateForStudyModule, action) {
    const {type, payload} = action;
    switch (type) {
        case t.GET_STUDY_MODULES_DETAIL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.GET_STUDY_MODULES_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            };
        case t.GET_STUDY_MODULES_DETAIL_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
