import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/EmptyButton";
import Container from "../../components/container";
import Input from "../../components/Input/InputForm";
import { useHistory, useLocation } from "react-router-dom";
import { tutorSessionPostOne } from "../../redux/actions/tutorSessionPostOneAction";
import { createErrorAlert } from "../../redux/actions";
import { Helmet } from "react-helmet";
import timeCheck from "../../helpers/timecheck";
import FullLoader from "../../components/Loader/FullLoader";
import PhoneInput from "react-phone-input-2";
import Stepper from "../../components/SignUpStepper";
import paymentImg from "../../assets/images/paymentIconOne.png";
import upArrow from "../../assets/images/ArrowNew.png";
import { getUser } from "../../helpers";
import Context from "../../services/Context";
import Dropzone from "react-dropzone";
import notesImg from "../../assets/icons/notes-icn.svg";

const onDrop = (filesNow) => {
  // let alreadyUploaded = false;
  // let uploadedFiles = [...files];
  // for (let i = 0; i < filesNow.length; i++) {
  //   const file = {
  //     name: filesNow[i]?.name,
  //     file: filesNow[i],
  //     preview: URL.createObjectURL(filesNow[i]),
  //   };
  //   uploadedFiles.forEach((elem) => {
  //     if (elem.name === filesNow[i]?.name) {
  //       alreadyUploaded = true;
  //     }
  //   });
  //   if (!alreadyUploaded) {
  //     uploadedFiles.push(file);
  //   }
  // }

  // setFiles([...uploadedFiles]);
};

const moment = require("moment");
const OneToOnePayment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(getUser());

  const {value} = useContext(Context)

  console.log(value, "pppp")

  const { selectedPackages, selectedSlots, dataItem, timeZone } = value;

  let stripe = false;

  const [state, setState] = useState({
    firstName: user?.first_name !== "" ? user?.first_name : "",
    title: value?.claim === 'free' ? 'Free Appointment' : '',
    lastName: user?.last_name !== "" ? user?.last_name : "",
    email: user?.email !== "" ? user?.email: "",
    phone: user?.contact_number !== "" ? user?.contact_number : "",
    firstNameMandatory: false,
    lastNameMandatory: false,
    emailMandatory: false,
    titleMandatory: false,
    isLoading: false,

  });

  const userCurrency = value?.userCurrency || user?.user_currency


  const moveToBackScreen = () => {
    history.goBack();
  };

  const handlePhoneNumberChange = (value) => {
    setState((prev) => ({
        ...prev,
        phone: value
    }))
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      titleMandatory:
        state?.title?.length > 0 ? false : prevState.titleMandatory,
      firstNameMandatory:
        state?.firstName?.length > 0 ? false : prevState.firstNameMandatory,
      lastNameMandatory:
        state?.lastName?.length > 0 ? false : prevState.lastNameMandatory,
      emailMandatory:
        state?.email?.length > 0 ? false : prevState.emailMandatory,
    }));
  }, [state.title, state.firstName, state.lastName, state.email]);

  const stripeCheck = async () => {
    for (let i = 0; i < selectedSlots.length; i++) {
      selectedSlots[i].startTime = timeCheck(selectedSlots[i].startTime);
    }
    if (state?.title?.length === 0) {
      setState((prev) => ({
        ...prev,
        titleMandatory: true
    }))
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Title is required to confirm the tutoring session",
          201
        )
      );
      return;
    } else {
      setState((prev) => ({
        ...prev,
        isLoading: true
    }))
      
      dispatch(
        tutorSessionPostOne(
          user?.username,
          dataItem.name,
          value?.claim === 'free' ? 'Free Appointment' : state.title,
          selectedSlots,
          selectedPackages.slug,
          selectedPackages.price,
          timeZone
        )
      ).then((res) => {
        setState((prev) => ({
            ...prev,
            isLoading: false
        }))
        if (res.status === 200) {
          if (res.data.checkout_url) {
            let event = [];
            for (let i = 0; i < selectedSlots.length; i++) {
              let startEvent = `${selectedSlots[i].date} ${selectedSlots[i].startTime}`;
              event.push({
                start: startEvent,
                duration: [1, "hour"],
                title: state.title,
                description: "One to One Appointment with Tutor",
                busy: true,
              });
            }
            localStorage.setItem("events", JSON.stringify(event));
            stripe = true;
            sessionStorage.setItem("stripRedirect", stripe);
            window.location.replace(res.data.checkout_url);
            dispatch(
              createErrorAlert(
                201,
                "Congratulations",
                "Appointment has been Created",
                201
              )
            );
          } else {
            dispatch(
              createErrorAlert(
                201,
                "Congratulations",
                "Issue With Stripe Redirection",
                201
              )
            );
            history.push("/profile");
          }
        } else {
          dispatch(
            createErrorAlert(
              400,
              "",
              "Tutor is already appointed for this date",
              400
            )
          );
        }
      });
    }
  };

  return (
    <>
      {state.isLoading ? <FullLoader /> : null}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20 pt-6">
        <Stepper step={4} exit={() => history.push("/profile")} totalSteps={4}/>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Simple Studying | One to One Payment</title>
          <meta name="description" content="One To One Payment Description" />
          <link
            rel="canonical"
            href="https://simplestudying.com/book-tutorials/one-to-one-payment"
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Simple Studying | One To One Payment"
          />
          <meta
            property="og:description"
            content="One To One Payment Description"
          />
          <meta
            property="og:url"
            content="https://simplestudying.com/book-tutorials/one-to-one-payment"
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          <div className="flex flex-col lg:flex-row lg:space-x-7">
            <div
              className="p-12  mb-10 w-full lg:w-2/3"
              
            >
              <p className="pb-12 text-h3 text-darkest custom2:text-36 font-bold ">
                One-to-one tutoring - 60 min
              </p>
              <div className="flex flex-row justify-start items-center mb-5">
                <p className="w-70 font-bold text-small-text text-15 text-darkest">
                  Tutor:
                </p>
                <div className="flex flex-row  ">
                  <p className="text-small-text text-15 ml-2 text-darkest">
                    {dataItem?.name}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-start items-center mb-5">
                <p className="w-70 font-bold text-small-text text-15text-darkest">
                  Dates:
                </p>
                <div className="flex flex-row  ">
                  {selectedSlots?.length > 0
                    ? selectedSlots?.map((item) => {
                        return (
                          <p
                            className="text-small-text text-15 text-darkGrey"
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.date},
                          </p>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="flex flex-row justify-start items-center mb-10">
                <p className="w-70 font-bold text-small-text text-15 text-darkest">
                  Local time:
                </p>
                <div className="flex flex-row ">
                  {selectedSlots?.length > 0
                    ? selectedSlots?.map((item) => {
                        return (
                          <p
                            className="text-small-text text-15  text-darkGrey"
                            style={{ marginLeft: "10px" }}
                          >
                            {item?.startTime}
                          </p>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="flex flex-col text-darkest">
                {/* <div className="flex flex-col md:flex-row md:space-x-6 md:mb-9 ">
                  <div className="w-full mb-6 md:mb-0 md:w-2/4 ">
                    <label className="mb-8 ">First name</label>
                    <Input
                      placeholder=""
                      value={state.firstName}
                      setValue={setState}
                      stateKey="firstName"
                    />
                    {state.firstNameMandatory ? (
                      <small style={{ color: "red" }}>
                        <i>* This field is mandatory</i>
                      </small>
                    ) : null}
                  </div>
                  <div className="w-full mb-6 md:mb-0 md:w-2/4">
                    <label className="mb-8">Last name</label>
                    <Input
                      placeholder=""
                      value={state.lastName}
                      setValue={setState}
                      stateKey="lastName"
                    />
                    {state.lastNameMandatory ? (
                      <small style={{ color: "red" }}>
                        <i>* This field is mandatory</i>
                      </small>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col md:flex-row md:space-x-6 mb-9 ">
                  <div className="w-full mb-6 md:mb-0 md:w-2/4">
                    <label className="mb-8">Email</label>
                    <Input
                      placeholder=""
                      value={state.email}
                      setValue={setState}
                      stateKey="email"
                    />
                    {state.emailMandatory ? (
                      <small style={{ color: "red" }}>
                        <i>* This field is mandatory</i>
                      </small>
                    ) : null}
                  </div>
                  <div className="w-full mb-6 md:mb-0 md:w-2/4">
                    <label className="mb-8">Phone</label>
                    <PhoneInput
                      country={"us"}
                      value={state.phone}
                      onChange={handlePhoneNumberChange}
                      className="text-input focus:bg-offWhite focus:border-none"
                      inputStyle={{
                        border: "none",
                        height: "51px",
                        fontSize: "15px",
                      }}
                      buttonStyle={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                  </div>
                </div> */}
                <div className="flex flex-col md:flex-row md:space-x-6 md:mb-9 ">
                  <div className="w-full mb-6 md:mb-0">
                    <p className="mb-4">
                    Please describe in detail what you would like to discuss on the tutoring session?
                    </p>
                    <Input
                      placeholder="Enter Session Description"
                      value={state.title}
                      autoComplete={"off"}
                      setValue={setState}
                      stateKey="title"
                    />
                    {state.titleMandatory ? (
                      <small style={{ color: "red" }}>
                        <i>* This field is mandatory</i>
                      </small>
                    ) : null}
                     <p className="mb-4 mt-6">
                     Please upload the questions you would like to discuss with the tutor and all the relevant
lectures slides relevant to that question. The lecture slides are very important for the tutor to give
you the most relevant personalised advise.
                    </p>
                    <Dropzone onDrop={onDrop} accept="application/pdf">
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            isDragAccept,
          }) => (
            <div
              className="dropzoneSection paymentDropZone"
              style={{
                borderColor: isDragReject
                  ? "red"
                  : isDragAccept
                  ? "green"
                  : "#B4B4B4",
                  
              }}
            >
              <div
                {...getRootProps()}
                className="dropzoneInner cursor-pointer flex flex-col justify-center items-center"
              >
                <input {...getInputProps()} className="dropzoneInner" />
                <img alt="upload" src={notesImg} />
                <p className="text-darkGrey2 font-normal text-body-large text-center pt-8">
                <span className="text-black">Upload lectures, your notes, reading materials</span><br />
                  {!isDragActive
                    ? "Drop or Choose a file from your computere"
                    : "Drop them like they are hot potatoes"}
                </p>
              </div>
            </div>
          )}
        </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-6  mb-10 bg-white w-full lg:w-1/3 h-auto"
              style={{
                borderRadius: "14px",
                boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
              }}
            >
              <div>
                <p className="pb-7 text-h3 text-darkest  font-bold ">Summary</p>
              </div>
              <div className="flex flex-row justify-between items-center text-darkest text-15 mb-5">
                <div className="flex justify-start w-2/4 ">
                  <p>Package price:</p>
                </div>
                <div className="flex justify-end w-2/4">
                  <p>
                  {userCurrency === "UK" ? "£" : "$"} {selectedPackages?.price ? selectedPackages?.price : 0.0}
                  </p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center text-darkest text-large font-bold mb-11">
                <div className="flex justify-start w-2/4 ">
                  <p>Total cost:</p>
                </div>
                <div>
                  <p>
                  {userCurrency === "UK" ? "£" : "$"} {selectedPackages?.price ? selectedPackages?.price : 0.0}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="mb-10 w-full">
                  <Button
                    filled
                    onClick={() => {
                      stripeCheck();
                    }}
                  >
                    <p className="text-center text-small-text xl:text-body-large">
                      Confirm
                    </p>
                  </Button>
                </div>
              </div>
              <p
                className="text-primary custom2:text-body-large text-14 font-bold text-center cursor-pointer"
                onClick={moveToBackScreen}
              >
                Cancel
              </p>
              <div className="savePaymentSec flex items-start">
                <div className="paymentImgSec">
                  <img src={paymentImg} alt="paymentImg" />
                </div>
                <div className="paymentContsec">
                  <h2>Your Payment Information is safe</h2>
                  <h4>
                    We use Stripe, a third party payment processor to store and
                    secure your information{" "}
                  </h4>
                  <a href="https://stripe.com/docs/security">
                    Learn more about data security{" "}
                    <img src={upArrow} alt="arrow" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default OneToOnePayment;
