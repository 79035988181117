import apiRequest from "../../helpers/apiRequest";

export default async (params) => {
    let obj = {
        new_password1: params.newPassword,
        new_password2: params.confirmPassword
    }
    return await apiRequest({
        method: 'post',
        url: `accounts/dj-rest-auth/password/change/`,
        data: obj
    })
}
