/* eslint-disable import/no-anonymous-default-export */
import Config from "../config/index";

import HTTP from "./httpInstance";
import { getApiKey } from "./index";
import getCsrf from "./getUserCsrf";

export default async (request) => {
  request.method = request.method || "get";
  request.url = `${Config.APP_API_URL}/${request.url}`;
  request.data = request.data || {};
  if (request.data && request.method === "get") {
    request.data = null;
  }

  if (getApiKey()) {
    request.headers = {
      Authorization: `Bearer ${getApiKey()}`,
      "Content-Type": "application/json",
      ...request.headers,
    };
  } else {
    request.headers = {
      "Content-Type": "application/json",
      ...request.headers,
    };
  }

  return HTTP(request);
};
