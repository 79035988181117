import React, { useState } from "react";
import Button from "../../components/EmptyButton";
import creator from "../../assets/images/mariam.png";
import Dropzone from "react-dropzone";
import RemoveIcon from "../../assets/images/removeIcon.webp";
import UploadIcon from "../../assets/newImages/docIcon.png";
import { apiRequest } from "../../helpers";
import { createErrorAlert } from "../../redux/actions";
import { useDispatch } from "react-redux";
const GrabDetail = () => {
  const [files, setFiles] = useState([]);
  const [content, setContent] = useState('')
  const [formDataState, setFormData] = useState(null)

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    if (!formDataState) {
      console.error('No file selected');
      return;
    }
  
    let formData = new FormData();
    formData.append('pdf', formDataState);
    formData.append('content', content);
  
    await apiRequest({
      method: 'post',
      url: 'studies/suggest/studymodules/',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        dispatch(createErrorAlert(200, "", res.data.message, 200))
      })
      .catch((err) => {
        console.log(err.response, 'err');
        dispatch(createErrorAlert(200, "", err.response.data.message, 200))
      });
  };
  

  const onDrop = (filesNow) => {
    setFormData(filesNow[0])
    let alreadyUploaded = false;
    let uploadedFiles = [...files];
    for (let i = 0; i < filesNow.length; i++) {
      const file = {
        name: filesNow[i]?.name,
        file: filesNow[i],
        preview: URL.createObjectURL(filesNow[i]),
      };
      uploadedFiles.forEach((elem) => {
        if (elem.name === filesNow[i]?.name) {
          alreadyUploaded = true;
        }
      });
      if (!alreadyUploaded) {
        uploadedFiles.push(file);
      }
    }

    setFiles([...uploadedFiles]);
  };

  const deleteFile = (name) => {
    let uploadedFiles = [...files];

    uploadedFiles.forEach((elem, index) => {
      if (elem.name === name) {
        uploadedFiles.splice(index, 1);
      }
    });

    setFiles([...uploadedFiles]);
  };
  return (
    <div
      className="
        my-10 px-4
        md:px-6
        lg:px-8
        custom:px-56
        custom2:px-56
        xl:px-60"
    >
      <p className="fw-bold text-h3 text-primary">
        Grab your tailor-made study materials now!
      </p>
      <div className="flex gap-14 flex-col md:flex-row">
        <div>
          <p className="py-5">
            Please enter as much details as possible about what kind of study
            materials you want the content creator to do for you. It could be
            simple notes, flashcards, videos, case summaries.
          </p>

          <textarea
            rows={8}
            className="w-full p-5 rounded-2xl"
            placeholder="Please provide a brief description of the required study materials."
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
          <p className="fw-bold text-black my-2 fw-medium ">
            Please upload any document that the tutor may need to create the
            study materials for you.
          </p>
          <p className=" opacity-70">
            E.g. lectures, your notes, reading materials.
          </p>

          <div className="flex flex-row items-center justify-center  flex-wrap w-full z-10">
            <Dropzone onDrop={onDrop} accept="application/pdf">
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                isDragAccept,
              }) => (
                <div
                  className="w-full py-10"
                  style={{
                    borderColor: isDragReject
                      ? "red"
                      : isDragAccept
                      ? "green"
                      : "#B4B4B4",
                  }}
                >
                  <div
                    {...getRootProps()}
                    className="dropzoneInner cursor-pointer flex flex-col justify-center p-7 items-center"
                  >
                    <input {...getInputProps()} className="dropzoneInner" />
                    <img alt="upload" src={UploadIcon} />
                    <p className="text-black font-normal text-14 text-center pt-8">
                      {!isDragActive
                        ? "Upload lectures, your notes, reading materials"
                        : "Drop them like they are hot potatoes"}
                    </p>
                    <p className="text-darkGrey2 font-normal text-14 text-center pt-1">
                      Drop or choose a file from your computer
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <div className="mt-10 px-6">
            {files.map((item, index) => (
              <div
                className="flex flex-row justify-between items-center mt-6"
                key={index}
              >
                <p className="text-darkest font-normal text-16 md:text-body-large">
                  {item?.name}
                </p>
                <img
                  className="cursor-pointer"
                  onClick={() => deleteFile(item?.name)}
                  src={RemoveIcon}
                  alt="remove"
                />
              </div>
            ))}
          </div>

          <p className="my-2 fw-medium ">Unlock your study potential!</p>
          <p className="my-1">
            Our tutors dedicate a minimum of{" "}
            <span className="text-primary">10 hours to review</span> your
            uploaded documents and craft tailor-made study materials just for
            you. With our{" "}
            <span className="text-primary">hourly rate at £35</span> , get
            started with this service at an unbeatable
            <span className="text-primary">price of £349.</span> <br />
            Elevate your learning experience today!
          </p>
          <div className="w-150 h-fit my-3">
            <Button className="confrmBtn" filled onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        <div className="m-auto">
          <p className="fw-bold text-black my-6 text-h3">Content Creator</p>
          <div className="bg-white p-5 flex justify-center items-center flex-col rounded-2xl ">
            <div
              className="my-4"
              style={{ width: "150px", height: "150px", borderRadius: "50%" }}
            >
              <img
                src={creator}
                className="w-full h-full"
                style={{ borderRadius: "50%" }}
              />
            </div>
            <p className=" fw-semibold text-h3">Mariam</p>
            <p>First Class LLB Degree</p>
            <p>City University of London</p>
            <hr className="my-2 text-darkGrey2 opacity-40 w-full" />
            <div className="my-2 flex flex-col justify-center">
              <p className="text-primary text-center fw-semibold ">
                Work Experience:
              </p>
              <p className="text-center">
                1000+ pages of study materials created for SimpleStudying
              </p>
            </div>
            <div className="my-2  flex flex-col justify-center">
              <p className="text-primary fw-semibold  text-center">
                Description:
              </p>
              <p className="text-center">
                Mariam can Create study materials for all main areas of law
                except LPC and BPTC{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrabDetail;
