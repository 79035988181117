import React, {useEffect, useRef, useState} from 'react';
import Xarrow from 'react-xarrows';

const connectPointStyle = {
    position: 'absolute', width: 15, height: 15, borderRadius: '50%', background: '#cc6827',
};
const connectPointOffset = {
    left: {left: 0, top: '50%', transform: 'translate(-50%, -50%)'},
    right: {left: '100%', top: '50%', transform: 'translate(-50%, -50%)'},
    top: {left: '50%', top: 0, transform: 'translate(-50%, -50%)'},
    bottom: {left: '50%', top: '100%', transform: 'translate(-50%, -50%)'},
};

const ConnectPointsWrapper = ({boxId, handler, ref0, end}) => {
    const ref1 = useRef();
    const [position, setPosition] = useState({});
    const [beingDragged, setBeingDragged] = useState(false);

    useEffect(() => {
    },[end])

    const handleDragEnd = (e) => {
        if(end === ''){
            setPosition({});
            setBeingDragged(true);
            ref1.current.draggable = true
        } else {
            setPosition({});
            // e.dataTransfer.setData("arrow", null);
            setBeingDragged(false);
            ref1.current.draggable = false
        }
    }
    
    return (<React.Fragment>
        <div
            className="connectPoint"
            style={{
                ...connectPointStyle, ...connectPointOffset[handler], ...position,
            }}
            draggable
            onDragStart={(e) => {
                setBeingDragged(true);
                e.dataTransfer.setData('arrow', boxId);
            }}
            onDrag={(e) => {
                setPosition({
                    position: 'fixed', left: e.clientX, top: e.clientY, transform: 'none', opacity: 0,
                });
            }}
            ref={ref1}
            onDragEnd={(e) => {
                handleDragEnd(e)
            }}
        />
        
        {beingDragged && end !== '' ? <Xarrow start={ref0} end={ref1} dashness
                                color="#B4B4B4"
                                strokeWidth={2}
                                curveness={1}
                                showHead={false}/> : null}
    </React.Fragment>);
};

export default ConnectPointsWrapper;