import React, { useEffect, useState } from "react";
const LetterCard = ({ letter, isActive, key, onLetterChange }) => {
  return (
    <>
      <div
        onClick={() => onLetterChange(letter)}
        key={key}
        className={
          isActive
            ? "linear-background w-12 mt-3 max-w-12 h-12 bg-white rounded-xl flex items-center justify-center text-body-large hover:border-gray cursor-pointer border-transparent border hover:border-lightBorderGrey "
            : "w-8 md:w-10 lg:w-12 mt-3 max-w-12 h-8 md:h-10 lg:h-12 bg-white rounded-xl flex items-center justify-center text-body-large hover:border-gray cursor-pointer border-transparent border hover:border-lightBorderGrey"
        }
        style={{ boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)" }}
      >
        <p
          className={
            isActive
              ? "text-white text-body-large"
              : "text-darkest text-body-large"
          }
        >
          {letter}
        </p>
      </div>
    </>
  );
};
const LetterPagination = ({ onPageChange }) => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const [letters, setLetters] = useState([]);
  useEffect(() => {
    let letterArray = [];
    alphabet.map((item) => {
      letterArray.push({ letter: item, isActive: false });
    });
    setLetters(letterArray);
  }, []);

  const letterpressed = (letter) => {
    onPageChange(letter);
    let newLetters = [...letters];
    newLetters.map((record) => {
      if (record.letter === letter) {
        record.isActive = true;
      } else {
        record.isActive = false;
      }
    });
    setLetters(newLetters);
  };
  return (
    <>
      {letters.map((item, index) => (
        <LetterCard
          letter={item.letter}
          key={index}
          isActive={item.isActive}
          onLetterChange={letterpressed}
        />
      ))}
    </>
  );
};

export default LetterPagination;
