import React from 'react';

import './index.css';

const SignupCheckbox = ({id, text, correct, handleChange}) => {
    return (
        <div key={id}>
            <div className="checkbox flex items-center  mt-4">
                <input type="checkbox" onChange={(e) => handleChange(e.target.value, e.target.checked)} value={text}
                       className="input-assumpte" id={text}/>
                <label htmlFor={text}
                       className="text-16 custom2:w-10/12 md:text-body-large text-darkest flex items-center">{text}</label>
            </div>
        </div>
    );
};

export default SignupCheckbox;
