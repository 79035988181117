import React from 'react'

import Container from '../container'
import LazyImage from '../LazyImage'

import Logo from '../../assets/images/study_small.png'
import { useHistory } from 'react-router-dom'

const ContentCreatorStepper = ({ step, exit }) => {

    const history = useHistory()

    return (
        <Container>
            <div className='py-5 flex items-center'>
                <div className='md:mr-10 xl:mr-14 hidden md:block'>
                    <LazyImage src={Logo} className="w-36 h-36" alt='Logo' onClick={() => history.push('/')} />
                </div>
                {step === 1 ? (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>1 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'></div>
                            <div className='w-full bg-lightGray h-1'></div>
                            <div className='w-full bg-lightGray h-1 rounded-sm'></div>
                        </div>
                    </div>
                ) : step === 2 ? (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>2 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'></div>
                            <div className='w-full bg-lightGray h-1 rounded-sm'></div>
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>3 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'></div>
                        </div>
                    </div>
                )}
                <div onClick={exit} className='ml-5 custom:ml-12 flex items-center'>
                    <p className='font-bold text-primary text-body-large cursor-pointer pt-6'>Exit</p>
                </div>
            </div>
        </Container>
    )
}

export default ContentCreatorStepper
