import * as t from '../types';
import {GetSubscriptions} from '../../services'

export const getSubscriptions = () => async (dispatch) => {
    dispatch({
        type: t.GET_SUBSCRIPTION_REQUEST
    });

    return await GetSubscriptions()
        .then((data) => {
            dispatch({
                type: t.GET_SUBSCRIPTION_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_SUBSCRIPTION_FAILED,
                payload: err.response
            });
        });
};
