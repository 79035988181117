import * as t from '../types';
import {GetQuizzes} from '../../services';

export const getAllQuiz = (slug, id) => async (dispatch) => {

    dispatch({
        type: t.GET_ALL_QUIZ_REQUEST,
    });
    
    return await GetQuizzes(slug, id)
        
        .then((data) => {
            dispatch({
                type: t.GET_ALL_QUIZ_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_ALL_QUIZ_FAILED,
                payload: err.response,
            });
        });
};
