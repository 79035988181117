import * as t from '../types';
import {GetGroupParticipants, GetGroupSession, GetHoliday, GetTutorGroupSessions} from '../../services'


export const getHolidays = (user) => async (dispatch) => {

    dispatch({
        type: t.GET_HOLIDAYS_REQUEST
    });

    return await GetHoliday(user)

        .then((data) => {
            dispatch({
                type: t.GET_HOLIDAYS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_HOLIDAYS_FAILED,
                payload: err.response
            });
        });
};
