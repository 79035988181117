import React from "react";

const ResutlCard = ({ correctAnswer }) => {
  return (
    <div>
      <div>
        {correctAnswer?.singleChoice?.length > 0 ? (
          <div>
            <p className="text-center font-bold text-2xl fontWeightQuiz">Single Choice</p>
            {correctAnswer?.singleChoice?.map((item) => (
              <div
                className="p-3 md:p-4 lg:p-6 w-full bg-white rounded-3xl mt-3 lg:mt-5"
                style={{ boxShadow: "5px 15px 40px rgba(2, 23, 54, 0.05)" }}
              >
                <p className="font-bold text-h5 md:text-h3 pb-5">
                  {item.question}
                </p>
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Answer Provided:</span>{" "}
                  {item.user_selected_option}
                </p>
                {item?.correct_option.map((subitem) => (
                  <p className="text-body-large pb-3.5">
                    <span className="font-bold text-greenGradient">
                      Correct Answer:
                    </span>{" "}
                    {subitem.correct_option}
                  </p>
                ))}
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Coments Entered:</span>{" "}
                  {item.comments}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        {correctAnswer?.multiChoice?.length > 0 ? (
          <div>
            <p className="text-center font-bold text-2xl fontWeightQuiz mt-12">Multiple Choice</p>
            {correctAnswer?.multiChoice?.map((item) => (
              <div
                className="p-3 md:p-4 lg:p-6 w-full bg-white rounded-3xl mt-3 lg:mt-5"
                style={{ boxShadow: "5px 15px 40px rgba(2, 23, 54, 0.05)" }}
              >
                <p className="font-bold text-h5 md:text-h3 pb-5">
                  {item.question}
                </p>
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Answer Provided:</span>{" "}
                  {item.user_selected_option}
                </p>
                {item?.correct_option.map((subitem) => (
                  <p className="text-body-large pb-3.5">
                    <span className="font-bold text-greenGradient">
                      Correct Answer:
                    </span>{" "}
                    {subitem.correct_option}
                  </p>
                ))}
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Coments Entered:</span>{" "}
                  {item.comments}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        {correctAnswer?.matchCase?.length > 0 ? (
          <div>
            <p className="text-center font-bold text-2xl fontWeightQuiz mt-12">Match Cases</p>
            {correctAnswer?.matchCase?.map((item) => (
              <div
                className="p-3 md:p-4 lg:p-6 w-full bg-white rounded-3xl mt-3 lg:mt-5"
                style={{ boxShadow: "5px 15px 40px rgba(2, 23, 54, 0.05)" }}
              >
                <p className="font-bold text-h5 md:text-h3 pb-5">
                  {item.match_question}
                </p>
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Answer Provided:</span>{" "}
                  {item.user_selected_option}
                </p>
                <p className="text-body-large pb-3.5">
                  <span className="font-bold text-greenGradient">
                    Correct Answer:
                  </span>{" "}
                  {item.match_answer}
                </p>
                <p className="text-body-large pb-3.5">
                  <span className="font-bold">Coments Entered:</span>{" "}
                  {item.comments}
                </p>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ResutlCard;
