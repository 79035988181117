export const ADD_CSRF = 'ADD_CSRF';
export const REMOVE_CSRF = 'REMOVE_CSRF';
export const ADD_USER = 'ADD_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const GET_USER = 'GET_USER';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const GET_CSRF = 'GET_CSRF';
export const GET_CSRF_FAILED = 'GET_CSRF_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';


