import React from 'react';

const Badge = ({startTime, endTime, setTime, onClick, item, selected, id, dates, dateArray}) => {    

    return (
        <div>
            {!dateArray.some(slot => slot.startTime === startTime) ? <div onClick={() => {
                setTime({id: id, start: startTime, selected: true});
                onClick(item);
                dates(null, startTime)
            }}
                className={`${selected === false ? 'border-2 border-green-500' : 'border'} p-2 mb-3.5 mr-3.5 flex justify-center items-center cursor-pointer`}
                style={{borderRadius: '43px'}} id="badge">
                <p className="text-greenGradient font-normal text-08">
                    {startTime}
                </p>
            
            </div> : null}
            
        </div>);
};

export default Badge;