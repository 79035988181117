import * as t from '../types';
import {POST_TUTOR_SESSION_ONE_FAILED, POST_TUTOR_SESSION_ONE_REQUEST, POST_TUTOR_SESSION_ONE_SUCCESS} from "../types";

const initialState = {
    data: '',
    loading: false,
    error: ''
};

export default function tutorSessionPostOneReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.POST_TUTOR_SESSION_ONE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.POST_TUTOR_SESSION_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            };
        case t.POST_TUTOR_SESSION_ONE_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
