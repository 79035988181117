import * as t from '../types';

const INITIAL_STATE = {
    user: null,
    csrf: null
}

export  function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case t.ADD_USER: {
            return {...state, user: action.payload}
        }
        case t.GET_USER: {
            return {...state, user: action.payload}
        }
        case t.REMOVE_USER: {
            return {...state, user: null}
        }
        case t.ADD_CSRF: {
            return {...state, csrf: action.payload}
        }
        case t.GET_CSRF: {
            return {...state, csrf: action.payload}
        }
        case t.REMOVE_CSRF: {
            return {...state, csrf: null}
        }
        case t.GET_USER_FAILED: {
            return {...state}
        }
        case t.GET_CSRF_FAILED: {
            return {...state}
        }
        default: {
            return {...state}
        }
    }
}


const initialState = {
    data: '',
    loading: false,
    error: ''
};

export function updateUserReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case t.UPDATE_USER_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
