import React from 'react'
import ReactPlayer from "react-player";

const VideoPlayer = ({url, controls}) => {
    return (
        <ReactPlayer
            url={url}
            config={{
                youtube: {
                    playerVars: {showinfo: 1}
                },
            }}
            width='100%'
            height='100%'/>
    )
}

export default VideoPlayer
