import * as t from '../types';
import {GetTutorGroupSessions, TutorSessionPost, UpdateSessionData} from '../../services'
import {
    GET_GROUP_SESSION_REQUEST,
    POST_TUTOR_SESSION_ONE_FAILED,
    POST_TUTOR_SESSION_ONE_REQUEST,
    POST_TUTOR_SESSION_ONE_SUCCESS
} from "../types";


export const tutorSessionUpdate = (obj, slug) => async (dispatch) => {

    dispatch({
        type: t.UPDATE_TUTOR_GROUP_REQUEST
    });

    return await UpdateSessionData(obj, slug)

        .then((data) => {
            dispatch({
                type: t.UPDATE_TUTOR_GROUP_SUCCESS,
                payload: data
            });
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        })
        .catch((err) => {
            dispatch({
                type: t.UPDATE_TUTOR_GROUP_FAILED,
                payload: err.response
            });
        });
};
