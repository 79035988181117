import * as t from '../types';
import {GetTutorGroupSessions} from '../../services'
import {GET_GROUP_SESSION_REQUEST} from "../types";


export const getTutorGroupSession = () => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_GROUP_SESSION_REQUEST
    });

    return await GetTutorGroupSessions()

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_GROUP_SESSION_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_GROUP_SESSION_FAILED,
                payload: err.response
            });
        });
};
