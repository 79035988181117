import apiRequest from "../../helpers/apiRequest";

export default async (sub_name) => {
    let obj = {
        subscription_name: sub_name
    }
    return await apiRequest({
        method: 'post',
        url: `content/sub-cancel/`,
        data: obj
    })
}
