import * as t from '../types';
import {GetTermsConditions} from '../../services'


export const getTermsConditions = () => async (dispatch) => {

    dispatch({
        type: t.GET_TERMS_CONDITIONS_REQUEST
    });

    return await GetTermsConditions()

        .then((data) => {
            dispatch({
                type: t.GET_TERMS_CONDITIONS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TERMS_CONDITIONS_FAILED,
                payload: err.response
            });
        });
};
