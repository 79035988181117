import apiRequest from "../../helpers/apiRequest";

export default async (page,slug) => {
    return await apiRequest({
        method: 'get',

        url: `content/flashcard/${slug}`,

    })
}
