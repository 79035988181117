import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./index.css";
import Container from "../../components/container";
import TutorSessionStudents from "../../components/TutorSiteComponents/tutorSessionsStudents";
import { mySessionsGroup, mySessionsOneToOne } from "../../redux/actions";
import {
  mySessionsGroupData,
  mySessionsGroupLoading,
  mySessionsOneToOneData,
  mySessionsOneToOneLoading,
} from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getYear } from "date-fns";

const Sessions = (props) => {
  const {
    mySessionsOneToOne,
    mySessionsGroup,
    mySessionsGroupData,
    mySessionsOneToOneData,
    mySessionsGroupLoading,
    mySessionsOneToOneLoading,
  } = props;

  const location = useLocation()
  const history = useHistory();

  const [state, setState] = useState({
    modal: false,
    status: location.state === "handler" ? 'oneToOne' : 'group',
    subStatus: 'upcoming',
    confirmModal: false,
  })

  let sortedAppointments, oneToOneSorted;
  if (mySessionsGroupData.length > 0) {
    sortedAppointments = mySessionsGroupData?.sort((a, b) => {
      const dateA = new Date(a.appointment.date);
      const dateB = new Date(b.appointment.date);
      return dateB - dateA;
    });
  }

  if (mySessionsOneToOneData.length > 0) {
    oneToOneSorted = mySessionsOneToOneData?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  }
  const [schedules, setSchedules] = useState([
    {
      id: 3,
      title: "Law Enforcement Discussion",
      start_time: "09:00 am",
      end_time: "10:00 am",
      day: "18",
      price: "$22",
      month: "July",
      capacity: "120",
      persons: 69,
      open: true,
      status: "open",
    },
    {
      id: 4,
      title: "Money Laundering Case Discussion",
      start_time: "10:00 am",
      end_time: "11:00 am",
      day: "20",
      price: "$57.7",
      month: "August",
      capacity: "100",
      persons: 88,
      open: false,
      status: "cancelled",
    },
    {
      id: 5,
      title: "Session Related to Criminal Law",
      start_time: "11:00 am",
      end_time: "12:00 pm",
      day: "20",
      month: "August",
      price: "$ 109.90",
      capacity: "60",
      persons: 19,
      open: false,
      status: "completed",
    },
  ]);


  useEffect(() => {
    mySessionsOneToOne();
    mySessionsGroup();
    setState((prevState) => ({
      ...prevState,
      confirmModal: true
    }))
  }, []);

  const openSchedule = (id) => {
    const schedulesCopy = [...schedules];
    schedulesCopy.forEach((elem) => {
      if (elem.id === id) {
        elem.is_open = true;
      }
    });
    setSchedules(schedulesCopy);
  };

  const closeSchedule = (id) => {
    const schedulesCopy = [...schedules];
    schedulesCopy.forEach((elem) => {
      if (elem.id === id) {
        elem.is_open = false;
      }
    });
    setSchedules(schedulesCopy);
  };

  const togglePersons = (id, type) => {
    let schedulesCopy = [...schedules];
    const schedule = schedulesCopy.find((value) => value.slug === id);

    if (type === "enlarge") {
      schedule.persons = schedule.persons + 1;
    } else {
      if (!(schedule.persons === 0)) {
        schedule.persons = schedule.persons - 1;
      }
    }

    setSchedules(schedulesCopy);
  };
  const getMonthFromString = (monthString) => {
    return new Date(Date.parse(monthString + " 1, 2000")).getMonth();
  };

  function convertTo24HourFormat(time12Hour) {
    const [time, ampm] = time12Hour.split(' ');
    const [hours, minutes] = time.split(':');
    let hours24;

    if (ampm === 'AM') {
      hours24 = hours === '12' ? '00' : hours;
    } else {
      hours24 = hours === '12' ? '12' : (parseInt(hours, 10) + 12).toString();
    }

    return `${hours24}:${minutes}`;
  }

  const time24HrSplit = (timeGet) => {
    const [hours, minutes] = timeGet.split(':');

    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);
    const newMinutesInt = (minutesInt + 30) % 60;
    const newHoursInt = hoursInt + Math.floor((minutesInt + 30) / 60);

    if (newHoursInt >= 24) {
      newHoursInt -= 24;
    }

    const newTime = `${newHoursInt.toString().padStart(2, '0')}:${newMinutesInt.toString().padStart(2, '0')}`;

    return newTime

  }

  const pastDateCheck = (item) => {
    let one2oneMonths, one2oneDay;
    one2oneMonths = new Date(item?.appointment?.date).toLocaleString(
      "default",
      { month: "short" }
    );
    one2oneDay = new Date(item?.appointment?.date).getDate();
    let providedYear = new Date(item?.appointment?.date).getFullYear();
    const now = new Date();
    const providedDate = new Date(
      providedYear,
      getMonthFromString(one2oneMonths),
      parseInt(one2oneDay)
    );
    let monthProvided = providedDate.getMonth() + 1;
    let monthNow = now.getMonth() + 1;
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    let providedHrs = time24HrSplit(convertTo24HourFormat(item.appointment.start_time)).split(':')[0]
    let providedMinutes = time24HrSplit(convertTo24HourFormat(item.appointment.start_time)).split(':')[1]
    providedDate.setHours(providedHrs)
    providedDate.setMinutes(providedMinutes)
    if ((providedYear < now.getFullYear())) {
      return 'Past'
    } else if (providedYear > now.getFullYear()) {
      return 'Up Coming'
    } else if (providedYear === now.getFullYear()) {
      if (monthProvided < monthNow) {
        if (providedDate.getDate() < now.getDate()) {
          return "Past";
        } if (providedDate.getDate() > now.getDate()) {
          return 'Past'
        } if (providedDate.getDate() === now.getDate()) {
          return 'Past'
        }
      }
      if (monthProvided > monthNow) {
        if (providedDate.getDate() < now.getDate()) {
          return 'Up Coming'
        } if (providedDate.getDate() > now.getDate()) {
          return 'Up Coming'
        } if (providedDate.getDate() === now.getDate()) {
          return 'Up Coming'
        }
      }
      if (monthProvided === monthNow) {
        if (providedDate.getDate() < now.getDate()) {
          return 'Past'
        } if (providedDate.getDate() > now.getDate()) {
          return 'Up Coming'
        } if (providedDate.getDate() === now.getDate()) {
          if (currentHours > parseInt(providedHrs) || (currentHours === parseInt(providedHrs) && currentMinutes > parseInt(providedMinutes))) {
            return 'Past';
          } else {
            return 'Up Coming';
          }
        }
      }
    }
  };

  const pastDateCheckOne = (item) => {
    let one2oneMonths, one2oneDay;
    one2oneMonths = new Date(item?.date).toLocaleString("default", {
      month: "short",
    });
    one2oneDay = new Date(item?.date).getDate();
    let providedYear = new Date(item?.date).getFullYear();
    const now = new Date();
    const providedDate = new Date(
      providedYear,
      getMonthFromString(one2oneMonths),
      parseInt(one2oneDay)
    );
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    let providedHrs = time24HrSplit(convertTo24HourFormat(item.created_at)).split(':')[0]
    let providedMinutes = time24HrSplit(convertTo24HourFormat(item.created_at)).split(':')[1]
    providedDate.setHours(providedHrs)
    providedDate.setMinutes(providedMinutes)
    let monthProvided = providedDate.getMonth() + 1;
    let monthNow = now.getMonth() + 1;
    if (item?.deactivate === false) {
      if(providedYear < now.getFullYear()){
        return "Past"
      } else if(providedYear > now.getFullYear()){
        return "Up Coming"
      } else if(providedYear === now.getFullYear()){
        if (monthProvided < monthNow) {
          if (providedDate.getDate() < now.getDate()) {
            return "Past";
          } if (providedDate.getDate() > now.getDate()) {
            return 'Past'
          } if (providedDate.getDate() === now.getDate()) {
            return 'Past'
          }
        }
        if (monthProvided > monthNow) {
          if (providedDate.getDate() < now.getDate()) {
            return 'Up Coming'
          } if (providedDate.getDate() > now.getDate()) {
            return 'Up Coming'
          } if (providedDate.getDate() === now.getDate()) {
            return 'Up Coming'
          }
        }
        if (monthProvided === monthNow) {
          if (providedDate.getDate() < now.getDate()) {
            return 'Past'
          } if (providedDate.getDate() > now.getDate()) {
            return 'Up Coming'
          } if (providedDate.getDate() === now.getDate()) {
            if (currentHours > providedHrs || (currentHours === providedHrs && currentMinutes > providedMinutes)) {
              return 'Past';
            } else {
              return 'Up Coming';
            }
          }
        }
      }
    }
  };

  const cancelCheckSession = (item) => {
    if (item.deactivate === true) {
      return "Cancel";
    }
  };

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Your Sessions Select</title>
        <meta name="description" content="Your Sessions Description" />
        <link rel="canonical" href="https://simplestudying.com/user-sessions" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Your Sessions" />
        <meta property="og:description" content="Your Sessions Description" />
        <meta
          property="og:url"
          content="https://simplestudying.com/user-sessions"
        />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      {mySessionsGroupLoading && mySessionsOneToOneLoading ? (
        <FullLoader />
      ) : (
        <div className="">
          <Container>
            <div className="pt-4 lg:pt-14 flex mb-10">
              <div className="text-darkest flex justify-between w-full">
                <div>
                  <p className="text-h3  font-bold mb-10 custom2:mb-8 "></p>
                </div>
              </div>
            </div>
            <div className="tutorTabs">
              <div
                className="mb-4 border-b "
                style={{ borderColor: "#cc6828", position: "relative" }}
              >
                <ul
                  className="flex flex-wrap -mb-px text-sm font-medium text-center"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li className="mr-2" role="presentation">
                    <button
                      className={`rounded-none inline-block px-4 py-2 ${state.status === "group" && " bg-primary text-white"
                        }`}
                      id="profile-tab"
                      data-tabs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          status: 'group',
                          subStatus: 'upcoming'
                        }))
                      }}
                    >
                      Group
                    </button>
                  </li>
                  <li className="mr-2" role="presentation">
                    <button
                      className={`rounded-none inline-block px-4 py-2 ${state.status === "oneToOne" && " bg-primary text-white"
                        }`}
                      id="dashboard-tab"
                      data-tabs-target="#dashboard"
                      type="button"
                      role="tab"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          status: 'oneToOne',
                        }))}
                      aria-controls="dashboard"
                      aria-selected="false"
                    >
                      One-to-One
                    </button>
                  </li>
                </ul>
                <button
                  className="scheduleBtn"
                  onClick={() => {
                    history.push("/boost-your-grades");
                  }}
                >
                  Schedule a Tutoring Session
                </button>
              </div>
              <div id="myTabContent" className="overflow-y-auto">
                <div
                  className="mb-4 border-b "
                  style={{ borderColor: "#fff", position: "relative" }}
                >
                  <ul
                    className="tagsRow flex flex-wrap -mb-px text-sm font-medium text-center"
                    id="myTab"
                    data-tabs-toggle="#myTabContent"
                    role="tablist"
                  >
                    <li className="mr-2" role="presentation">
                      <button
                        className={`rounded-none inline-block px-4 py-2 ${state.subStatus === "upcoming"
                            ? " bg-primary text-white"
                            : " bg-gray text-black"
                          }`}
                        id="Past"
                        data-tabs-target="#Past"
                        type="button"
                        role="tab"
                        onClick={() => setState((prevState) => ({
                          ...prevState,
                          subStatus: "upcoming"
                        }))}
                        aria-controls="Past"
                        aria-selected="false"
                      >
                        Upcoming
                      </button>
                    </li>
                    <li className="mr-2" role="presentation">
                      <button
                        className={`rounded-none inline-block px-4 py-2 ${state.subStatus === "past"
                            ? " bg-primary text-white"
                            : " bg-gray text-black"
                          }`}
                        id="dashboard-tab"
                        data-tabs-target="#dashboard"
                        type="button"
                        role="tab"
                        onClick={() => setState((prevState) => ({
                          ...prevState,
                          subStatus: "past"
                        }))}
                        aria-controls="dashboard"
                        aria-selected="false"
                      >
                        Past
                      </button>
                    </li>
                    {/* {status === "oneToOne" ? (
                      // <li className="mr-2" role="presentation">
                      //   <button
                      //     className={`rounded-none inline-block px-4 py-2 ${
                      //       subStatus === "cancel"
                      //         ? " bg-primary text-white"
                      //         : " bg-gray text-black"
                      //     }`}
                      //     id="cancelTab-tab"
                      //     data-tabs-target="#cancelTab"
                      //     type="button"
                      //     role="tab"
                      //     onClick={() => {
                      //       setSubStatus("cancel");
                      //     }}
                      //     aria-controls="cancelTab"
                      //     aria-selected="false"
                      //   >
                      //     Cancelled
                      //   </button>
                      // </li>
                    ) : null} */}
                  </ul>
                </div>
                {state.status === "group" ? (
                  <div id="myTabContent" className="overflow-y-auto">
                    {state.subStatus === "past" ? (
                      <div
                        className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                        id="Past"
                        role="tabpanel"
                        aria-labelledby="Past-tab"
                      >
                        <div
                          className="space-y-3.5 max-h-925 px-3.5 "
                          id="session-container"
                        >
                          <div
                            className={`${state.status === "oneToOne" && "hidden "
                              } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div
                              className="space-y-3.5 max-h-925 px-3.5 "
                              id="session-container"
                            >
                              {mySessionsGroupData.length
                                ? sortedAppointments?.map((elem, index) => {
                                  if (pastDateCheck(elem) === "Past") {
                                    return (
                                      <TutorSessionStudents
                                        status={elem.status}
                                        type={state.status}
                                        loading={mySessionsGroupLoading}
                                        key={elem.id}
                                        open={elem.is_open}
                                        numOfPersons={elem.persons}
                                        data={elem}
                                        date={location?.state?.calendarDate}
                                        openSchedule={() =>
                                          openSchedule(elem.id)
                                        }
                                        closeSchedule={() =>
                                          closeSchedule(elem.id)
                                        }
                                        enlargePersons={() =>
                                          togglePersons(elem.id, "enlarge")
                                        }
                                        reducePersons={() =>
                                          togglePersons(elem.id, "reduce")
                                        }
                                        book={() => setState((prevState) => ({
                                          ...prevState,
                                          modal: true
                                        }))}
                                      />
                                    );
                                  }
                                })
                                : "You do not have any tutoring session scheduled"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : state.subStatus === "upcoming" ? (
                      <div
                        className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                        id="upComing"
                        role="tabpanel"
                        aria-labelledby="upComing-tab"
                      >
                        <div
                          className="space-y-3.5 max-h-925 px-3.5 "
                          id="session-container"
                        >
                          <div
                            className={`${state.status === "oneToOne" && "hidden "
                              } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div
                              className="space-y-3.5 max-h-925 px-3.5 "
                              id="session-container"
                            >
                              {mySessionsGroupData.length
                                ? sortedAppointments?.map((elem, index) => {
                                  if (pastDateCheck(elem) === "Up Coming") {
                                    return (
                                      <TutorSessionStudents
                                        status={elem.status}
                                        type={state.status}
                                        loading={mySessionsGroupLoading}
                                        key={elem.id}
                                        open={elem.is_open}
                                        numOfPersons={elem.persons}
                                        data={elem}
                                        date={location?.state?.calendarDate}
                                        openSchedule={() =>
                                          openSchedule(elem.id)
                                        }
                                        closeSchedule={() =>
                                          closeSchedule(elem.id)
                                        }
                                        enlargePersons={() =>
                                          togglePersons(elem.id, "enlarge")
                                        }
                                        reducePersons={() =>
                                          togglePersons(elem.id, "reduce")
                                        }
                                        book={() => setState((prevState) => ({
                                          ...prevState,
                                          modal: true
                                        }))}
                                      />
                                    );
                                  }
                                })
                                : "You do not have any tutoring session scheduled"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : state.status === "oneToOne" ? (
                  <div id="myTabContent" className="overflow-y-auto">
                    {state.subStatus === "past" ? (
                      <div
                        className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                        id="Past"
                        role="tabpanel"
                        aria-labelledby="Past-tab"
                      >
                        <div
                          className="space-y-3.5 max-h-925 px-3.5 "
                          id="session-container"
                        >
                          <div
                            className={`${state.status === "group" && "hidden "
                              } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                            id="dashboard"
                            role="tabpanel"
                            aria-labelledby="dashboard-tab"
                          >
                            {mySessionsOneToOneData?.length
                              ? mySessionsOneToOneData?.map((elem, index) => {
                                if (pastDateCheckOne(elem) === "Past") {
                                  return (
                                    <TutorSessionStudents
                                      status={elem.status}
                                      type={state.status}
                                      key={elem.id}
                                      loading={mySessionsOneToOneLoading}
                                      open={elem.is_open}
                                      numOfPersons={elem.persons}
                                      oneToOne={elem}
                                      date={location?.state?.calendarDate}
                                      openSchedule={() =>
                                        openSchedule(elem.id)
                                      }
                                      closeSchedule={() =>
                                        closeSchedule(elem.id)
                                      }
                                      enlargePersons={() =>
                                        togglePersons(elem.id, "enlarge")
                                      }
                                      reducePersons={() =>
                                        togglePersons(elem.id, "reduce")
                                      }
                                      book={() => setState((prevState) => ({
                                        ...prevState,
                                        modal: true
                                      }))}
                                    />
                                  );
                                }
                              })
                              : "You do not have any tutoring session scheduled"}
                          </div>
                        </div>
                      </div>
                    ) : state.subStatus === "upcoming" ? (
                      <div
                        className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                        id="upComing"
                        role="tabpanel"
                        aria-labelledby="upComing-tab"
                      >
                        <div
                          className={`${state.status === "group" && "hidden "
                            } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                          id="dashboard"
                          role="tabpanel"
                          aria-labelledby="dashboard-tab"
                        >
                          {mySessionsOneToOneData?.length
                            ? oneToOneSorted?.map((elem, index) => {
                              if (pastDateCheckOne(elem) === "Up Coming") {
                                return (
                                  <TutorSessionStudents
                                    status={elem.status}
                                    type={state.status}
                                    key={elem.id}
                                    loading={mySessionsOneToOneLoading}
                                    open={elem.is_open}
                                    numOfPersons={elem.persons}
                                    oneToOne={elem}
                                    date={location?.state?.calendarDate}
                                    openSchedule={() => openSchedule(elem.id)}
                                    closeSchedule={() =>
                                      closeSchedule(elem.id)
                                    }
                                    enlargePersons={() =>
                                      togglePersons(elem.id, "enlarge")
                                    }
                                    reducePersons={() =>
                                      togglePersons(elem.id, "reduce")
                                    }
                                    book={() => setState((prevState) => ({
                                      ...prevState,
                                      modal: true
                                    }))}
                                  />
                                );
                              }
                            })
                            : "You do not have any tutoring session scheduled"}
                        </div>
                      </div>
                    ) :
                      // subStatus === "cancel" ? (
                      //   <div
                      //     className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                      //     id="cancelTab"
                      //     role="tabpanel"
                      //     aria-labelledby="cancelTab-tab"
                      //   >
                      //     <div
                      //       className={`${
                      //         status === "group" && "hidden "
                      //       } py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                      //       id="dashboard"
                      //       role="tabpanel"
                      //       aria-labelledby="dashboard-tab"
                      //     >
                      //       {mySessionsOneToOneData?.length
                      //         ? oneToOneSorted?.map((elem, index) => {
                      //             if (cancelCheckSession(elem) === "Cancel") {
                      //               return (
                      //                 <TutorSessionStudents
                      //                   status={elem.status}
                      //                   type={status}
                      //                   key={elem.id}
                      //                   loading={mySessionsOneToOneLoading}
                      //                   open={elem.is_open}
                      //                   numOfPersons={elem.persons}
                      //                   oneToOne={elem}
                      //                   date={location?.state?.calendarDate}
                      //                   openSchedule={() => openSchedule(elem.id)}
                      //                   closeSchedule={() =>
                      //                     closeSchedule(elem.id)
                      //                   }
                      //                   enlargePersons={() =>
                      //                     togglePersons(elem.id, "enlarge")
                      //                   }
                      //                   reducePersons={() =>
                      //                     togglePersons(elem.id, "reduce")
                      //                   }
                      //                   book={() => setModal(true)}
                      //                 />
                      //               );
                      //             }
                      //           })
                      //         : "You do not have any tutoring session scheduled"}
                      //     </div>
                      //   </div>
                      // ) 
                      null}
                  </div>
                ) : null}
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  mySessionsGroupData: mySessionsGroupData(state),
  mySessionsGroupLoading: mySessionsGroupLoading(state),
  mySessionsOneToOneLoading: mySessionsOneToOneLoading(state),
  mySessionsOneToOneData: mySessionsOneToOneData(state),
});

const mapDispatchToProps = (dispatch) => ({
  mySessionsOneToOne: () => dispatch(mySessionsOneToOne()),
  mySessionsGroup: () => dispatch(mySessionsGroup()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
