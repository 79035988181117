import React, { useState, useEffect } from 'react'
import Select from "../../components/Select";
import Input from "../../components/Input/InputForm";
import ReactTooltip from 'react-tooltip';
const values = [
    { value: '£', label: '£' }
]

const PricingPage = () => {
    const [value, setValue] = useState('')
    const [showTooltip, setShowTooltip] = useState(false)
    const [text, setText] = useState('Here you can put the price')

    const copyMessage = () => {
        return text
    }

    useEffect(() => {
        if (!value || value === "") {
            setText("Here you can put the price")
        }
        else if (value < 15) {
            setText("Your price is in the below average range. If you want to achieve the average, your values ​​should be in the £15 - £30 range.")
        }
        else if (value >= 15 && value <= 30) {
            setText("You put an average price")
        }
        else if (value > 35) {
            setText("Your price is in the above average range. If you want to achieve the average, your values ​​should be in the £15 - £35 range.")
        }


    }, [value])

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [text])

    return (
        <div className="flex flex-row mt-12 pb-20 md:pb-32 custom2:pb-52">
            <div className="w-44 md:w-32 mr-5 ">
                <Select
                    values={values}
                    placeholder="£"
                />
            </div>

            <div data-tip={copyMessage()} onFocusCapture={() => setShowTooltip(true)}
                className="relative cursor-pointer w-273 z-10">
                <div className="w-32 md:w-273 mt-2" >
                    <Input type="number" placeholder="" value={value} setValue={setValue} />
                    <ReactTooltip className="w-52 p-5 bg-white text-small-text text-darkGrey rounded-xl shadow" getContent={copyMessage} effect="solid" place="top" backgroundColor="white" arrowColor="white" textColor="#8E8E8E" />

                </div>
            </div>

        </div>
    )
}

export default PricingPage
