import React, {useState} from "react";

import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import {Document, Page, pdfjs} from "react-pdf";
import {getApiKey} from "../../helpers";

import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {useHistory} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const FreeUsers = ({data, slug}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    let user = JSON.parse(localStorage.getItem('app_user'))
    const [document, setDocument] = useState(false)

    const history = useHistory()

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const upgradeMembership = () => {
        if (!getApiKey()) {
            history.push('/subscription')
        }
        if ((user?.subscription_name === 'Basic Subscription' || user?.subscription_name === 'FREE_PLAN') && user?.free_trial === true) {
            setDocument(true)
        }
        if ((user?.subscription_name === 'Basic Subscription' || user?.subscription_name === 'FREE_PLAN') && user?.free_trial === false) {
            history.push('/subscription')
        } else {
            setDocument(true)
        }
    };

    function convertSlugToText(text) {
        let slug = text.split("-");
        for (let i = 0; i < slug.length; i++) {
            let word = slug[i];
            slug[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return slug.join(" ");
    }


    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    return (
        <div className="overflow-hidden relative bg-white relative min-h-1400 xl:min-h-1200">
            <Container>
                {getApiKey() || !getApiKey() ?
                    <div
                        className="w-full flex flex-col md:flex-row justify-center items-center mt-14 rounded-3xl"
                        style={{
                            border: "1px solid #D8D8D8",
                            boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
                        }}
                    >
                        <p className="font-bold text-14 text-center px-4 md:px-0 pt-2 md:pt-0 md:text-16 md:mr-2 lg:mr-0 lg:text-h5">
                            Your membership allows you to access the full content
                        </p>
                        <div className="px-4 md:px-0 w-full md:w-48 py-4 lg:ml-10">
                            <Button filled onClick={() => {
                                upgradeMembership()
                            }}>
                                <p className="text-16 md:text-body-large font-normal">
                                    View Document
                                </p>
                            </Button>
                        </div>
                    </div>
                    : null}
                {document === false ? <div className="pt-12">
                    <p className="md:text-large-medium lg:text-36 font-bold pb-10">
                        {convertSlugToText(slug)}
                    </p>
                    <section className="text-body-large">
                        <Document
                            file={
                                data?.value?.document ? data?.value?.document : data?.document
                            }
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} width={900}/>
                        </Document>
                    </section>
                </div> : document === true ?
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <div style={{height: "720px"}}>
                            <Viewer
                                fileUrl={
                                    data?.value?.document
                                        ? data?.value?.document
                                        : data?.document
                                }
                                plugins={[defaultLayoutPluginInstance]}
                            />
                        </div>
                    </Worker> : null}

            </Container>
            {/*<div className="absolute bottom-0 left-0 w-full bg-primary-bottom-bg bg-no-repeat bg-bottom" style={{*/}
            {/*    backgroundColor: 'background: rgba(255, 255, 255, 0.3)',*/}
            {/*    height: '426px',*/}
            {/*}}>*/}
            {/*    <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20 mt-52 md:mt-72">*/}
            {/*        <div className="w-11/12 custom:w-1/4 xl:w-80">*/}
            {/*            <Button filled>*/}
            {/*                <p className="text-center text-14 xl:text-body-large">Get All Contract Law <br/> Materials*/}
            {/*                    FOR ONLY £20</p>*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <p className="my-8 font-bold text-17 md:mx-5 xl:mx-7">OR</p>*/}
            {/*        </div>*/}
            {/*        <div className="w-11/12 custom:w-1/4 xl:w-80">*/}
            {/*            <Button filled>*/}
            {/*                <p className="text-center text-14 xl:text-body-large">Get all the Study Modules <br/>for*/}
            {/*                    £7.5/month</p>*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='bg-white bg-notes-bottom bg-no-repeat bg-bottom'>*/}
            {/*<Container>*/}

            {/*    <div className='pt-28 pb-10 custom:pb-24'>*/}
            {/*        <div className='flex flex-col items-center md:flex-row custom:justify-center md:px-20'>*/}
            {/*            /!*<div className='w-10/12 custom:w-1/3 xl:w-96'>*!/*/}
            {/*            /!*    <Button filled>*!/*/}
            {/*            /!*        <p className='text-center text-14 xl:text-body-large'>Get All Contract*!/*/}
            {/*            /!*            Law <br/> Materials FOR ONLY £20</p>*!/*/}
            {/*            /!*    </Button>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            /!*<div>*!/*/}
            {/*            /!*    <p className='my-8 font-bold text-17 md:mx-5 xl:mx-7'>OR</p>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            /!*<div className='w-10/12 custom:w-1/3 xl:w-96'>*!/*/}
            {/*            /!*    <Button filled>*!/*/}
            {/*            /!*        <p className='text-center text-14 xl:text-body-large'>Get all the Study*!/*/}
            {/*            /!*            Modules <br/>for £7.5/month</p>*!/*/}
            {/*            /!*    </Button>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Container>*/}
            {/*</div>*/}
        </div>
    );
};

export default FreeUsers;
