import * as t from "../types";
import { PostContentEmail } from "../../services";
import { createErrorAlert } from "./errorAlertAction";

export const contentEmailAction = (email) => async (dispatch) => {
  dispatch({
    type: t.POST_CONTENT_EMAIL_REQUEST,
  });

  return await PostContentEmail(email)
    .then((data) => {
      dispatch(
        createErrorAlert(
          204,
          "Thank You",
          "Your Email has been saved as a Content Creator",
          204
        )
      );
      setTimeout(() => {
        dispatch({
          type: t.POST_CONTENT_EMAIL_SUCCESS,
          payload: data.data,
        });
      },5000)
    })
    .catch((err) => {
      dispatch({
        type: t.POST_CONTENT_EMAIL_FAILED,
        payload: err.response,
      });
    });
};
