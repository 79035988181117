import React from 'react';
import { Modal } from 'react-responsive-modal';
import './modal.css';
import Button from '../EmptyButton';

import SignupCheckbox from '../../components/CheckBoxQuestion/SignupCheckbox';
import { useHistory } from 'react-router-dom';

const CustomModal = ({
    isOpen,
    closeModal,
    title,
    buttonOneText,
    onClickFirstButton,
    subtitle,
    handleChange,
    degrees,
    handleClick, loading,
}) => {
    return (
        <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center>
            <p className="text-darkest text-large text-h5 custom2:text-h4 font-bold">{title}</p>
            <p className="text-15 font-normal text-darkGrey">{subtitle}</p>
            <div className="space-y-3.5 max-h-200 overflow-y-auto mt-6" id="session-container">
                {degrees.map((item, index) => <SignupCheckbox id={item.item.id} handleChange={handleChange}
                    text={item.text}
                    correct={item.correct} />)}
            </div>

            <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
                <div className="w-56 mt-5 md:mt-0">
                    {buttonOneText !== null && <Button filled loading={loading} onClick={() => {
                        handleClick();
                        onClickFirstButton()
                    }}>
                        <p className="text-center text-14 xl:text-body-large">{buttonOneText}</p>
                    </Button>}
                </div>
            </div>
        </Modal>
    );
};

export default CustomModal;
