import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import Input from '../../components/SearchInput';
import ModelEssayCard from '../../components/ModelEssayCard';
import Pagination from '../../components/Pagination';
import NotesImage from '../../assets/images/main-page-materials/essays.webp';
import {getEssays} from '../../redux/actions';
import {essaysData, essaysError, essaysLoading,} from '../../redux/selectors';
import {useHistory} from 'react-router-dom';
import FullLoader from '../../components/Loader/FullLoader';
import {getApiKey, slugToText} from '../../helpers';
import {Helmet} from "react-helmet";
import NotesTile from '../../components/NotesTiles';
import TileImage from '../../assets/newImages/Group 1439.png'


const ModelEssays = (props) => {
    const {data, loading, getEssays, match} = props;
    const [searchText, setSearchText] = useState('');
    const slug = match.params.slug;
    const history = useHistory();
    const [showEssays, setEssays] = useState([]);
    const [pageState, setPageState] = useState({
        offset: 0,
        perPage: 12,
        currentPage: 1,
        pageCount: 0,
    });
    const breadCrumbItem = [{title: 'Get study modules', link: '/'}, {
        title: slugToText(slug),
        link: `/study-module/${slug}`,
    }];

    useEffect(() => {
        getEssays(null, null, slug);
    }, []);

    useEffect(() => {
        if (data?.results?.length) {
            const endOffset = pageState.offset + pageState.perPage;
            pageState.pageCount = Math.ceil(
                data.count / pageState.perPage,
            );
            setPageState(pageState);
            for (let i = 0; i < data?.results?.length; i++) {
                if (!data.results?.includes('check_description.com')) {
                    setEssays(data.results);
                }
            }
        }
    }, [data]);

    const searchEssays = () => {
        getEssays(1, searchText, slug);
    };

    const pageHandler = (page) => {
        let selectedPage = page.selected;
        const offset = selectedPage * pageState.perPage;
        pageState.currentPage = selectedPage;
        pageState.offset = offset;
        setPageState(pageState);
        getEssays(page.selected + 1, null, slug);
    };
    const subscription = () => {
        history.push('/subscription');
    };

    return (
        <>
            {loading ? (<FullLoader/>) : null}
            <div className="bg-white">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Simple Studying | {slugToText(slug)} | Model Essays </title>
                    <meta name="description" content="Model Essays Description"/>
                    <link rel="canonical" href={`https://simplestudying.com/${slug}/model-essays`}/>
                    <meta name="robots" content="index, follow"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:locale:alternate" content="en_gb"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={`Simple Studying | ${slugToText(slug)} | Model Essays`}/>
                    <meta property="og:description" content="Model Essays Description"/>
                    <meta property="og:url" content={`https://simplestudying.com/${slug}/model-essays`}/>
                    <meta property="og:site_name" content="Simple Studying"/>
                </Helmet>
                <div className="bg-main-page-back bg-top bg-no-repeat">
                    <Container>
                        {getApiKey() ? <div className="py-4 md:py-8 lg:py-14">
                            <Breadcrumb items={breadCrumbItem} current={'Model Essays'}/>
                        </div> : null}

                        <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                            <p className="text-h5 md:text-h3 lg:text-36 font-bold">{slugToText(slug)} Model Essays</p>
                            <div>
                                <div
                                    className="pt-4 md:pt-0 custom:w-80 md:block md:w-60 xl:w-80 md:flex-2 flex-initial">
                                    <Input placeholder="Search Model Essays" search auth handleClick={searchEssays}
                                           value={searchText} setValue={setSearchText}/>
                                </div>
                            </div>
                        </div>
                        {data?.results?.length === 0 ? (
                            <p style={{textAlign: 'center', marginTop: '50px'}}>No Results Found</p>
                        ) : (
                            <div className="pt-12 grid md:grid-cols-2 lg:grid-cols-3 gap-5">
                                {showEssays.map(elem => (
                                        <NotesTile image={TileImage} text={elem} type={'essays'}
                                                        prevSlug={slug} slug={elem.slug}/>

                                ))}
                            </div>
                        )}

                    </Container>
                </div>
                <div className="bg-white bg-notes-bottom bg-no-repeat bg-bottom">
                    <Container>
                        <div className="pt-14 flex justify-center">
                            <Pagination onPageChange={pageHandler} pageCount={pageState.pageCount}/>
                        </div>
                        <div className="pt-28 pb-10 custom:pb-24">
                            <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20">
                                {/*<div className="w-10/12 custom:w-1/4 xl:w-96">*/}
                                {/*    <Button filled>*/}
                                {/*        <p className="text-center text-14 xl:text-body-large">Get All Contract*/}
                                {/*            Law <br/> Materials FOR ONLY £20</p>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                                {/*<div>*/}
                                {/*    <p className="my-8 font-bold text-17 md:mx-5 xl:mx-7">OR</p>*/}
                                {/*</div>*/}
                                {/*<div className="w-10/12 custom:w-1/4 xl:w-96">*/}
                                {/*    <Button filled onClick={subscription}>*/}
                                {/*        <p className="text-center text-14 xl:text-body-large">Get all the Study*/}
                                {/*            Modules <br/>for £7.5/month</p>*/}
                                {/*    </Button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = state => ({
    loading: essaysLoading(state),
    error: essaysError(state),
    data: essaysData(state),
});

const mapDispatchToProps = dispatch => ({

    getEssays: (page, title, slug) => dispatch(getEssays(page, title, slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ModelEssays);
