import React, { useState } from 'react'

import Container from '../../components/container'
import WithoutSteps from '../../components/ContentCreatorStepper/WithoutSteps'
import Select from "../../components/Select";
import Button from '../../components/EmptyButton'

const StepOne = () => {
    const [email, setEmail] = useState('')
    return (
        <div className='bg-white bg-subscription-bottom bg-bottom bg-no-repeat'>
            <WithoutSteps />
            <Container>
                <div className='pt-10 lg:pt-14 flex' >
                    <div>
                        <p className='custom2:text-36 font-bold'>You can publish your content here:</p>
                    </div>
                </div>
                <div className="mt-50 text-body-large flex flex-col md:flex-row  md:space-x-20 lg:space-x-40 ">
                    <form className="flex flex-col w-full md:w-2/4" onSubmit={(e) => console.log({ email })}>
                        <div>
                            <label className='mb-8' >
                                Indicate university where you studied this study module
                            </label>
                            <Select
                                placeholder="Add university"
                            />
                        </div>
                        <div className="mt-4">
                            <label className='mb-8'>
                                Study module:
                            </label>
                            <Select
                                placeholder=""
                            />
                        </div>

                    </form>
                    <div className="flex flex-col w-full md:w-2/4" >
                        <p className="text-body-large mb-30">
                            Grade
                        </p>
                        <p className="text-body-large">
                            What was your grade for that study module?
                        </p>
                        <p className="text-15 text-darkGrey">
                            Notes: We only accept 2.1 Class and First Class
                        </p>

                        <div key={1} className="flex items-center mt-16">
                            <input
                                id={1}
                                name="notification-method"
                                type="radio"
                                defaultChecked={true}
                                className="focus:red h-4 w-4 text-red red border-gray-300"
                            />
                            <label htmlFor={1} className="ml-3 block text-sm font-medium text-gray-700">
                                2.1 Class
                            </label>
                        </div>
                        <div key={2} className="flex items-center mt-16">
                            <input
                                id={2}
                                name="notification-method"
                                type="radio"
                                defaultChecked={false}
                                className="focus:bg-primary h-4 w-4 checked:bg-primary  border-gray-300"
                            />
                            <label htmlFor={2} className="ml-3 block text-sm font-medium text-gray-700">
                                First Class
                            </label>
                        </div>
                    </div>
                </div>


                <div className='py-20'>
                    <div className='flex flex-col items-center md:flex-row justify-center md:px-20'>
                        <div className='w-10/12 md:w-80  custom2:w-472'>


                            <Button filled  >
                                <p className='text-center text-14 xl:text-body-large'>Continue</p>
                            </Button>
                            <p className='text-15 mt-30'>
                                Join our <a className="text-primary" href="#">Slack Community</a> of Content Creators to Learn from Others and Share your Experience
                            </p>
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default StepOne
