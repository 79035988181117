import * as t from '../types';
import {GetEssays} from '../../services'

export const getEssays = (page, search,slug) => async (dispatch) => {
    dispatch({
        type: t.GET_ESSAYS_REQUEST
    });


    return await GetEssays(page, search,slug)

        .then((data) => {
            dispatch({
                type: t.GET_ESSAYS_SUCCESS,
                payload: data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_ESSAYS_FAILED,
                payload: err.response
            });
        });
};
