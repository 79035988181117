import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {GetPartners} from '../../services'

const OurPartners = () => {

    const [response, setResponse] = useState([]);
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
    }, [])

    useEffect(() => {
        GetPartners().then((res) => {
            setResponse(res)
        })
    },[])


    return (
        <div className="mt-10 bg-white" style={{scrollBehavior: 'smooth'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Our Partners</title>
                <meta name="description" content="Our Partners Description"/>
                <link rel="canonical" href="https://simplestudying.com/our-partners"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Our Partners"/>
                <meta property="og:description" content="Our Partners Description"/>
                <meta property="og:url" content="https://simplestudying.com/our-partners"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                <div className="ourPrtnerText text-large-small font-bold text-darkest md:text-large-medium">
                    <h2>Our Partners</h2>

                </div>
                {response?.map((item => {
                        return (
                            <div className={'partBoxOne text-center w-full mt-6 mb-12 '}>
                                <img src={item?.image} alt=""/>

                                <div className="pTitleText text-large-small font-bold text-darkest md:text-large-small">
                                    <h3>{item?.title}</h3>

                                </div>
                                <p className={'partBoxP'} dangerouslySetInnerHTML={{__html: item?.description}}>

                                </p>
                                <p className={'partBoxLink'}><a href={item?.url}
                                                                target="_blank">{item?.url}</a></p>
                            </div>
                        )
                    }
                ))}

            </div>

        </div>
    );
}

export default OurPartners