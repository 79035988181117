import * as t from '../types';
import {GetContentEssay} from '../../services'


export const getContentEssayAction = (slug) => async (dispatch) => {
    dispatch({
        type: t.GET_CONTENT_ESSAY_REQUEST
    });

    return await GetContentEssay(slug)

        .then((data) => {
            dispatch({
                type: t.GET_CONTENT_ESSAY_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CONTENT_ESSAY_FAILED,
                payload: err.response
            });
        });
};
