import * as t from '../types';

const initialState = {
    data: '',
    loading: false,
    error: '',
    status:false,
};

export function signupReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.SIGNUP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                status:true,
            };
        case t.SIGNUP_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}

const initialStateForLogin = {
    data: '',
    loading: false,
    error: '',
    status:false,
};

export function loginReducer(state = initialStateForLogin, action) {
    const {type, payload} = action;
    switch (type) {
        case t.LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                status:true,
            };
        case t.LOGIN_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}


const initialStateForGoogle = {
    data: '',
    loading: false,
    error: '',
    status:false,
};

export function loginGoogleReducer(state = initialStateForGoogle, action) {
    const {type, payload} = action;
    switch (type) {
        case t.LOGIN_GOOGLE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.LOGIN_GOOGLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                status:true,
            };
        case t.LOGIN_GOOGLE_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}

const initialStateForFacebook = {
    data: '',
    loading: false,
    error: '',
    status:false,
};

export function loginFacebookReducer(state = initialStateForFacebook, action) {
    const {type, payload} = action;
    switch (type) {
        case t.LOGIN_FACEBOOK_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.LOGIN_FACEBOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
                status:true,
            };
        case t.LOGIN_FACEBOOK_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}

