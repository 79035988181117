import apiRequest from "../../helpers/apiRequest";

export default async (id) => {
    if(id !== null && id !== undefined) {
        return await apiRequest({
            method: 'delete',
            url: `content/basket/`,
            data: {id}
        })
    } else{
        return await apiRequest({
            method: 'get',
            url: 'content/basket/',
        })
    }
}
