import * as t from "../types";
import { ContactUs } from "../../services";

export const contactUsAction = (data, setInitialState) => async (dispatch) => {
  dispatch({
    type: t.POST_CONTACT_US_REQUEST,
  });

  return await ContactUs(data)
    .then((data) => {
      setInitialState((prev) => ({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      }));
      dispatch({
        type: t.POST_CONTACT_US_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: t.POST_CONTACT_US_FAILED,
        payload: err.response,
      });
    });
};
