import React from "react";
import { Modal } from "react-responsive-modal";
import Button from "../EmptyButton";
import Success from "../../assets/images/success.png";
import Select from "../Select";

const Badge = ({ startTime, endTime }) => (
  <div
    className="w-32 border border-borderGreen p-2 mb-3.5 mr-3.5 h-26 flex justify-center items-center cursor-pointer"
    style={{ borderRadius: "43px" }}
  >
    <p className="text-greenGradient font-normal text-14">
      {startTime} - {endTime}
    </p>
  </div>
);

const CustomModal = ({
  isOpen,
  closeModal,
  title,
  buttonOneText,
  buttonTwoText,
  onClickFirstButton,
  onClickSecondButton,
}) => {
  return (
    <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center>
      <div className="flex justify-center mb-10">
        <img src={Success} alt="success" />
      </div>

      <p className="text-darkest text-large md:text-h5 font-bold mb-5 text-center">
        {title}
      </p>
      <p className="text-center text-15 text-darkest">
        Thank you! Your booking is completed.
      </p>
      <div className="mt-14 w-full">
        <Select placeholder="Select Calendar" />
      </div>
      <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-12">
        <div className="w-56">
          <Button simple onClick={onClickFirstButton}>
            <p className="text-center text-14 xl:text-body-large">
              {buttonOneText}
            </p>
          </Button>
        </div>
        <div className="w-56 mt-5 md:mt-0">
          <Button filled onClick={onClickSecondButton}>
            <p className="text-center text-14 xl:text-body-large">
              {buttonTwoText}
            </p>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
