import store from '../reducers';
import {combineReducers} from 'redux';

const createReducer = (asyncReducers) =>
    combineReducers({
        store,
        ...asyncReducers
    });

export default createReducer;
