import React from 'react'
import AddIcon from '../../assets/images/addIcon.webp'

const NotesAdditionalContent = ({ setIsModalOpen }) => {
    return (
        <div>
            <div onClick={() => setIsModalOpen(true)} className="px-4 custom2:px-12 flex flex-row pb-16 items-center cursor-pointer">
                <img className="w-3.5 h-auto" src={AddIcon} alt="add" /> <span className="font-bold text-16 md:text-body-large text-primary ml-16">Add more</span>
            </div>
        </div>
    )
}

export default NotesAdditionalContent
