import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";

const Step4Content = ({ handlePrev }) => {
    const user = JSON.parse(localStorage.getItem('app_user'))
    const history = useHistory()
    const handleProfileRoute = () =>{
        history.push('/profile')
    }
  return (
    <div>
      <ul className="stepper-ul">
        <li>
          {user?.first_name}, we are sorry to see you go. We confirm your subscription is
          cancelled now. You will still have access until the end of your
          membership period.
        </li>
        <li>
          If our subscription did not work for you for whatever reason,<strong className="color-CC6828"> you can
          consider our tutoring services instead!</strong>  Tutoring is a personalised
          support from our tutors to assist you on every step of your studies.
          Our tutors can help you prepare for your exams, review your
          courseworks to give guidance and feedback. We can also help with
          mooting and dissertation. All tutoring sessions take place on Zoom.
        </li>
      </ul>
      <div className="flex justify-center xl:px-28 lg:px-18 px-2 pb-6 xl:mt-20 lg:mt-12 mt-4 w-4/6 xl:ml-36 mx-auto">
        <Button onClick={handleProfileRoute}>
          Go to Profile Page
        </Button>
      </div>
    </div>
  );
};
export default Step4Content;
