import React from 'react'
import InactiveIcon from '../../assets/images/inactiveIcon.png'
import ActiveIcon from '../../assets/images/activeIcon (2).webp'
import Button from '../EmptyButton'
const CustomRadioButton = ({ text, index, isActive, isPrevious }) => {
    return (
        <div key={index} className={`p-3.5 md:px-5 md:py-6 ${isActive && "bg-lightGray"} cursor-pointer flex flex-row items-center`} style={{ borderRadius: '14px' }}>
            <img alt="image" className="w-6 h-6" src={isPrevious ? ActiveIcon : InactiveIcon} /><span className="ml-1.5 md:ml-3.5 text-darkest font-normal text-16 md:text-body-large">{text}</span>
        </div>
    )
}

const Stepper = ({ indexActive, setIndexActive }) => {
    const steps = [{ index: 1, text: 'Notes' }, { index: 2, text: 'Model essays/Problem questions' }, { index: 3, text: 'Pricing' }, { index: 4, text: 'Pictures' },]
    return (
        <div className="flex flex-col w-full custom2:w-1/3 ">
            <p className="font-bold text-h5 md:text-h4 text-darkest md:mb-6">Plan your study module</p>
            <div className="bg-white " style={{ borderRadius: '14px' }}>
                {steps.map((item) => {
                    let isActive = false
                    let isPrevious = false
                    if (item?.index === indexActive) {
                        isActive = true
                    }
                    if (item?.index < indexActive) {
                        isPrevious = true
                    }
                    return <CustomRadioButton isActive={isActive} isPrevious={isPrevious} index={item?.index} text={item?.text} />
                })}
            </div>

            <div className='flex flex-col items-center md:flex-row justify-center w-full pt-10 pb-10 custom2:pt-16 custom2:pb-0'>
                <div className='w-full md:w-2/4 custom2:w-full'>
                    <Button onClick={() => {
                        if (indexActive !== 4) {
                            setIndexActive(indexActive + 1)
                        }
                    }} filled>
                        <p className='text-center text-14 xl:text-body-large'>Submit for review</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Stepper
