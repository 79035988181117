import React, {useEffect} from 'react'
import {Modal} from 'react-responsive-modal';
// import './modal.css'
import Input from '../Input/InputForm'
import Button from '../EmptyButton'
import Success from '../../assets/images/success.png'
import Select from '../Select';
import SingleCheckbox from "../../components/CheckBoxQuestion/SingleCheckBox";
import {useState} from "react";
import {GetPackage, GetTimeSlots} from "../../services";
import timeCheck from '../../helpers/timecheck';

const Badge = ({startTime, endTime}) => (<div
    className='w-32 border border-borderGreen p-2 mb-3.5 mr-3.5 h-26 flex justify-center items-center cursor-pointer'
    style={{borderRadius: '43px'}}>
    <p className="text-greenGradient font-normal text-14">
        {startTime} - {endTime}
    </p>

</div>)


const TutorSessionModal = ({
                               isOpen,
                               closeModal,
                               title,
                               buttonOneText,
                               buttonTwoText,
                               onClickFirstButton,
                               onClickSecondButton,
                               createSession
                           }) => {

    const [capacity, setCapacity] = useState(0);
    const [title2, setTitle] = useState("");
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [timeArray, setTimeArray] = useState([])
    const [res, setRes] = useState([])
    const [packageList, setPackageList] = useState([])
    const [price, setPrice] = useState([{package: "One to One package $40"}, {package: "One to One package $33"}, {package: "One to One package $20"}]);
    const [sessionStatus, setSessionStatus] = useState([{status: "Open", correct: false}, {
        status: "Complete", correct: false
    }, {status: "Cancel", correct: false},])
    const [packagesData, setPackagesData] = useState([])
    const [serviceType, setServiceType] = useState('Group');
    const [packages, setPackage] = useState('')

    useEffect(() => {
        GetTimeSlots().then((response) => {
            let obj = []
            setRes(response?.data)
            if (response?.data?.sunday?.length !== 0) {
                response?.data?.sunday?.map((item) => {
                    obj.push(item?.slot?.slot)

                })
            }
            setTimeArray(obj)

        })
        GetPackage("one-2-one").then((res) => {
            let pack_obj = []
            setPackagesData(res.data)
            if (res?.data?.length !== 0) {
                res.data.map((item) => {
                    pack_obj.push(item?.description)
                })
            }
            setPackageList(pack_obj)
        })

    }, [])


    const sessionCheck = (value, checked) => {

        let data = [...sessionStatus]
        data.map((item) => {
            if (item.status === value) {
                item.correct = true
            } else {
                item.correct = false
            }
        })
        setSessionStatus(data);
    }

    const createSessions = (title2, date, time, price, sessionStatus, capacity) => {
        createSession(title2, date, time, price, sessionStatus, capacity)

    }

    const timeSet = (e) => {
        setTime(timeCheck(e))
    }

    return (
        <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center   classNames={{
            modal: 'react-modal-class2', 
           }}>
        {/* <div className='flex justify-center mb-10'>
                <img src={Success} alt='success'/>
            </div>
     */}
        {/* <p className="text-darkest text-large md:text-h5 font-bold mb-5 text-center">{title}</p> */}
        {/* <p className='text-center text-15 text-darkest'></p> */}
        {/* <div className='mt-14 w-full'>
                <Select placeholder='Select Calendar'/>
            </div> */}
        {/* <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-12">
                <div className="w-56">
                    <Button simple onClick={onClickFirstButton}>
                        <p className='text-center text-14 xl:text-body-large'>{buttonOneText}</p>
                    </Button>
                </div>
                <div className="w-56 mt-5 md:mt-0">
                    <Button filled onClick={onClickSecondButton}>
                        <p className='text-center text-14 xl:text-body-large'>{buttonTwoText}</p>
                    </Button>
                </div>
            </div> */}
        <form className="md:flex  flex-col gap-x-6 gap-y-9">
            <div className="flex gap-x-1.5">
                <div className="w-3/6">
                    <label className="mb-8 md:text-body-large">Title</label>
                    <Input type="text" value={title2} placeholder="Enter the topic"
                           setValue={setTitle}/>
                </div>
                <div className="w-3/6">
                    <label className="mb-8 md:text-body-large">Capacity</label>
                    <Input type="number" value={capacity} setValue={setCapacity}
                           placeholder="Enter the max persons"/>
                </div>
            </div>

            <div className="flex gap-x-1.5">
                <div className="w-3/6">
                    <label className="mb-8 md:text-body-large">Date</label>
                    <Input placeholder="Enter the date" type="date" value={date}
                           setValue={setDate}/>
                </div>
                <div className="w-3/6">
                    <label className="mb-8 md:text-body-large">
                        Time
                    </label>
                    <div className="flex justify-center">
                        <select
                            className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0
     "
                            aria-label="Default select example"
                            onChange={(e) => timeSet(e.target.value)}
                        >
                            <option value="select">--- Select any Time Slot ---</option>
                            {timeArray?.map((item) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>
            </div>

            <div className="flex gap-x-1.5">
                {/*<div className="w-3/6">*/}
                {/*    <label className="mb-8 md:text-body-large">*/}
                {/*        Service Type*/}
                {/*    </label>*/}
                {/*    <div className="flex justify-center">*/}
                {/*        <select*/}
                {/*            className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0"*/}
                {/*            aria-label="Default select example"*/}
                {/*            onChange={(e) => setServiceType(e.target.value)}*/}
                {/*        >*/}
                {/*            <option selected> -- select --</option>*/}
                {/*            <option value="OneToOne">One to One</option>*/}
                {/*            <option value="Group">Group</option>*/}
                {/*        </select>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*{serviceType !== 'Group' ? <div className="w-3/6">*/}
                {/*    <label className="mb-8 md:text-body-large">*/}
                {/*        Packages*/}
                {/*    </label>*/}
                {/*    <div className="flex justify-center">*/}
                {/*        <select*/}
                {/*            className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0"*/}
                {/*            aria-label="Default select example"*/}
                {/*            onChange={(e) => setPackage(e.target.value)}*/}
                {/*        >*/}
                {/*            {packageList.map((item) => {*/}
                {/*                return (*/}
                {/*                    <option value={item}>{item}</option>*/}
                {/*                )*/}
                {/*            })*/}
                {/*            }*/}


                {/*        </select>*/}
                {/*    </div>*/}
                {/*</div> : null}*/}

                {/*<div className="w-3/6">*/}
                {/*    <label className="mb-8 md:text-body-large">Price</label>*/}
                {/*    <Input type="number" value={price} setValue={setPrice}*/}
                {/*           placeholder="Enter the price"/>*/}
                {/*</div>*/}

            </div>
        </form>
        {/* <div class="radioRow flex justify-around my-12 gap-4">
            {/* {sessionStatus.map((item) => (<SingleCheckbox
                id={item.status}
                text={item.status}
                correct={item.correct}
                handleChange={sessionCheck}
            />))} */}

            {/*<SingleCheckbox*/}
            {/*    id={'2'}*/}
            {/*    text={'Cancel'}*/}
            {/*    correct={sessionStatus.cancel}*/}
            {/*    handleChange={sessionCheck}*/}
            {/*/>*/}
            {/*<SingleCheckbox*/}
            {/*    id={'3'}*/}
            {/*    text={'Complete'}*/}
            {/*    correct={sessionStatus.complete}*/}
            {/*    handleChange={sessionCheck}*/}
            {/*/>*/}
        {/* </div> */}
        {/* radio row end */}

        <div className='mt-2 flex flex-col md:flex-row items-center'>
            <div className='py-6 md:pl-16' onClick={closeModal}>
                                    <span
                                        className='font-bold text-body-large text-primary cursor-pointer'>Cancel</span>
            </div>
            <div
                className='w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end'>
                {/* <div>
                                    <Button onClick={book}>
                                        <p className='text-bold text-darkest text-15 px-2 mac:text-body-large mac:px-6'>Book
                                            one to one tutorials</p>
                                    </Button>
                                </div> */}
                <div className='md:w-44'>
                    <Button filled
                            onClick={() => {
                                closeModal()
                                createSessions(title2, capacity, date, time, packages, serviceType, sessionStatus)
                    }}>
                        <p className='text-15 mac:text-body-large'>Confirm</p>
                    </Button>
                </div>
            </div>
        </div>

    </Modal>
    )
}

export default TutorSessionModal
