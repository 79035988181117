import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {loginWithFacebook} from '../../redux/actions';
import FacebookLogin from 'react-facebook-login';

const FB_CLIENT_ID = '631781848768889';

const FacebookAuthentication = (props) => {
    const {loginWithFacebook} = props;
    const history = useHistory();
    const responseFacebook = (response) => {
        loginWithFacebook(response.accessToken, history);
    };
    return (
        <div className="pt-4 flex justify-center">
            <div className="w-full h-51">
                <FacebookLogin
                    appId={FB_CLIENT_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,user_friends"
                    callback={responseFacebook}
                    icon="fa-facebook"
                    textButton="Continue with Facebook"
                    cssClass="socialBtn"
                />
            
            </div>
        </div>
    );
};
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
    loginWithFacebook: (body, history) => dispatch(loginWithFacebook(body, history)),
    
});
export default connect(mapStateToProps, mapDispatchToProps)(FacebookAuthentication);
