import * as t from '../types';
import {GetQuestionsQuiz, GetQuizzes} from '../../services';

export const getQuizQuestion = (slug) => async (dispatch) => {

    dispatch({
        type: t.GET_QUIZ_QUESTION_REQUEST,
    });

    return await GetQuestionsQuiz(slug)

        .then((data) => {
            dispatch({
                type: t.GET_QUIZ_QUESTION_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_QUIZ_QUESTION_FAILED,
                payload: err.response,
            });
        });
};
