import * as t from '../types';
import {GetTutorialVideo} from '../../services'


export const getTutorialVideo = (page,slug) => async (dispatch) => {
    dispatch({
        type: t.GET_TUTORIAL_VIDEO_REQUEST
    });
    return await GetTutorialVideo(page,slug)
        .then((data) => {
            dispatch({
                type: t.GET_TUTORIAL_VIDEO_SUCCESS,
                payload: data.data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTORIAL_VIDEO_FAILED,
                payload: err.response
            });
        });
};
