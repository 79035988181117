/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useHistory} from 'react-router-dom'
import ArrowRight from '../../assets/icons/arrow-right.svg'

const Breadcrumb = ({items, current}) => {
    const history = useHistory()

    function breadLink(link) {

        history.push({
            pathname: link, state: null
        });

    }

    return (
        <div className='flex flex-row w-full justify-start pt-4 flex-wrap'>

            <div className='flex mb-2 breadCrumSubMain'>
                {items.map((elem, index) => (<>
                        <button className="font-bold text-14 text-darkGrey" onClick={() => {
                            breadLink(elem.link)
                        }}>{elem.title}</button>
                        <img src={ArrowRight} alt='arrow' className='mx-2 md:mx-5'/>
                    </>
                ))}
                <p className="font-bold text-14 text-darkGrey">{current}</p>

            </div>

        </div>
    )
}

export default Breadcrumb
