import React from 'react'

import '../../index.css'

const ErrorMsg = ({ error, color, background }) => {
    return (
        <p className='errorMsg' style={{backgroundColor: background, color:color}} >{error}</p>
    )
}

export default ErrorMsg
