import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";


const Step2Content = ({ handleNext }) => {
    const history = useHistory()
  return (
    <div>
      <ul className="stepper-ul">
        <li>
          On average, SimpleStudying will save you <strong className="color-CC6828">738 hours</strong> of reading time per
          year compared to the textbooks. That's 2213 LESS pages to read per
          year!
        </li>
        <li>
          You could use the time we save you to get work experience and be more
          employable!
        </li>
        <li>
          SimpleStudying premium is just <strong className="color-CC6828">£90/year</strong> instead of buying at least <strong className="color-CC6828">4 textbooks/year</strong>           you’d need without SimpleStudying premium. <strong className="color-CC6828">4 textbooks </strong>
          should cost you on average <strong className="color-CC6828">£160</strong>.
        </li>
        <li>
          SimpleStudying saves you at least £70/year and 738+ hours not to
          mention maximising your chances of getting good grades.
        </li>
        <li>
          We don’t want to scare you but drop out rates in the UK universities
          can be over <strong className="color-CC6828">60%</strong> for most of the degrees. Drop out rates are even
          higher than <strong className="color-CC6828">60%</strong> average for law degrees. Last thing students want is
          to lose their tuition fees by dropping out. SimpleStudying was created
          to help students successfully obtain degrees with best grades.
        </li>
        <li>
          You need very good grades to get a graduate job in this competitive
          job market. The best paying graduate jobs are usually given to
          students who get First Class degrees or at least a high <strong className="color-CC6828">2.1</strong>
        </li>
      </ul>
      <div className="flex flex-col sm-md:flex-row justify-between px-26 pb-6  sm-md:space-x-10 md:space-x-24">
        <Button onClick={() => {history.push('/')}} className='mb-5 mt-5 sm-md:mt-0'>
          Keep Subscription
        </Button>
        <Button
          onClick={handleNext}
        >
          Request Cancellation
        </Button>
      </div>
    </div>
  );
};
export default Step2Content;
