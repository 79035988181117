import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/simpleStudyingLogo.png";
import UnderLine from "../../assets/icons/footer-underline.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import WhatsApp from "../../assets/icons/whatsapp.svg";
import Youtube from "../../assets/icons/youtube.svg";

import { getApiKey } from "../../helpers";
import Logos from "../../assets/images/study_small.png";
import Button from "../../components/EmptyButton";

const listItemClassNames =
  "text-14 font-normal list-none mt-4 cursor-pointer xl:text-body-large hover:text-primary";
const footerHeadlineClassNames = "text-14 font-bold xl:text-body-large";

const Logoss = ({ showSearch, handleClick, source }) => {
  const history = useHistory();
  return !showSearch ? (
    <div onClick={() => history.push("/")}>
      <img
        id={"footer_logo"}
        src={source}
        alt="Logo"
        className=""
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      />
    </div>
  ) : null;
};

const Footer = () => {
  const history = useHistory();
  const logoClick = () => {
    if (getApiKey()) {
      history.push(`/profile`);
    } else {
      history.push(`/`);
    }
  };

  useEffect(() => {
    // Load the external script
    const script = document.createElement("script");
    script.src = "https://widget.reviews.co.uk/badge-modern/dist.js";
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Initialize the reviews badge after the script has loaded
    script.onload = () => {
      // Call the reviewsBadgeModern function
      window.reviewsBadgeModern("badge-120", {
        store: "www.simplestudying.com",
        primaryClr: "#000000",
        starsClr: "#000000",
      });
    };

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {window.location.pathname !== "/login" ||
      window.location.pathname !== "/signup" ? (
        <div
          className="
                bg-offWhite
                w-full
                px-4
                md:px-6
                lg:px-8
                custom:px-56
                custom2:px-56
                xl:px-60
                xl:pt-20 pt-2
                "
        >
          <div className="logoSec w-44 lg:mb-10 mb-4 pt-2 sm:pt-4 lg:pt-8 xl:pt-12 ">
            <Logoss handleClick={logoClick} source={Logos} />
          </div>
          <div
            className="grid grid-cols-2 xl:pt-12 md:pt-12 pt-6 md:flex gap-2 md:justify-between lg:justify-between lg:flex"
            style={{ borderTop: "1px solid rgba(0,0,0,0.1)" }}
          >
            <div className="w-40 custom:w-60 mb-10 hidden lg:hidden">
              <Logoss handleClick={logoClick} source={Logos} />
              {/*<img src={Logo} alt="Logo" className="xl:w-64"/>*/}
            </div>
            <div>
              <p className={footerHeadlineClassNames}>
                Quick Links: <br /> <img src={UnderLine} alt="Under" />
              </p>
              <div className="">
                {!getApiKey() ? (
                  <li className={listItemClassNames}>
                    <p
                      onClick={() => {
                        history.push("/content-creator");
                      }}
                    >
                      Become a Content Creator
                    </p>
                  </li>
                ) : (
                  <li className={listItemClassNames}>
                    <p
                      onClick={() => {
                        history.push("/content-creator");
                      }}
                    >
                      Become a Content Creator
                    </p>
                  </li>
                )}
                {/* <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/study-tips");
                    }}
                  >
                    Study Tips
                  </p>
                </li> */}
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/all-subscriptions");
                    }}
                  >
                    Your Membership
                  </p>
                </li>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/blogs");
                    }}
                  >
                    Blog
                  </p>
                </li>
              </div>
            </div>
            <div className="mr-24">
              <p className={footerHeadlineClassNames}>
                Our Team: <br /> <img src={UnderLine} alt="Under" />
              </p>
              <div className="">
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/about-us");
                    }}
                  >
                    About Us
                  </p>
                </li>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/contact-us");
                    }}
                  >
                    Contact Us
                  </p>
                </li>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/our-partners");
                    }}
                  >
                    Our Partners
                  </p>
                </li>
              </div>
            </div>
            <div className="col-span-2 mt-10 md:mt-0">
              <p className={footerHeadlineClassNames}>
                Legal: <br /> <img src={UnderLine} alt="Under" />
              </p>
              <div>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/terms-conditions");
                    }}
                  >
                    User Terms & Conditions
                  </p>
                </li>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/terms-conditions-content");
                    }}
                  >
                    Content Creator Terms & Conditions
                  </p>
                </li>

                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/privacy-policy");
                    }}
                  >
                    Privacy Policy
                  </p>
                </li>
                <li className={listItemClassNames}>
                  <p
                    onClick={() => {
                      history.push("/cookies-policy");
                    }}
                  >
                    Cookies Policy
                  </p>
                </li>
              </div>
            </div>
            <div className="col-span-2 mt-12 md:mt-0 custom:w-1/2">
              <p className={footerHeadlineClassNames}>
                Stay in Touch with Us: <br />{" "}
                <img src={UnderLine} alt="Under" className="under-image" />
              </p>
              <div className="lg:block custom:hidden custom:rounded-2xl custom:mt-1 custom:border-opacity-20"></div>
              <div
                className=" bg-white  custom:block rounded-2xl xl:w-96 custom:mt-4 bg-transparent"
                style={{ background: "transparent" }}
              >
                {/* <SubscribeInput text="Subscribe" placeholder="Email" value={emailVal}
                                                setValue={setEmailVal}
                                                handleClick={() => {
                                                    subscribeEmail()
                                                }}/> */}

                {!getApiKey() && (
                  <div className="flex">
                    <div className="w-36">
                      <Button
                        text="Sign Up"
                        onClick={() => history.push("/signup")}
                      ></Button>
                    </div>
                    <p className="ml-2 text-small-text mt-4 socials-text">
                      {" "}
                      at Simple Studying to start improving your grades now.
                    </p>
                    <br />
                  </div>
                )}
                <div class="socials-text">
                  {/* style={{ width: "510px" }} */}
                  <p className="text-small-text mt-2">
                    Follow us on social media to receive regular tips for
                    achieving top grades and securing internships and jobs! Stay
                    tuned for exciting updates from{" "}
                    <a
                      className="cursor-pointer"
                      href="https://simplestudying.com"
                    >
                      Studycrowd.ai
                    </a>
                    , including upcoming feature launches designed to enhance
                    your learning experience, as well as new additions and
                    updates to our study materials.
                  </p>
                  <br />
                </div>
                <a
                  href="https://www.linkedin.com/company/studycrowd-ai/?viewAsMember=true"
                  className={
                    "text-small-text mt-2 cursor-pointer"
                  }
                >
                  Follow our company on Linkedin
                  <img src={UnderLine} alt="Under" className="under-image" />
                </a>
                <br />
                <a
                  href="https://www.linkedin.com/in/nini-sarishvili-8837a6114/"
                  className={
                    "text-small-text mt-2 cursor-pointer" 
                  }
                >
                  Follow our CEO on Linkedin
                  <img src={UnderLine} alt="Under" className="under-image" />
                </a>
                <p className="mt-4 text-small-text">
                  We have dedicated social media pages for law students. If
                  you’re studying law, follow us on the pages below for valuable
                  free content designed to help law students achieve top grades.
                </p>
              </div>

              <div className="flex flex-start mt-4">
                <a
                  href={"https://www.facebook.com/groups/633210267590391"}
                  target="_blank"
                >
                  <img
                    src={Facebook}
                    alt="facebook"
                    className="cursor-pointer"
                  />
                </a>
                <a href={"https://twitter.com/StudyingSimple"} target="_blank">
                  <img
                    src={Twitter}
                    alt="tw"
                    className="cursor-pointer  md:ml-3 ml-7"
                  />
                </a>
                <a
                  href={"https://www.instagram.com/simplestudyinglaw/"}
                  target="_blank"
                >
                  {" "}
                  <img
                    src={Instagram}
                    alt="in"
                    className="cursor-pointer md:ml-3 ml-7"
                  />
                </a>
                <a
                  href={"https://www.linkedin.com/company/simplestudying/"}
                  target="_blank"
                >
                  <img
                    src={Linkedin}
                    alt="li"
                    className="cursor-pointer md:ml-3 ml-7"
                  />
                </a>
                <a
                  href={"https://linktr.ee/SimpleStudying_studygroups"}
                  target="_blank"
                >
                  {" "}
                  <img
                    src={WhatsApp}
                    alt="wp"
                    className="cursor-pointer md:ml-3 ml-7"
                  />
                </a>
                <a
                  href={
                    "https://www.youtube.com/channel/UC-a0z1-O8ySfW1fM39aC-QQ"
                  }
                  target="_blank"
                >
                  <img
                    src={Youtube}
                    alt="wp"
                    className="cursor-pointer md:ml-3 ml-7"
                  />
                </a>
              </div>
            </div>
            <div id="badge-120" style={{ maxWidth: "120px" }}></div>
          </div>

          {/* <div className="block mt-7 mb-7 1200:hidden text-small-text text-darkest  md:flex w-full md:mt-70 justify-start">
          <p
              className="btn flex-auto border-2 border-primary md:border-0 ml-1 text-black py-2 px-2 font-bold xl:w-full"
              style={{ cursor: "pointer" }}
              onClick={contactUs}
            >
              Contact Us
            </p>
            <p
              className="btn flex-auto border-2 border-primary md:border-0 ml-1 text-primary py-2 px-2 font-bold xl:w-full"
              style={{ cursor: "pointer" }}
              onClick={tutoring}
            >
              Tutoring
            </p>
          </div> */}
          {/* tutoring contact us */}
          <div className="hidden mt-7 mb-7 md:block text-small-text text-darkest opacity-50 md:flex w-full md:mt-70">
            <p className="lg:ml-46 custom:ml-72 xl:ml-72 xl:ml-80">
              Copyright © 2024 - Studycrowd.ai is a trading name of <br />{" "}
              SimpleStudyingAI Inc., a company registered in Delaware United
              States.
            </p>
            <p className="lg:ml-12 custom:ml-10 xl:ml-10">
              SimpleStudyingAI.Inc. is registered at <br /> 1111B S Governors
              Ave, #21850, Dover DE 19904.
            </p>
          </div>
          <div className="mt-7 mb-7 text-small-text text-darkest opacity-50 md:hidden">
            <p>
              Copyright © 2019 - {new Date().getFullYear()} SimpleStudying is a
              trading name of SimpleStudying Ltd, a company registered in
              England and Wales. Company registration No: 12373336. Registered
              office: Unit 6 Queens Yard, White Post Lane, London, England, E9
              5EN.
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
