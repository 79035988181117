
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Container from '../../components/container';
import PageHeadline from '../../components/PageHeadline';
import ResutlCard from '../../components/ResultCard';
import {getUserInfo} from "../../redux/actions";
import $ from  'jquery'

let results = []

const QuizResults = (props) => {

    const {getUserInfo} = props

    let userSelected = props?.location?.state?.userAnswers
    let total = props?.location?.state?.total
    let current = props?.location?.state?.current



    useEffect(() => {
        getUserInfo()
    }, []);

    return (
        <div className='bg-white bg-subscription-bottom bg-no-repeat bg-bottom pb-24' id="quizResult">
            <Container>
                <PageHeadline title={current}/>
                <p className='quizResSecH3 font-bold text-h3'>Score: {userSelected?.score ? userSelected?.score : '0'} of {total}</p>
                        <div className='pt-2 md:pt-4 lg:pt-8'>
                        <ResutlCard
                                correctAnswer={userSelected}
                            />

                        </div>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    getUserInfo: () => dispatch(getUserInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizResults)