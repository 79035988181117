import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../assets/images/defaultDp.webp";
import Button from "../EmptyButton";
import { Modal } from "react-responsive-modal";
import * as Yup from "yup";
import { updateUser } from "../../redux/actions";
import { updatedUserLoader } from "../../redux/selectors";
import { Field, Form, Formik } from "formik";
import Input from "../Input/InputComponent";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditUserInfoModal = ({
  open,
  setOpen,
  details,
  updateUser,
  updatedUserLoader,
}) => {
  const [bio, setBio] = useState(details?.bio);
  const [firstName, setFirstName] = useState(details?.first_name);
  const [lastName, setLastName] = useState(details?.last_name);
  const [image, setImage] = useState();
  const [phNumber, setPhNumber] = useState(details?.contact_number);
  const [newImage, setNewImage] = useState(details?.image);
  const validateSchema = Yup.object({
    bio: Yup.string(),
    first_name: Yup.string(),
    last_name: Yup.string(),
    email: Yup.string(),
    username: Yup.string(),
  });

  const profileImage = (e) => {
    setImage(e.target.files[0]);
    setNewImage(URL.createObjectURL(e.target.files[0]));
  };
  const updateUserData = (values) => {
    const form = new FormData();
    form.append("email", values.email);
    form.append("username", values.username);
    form.append("first_name", values.first_name);
    form.append("last_name", values.last_name);
    form.append("bio", values.bio);
    if (image) {
      form.append("image", image);
    }
    if (phNumber) {
      form.append("contact_number", phNumber);
    }
    updateUser(form).then(() => setOpen(false));
  };
  const handlePhoneNumberChange = (value) => {
    setPhNumber(value);
  };
  useEffect(() => {
    setPhNumber(details?.contact_number);
  }, [details]);


  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      focusTrapped={false}
      center
      // classNames={{ modal: "xl:w-650" }}
      classNames={{
        overlay: 'custom-overlay-class',
       modal: 'react-modal-class2', 
      }}
    >
      <Formik
        initialValues={{
          bio: details?.bio,
          first_name: details?.first_name,
          last_name: details?.last_name,
          email: details?.email,
          username: details?.username,
        }}
        validationSchema={validateSchema}
        onSubmit={(values) => updateUserData(values)}
      >
        {({ isValid, handleSubmit, values }) => (
          <Form>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="flex flex-col text-darkest items-center">
                <div className="flex justify-center mb-2 md:mb-6 lg:mb-10">
                  <div className="uploadImgWrap">
                    <img
                      src={newImage ? newImage : ProfileImage}
                      alt="Profile"
                    />
                    <input
                      type="file"
                      title={
                        newImage
                          ? "Change Profile Picture"
                          : "Upload Profile Picture"
                      }
                      onChange={(e) => profileImage(e)}
                    ></input>
                  </div>
                </div>
                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">Email</p>
                  <Field
                    name="email"
                    as={Input}
                    placeholder="Enter your email"
                    type="email"
                    readOnly={true}
                  />
                </div>

                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">Username</p>
                  <Field
                    name="username"
                    as={Input}
                    placeholder="Enter your username"
                    type="text"
                    readOnly={true}
                  />
                </div>
                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">First Name</p>
                  <Field
                    name="first_name"
                    as={Input}
                    placeholder="Enter your first name"
                    type="text"
                  />
                </div>
                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">Last Name</p>
                  <Field
                    name="last_name"
                    as={Input}
                    placeholder="Enter your last name"
                    type="text"
                  />
                </div>
                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">Bio</p>
                  <Field
                    name="bio"
                    as={Input}
                    placeholder="Enter your bio"
                    type="text"
                  />
                </div>
                <div className="w-full mb-3 md:mb-9 ">
                  <p className="mb-8">Contact Number</p>
                  <div className="text-input-container">
                    <PhoneInput
                      country={"us"}
                      value={phNumber}
                      onChange={handlePhoneNumberChange}
                      className="text-input focus:bg-offWhite focus:border-none"
                      inputStyle={{
                        border: "none",
                        height: "51px",
                        fontSize: "15px",
                      }}
                      buttonStyle={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-4 flex justify-center mb-0 md:mb-6 lg:mb-8">
                <div className="w-full h-51 efUpdateBtnSec">
                  <Button
                    onClick={handleSubmit}
                    text="Update"
                    filled
                    className="buttonClass"
                    loading={updatedUserLoader}
                  />
                </div>
              </div>
            </form>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  updatedUserLoader: updatedUserLoader(state),
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (body) => dispatch(updateUser(body)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditUserInfoModal);
