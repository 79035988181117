import React, {useState} from 'react'
import Container from '../../components/container';
import Input from "../../components/Input/InputForm";
import Button from "../../components/EmptyButton";
import {contentEmailAction} from "../../redux/actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import { contentEmailData } from '../../redux/selectors';

const ContentWelcome = (props) => {

    const {contentEmailAction} = props

    const [email, setEmail] = useState()

    return (
        <div className="bg-white">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Become a Content Creator</title>
                <meta name="description" content="Content Creator Description"/>
                <link rel="canonical" href="https://simplestudying.com/contentwelcome"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Content Creator"/>
                <meta property="og:description" content="Content Creator Description"/>
                <meta property="og:url" content="https://simplestudying.com/contentwelcome"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="bg-offWhite">
                <Container>
                    <p className="text-h5 custom2:text-h3 text-darkest font-bold mt-16 mb-9">Welcome to Content
                        Creator</p>
                    <div className="flex flex-col text-darkest">
                        <div className="flex flex-col md:flex-row md:space-x-6 mb-9 ">
                            <div className="w-full mb-6 md:mb-0 md:w-2/8">
                                <label className="mb-16" style={{display:'inline-block'}}>
                                    Your Email
                                </label>
                                <Input placeholder="Email for Content Creator" setValue={setEmail} value={email}/>
                            </div>
                        </div>
                    </div>
                    <Button
                        filled
                        className='queryBtn'
                        onClick={() => contentEmailAction({email: email})}
                    >
                        <p className="text-center text-small-text xl:text-body-large">
                            Send Query
                        </p>
                    </Button>
                </Container>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    contentEmailData: contentEmailData(state),
  });
  const mapDispatchToProps = (dispatch) => ({
    contentEmailAction: (email) => dispatch(contentEmailAction(email)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ContentWelcome);