import * as t from '../types';
import {CancelAppointment} from '../../services'


export const cancelAppointmentAction = (obj) => async (dispatch) => {
    dispatch({
        type: t.POST_CANCEL_SUBSCRIPTION_REQUEST
    });

    return await CancelAppointment(obj)
        .then((data) => {
            dispatch({
                type: t.POST_CANCEL_SUBSCRIPTION_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.POST_CANCEL_SUBSCRIPTION_FAILED,
                payload: err.response
            });
            return err.response
        });
};
