import apiRequest from "../../helpers/apiRequest";

export default async (body) => {
    if(body !== undefined){
        return await apiRequest({
            method: 'get',
            url: `accounts/tutor_schedule/${body}`,
        })
    } else {
        return await apiRequest({
            method: 'get',
            url: `accounts/tutor_schedule/`,
        })
    }
}
