export const GET_STUDY_MODULES_REQUEST = 'studyModulesReducer/GET_STUDY_MODULES_REQUEST';
export const GET_STUDY_MODULES_SUCCESS = 'studyModulesReducer/GET_STUDY_MODULES_SUCCESS';
export const GET_STUDY_MODULES_FAILED = 'studyModulesReducer/GET_STUDY_MODULES_FAILED';

export const GET_STUDY_MODULES_DETAIL_REQUEST = 'GET_STUDY_MODULES_DETAIL_REQUEST';
export const GET_STUDY_MODULES_DETAIL_SUCCESS = 'GET_STUDY_MODULES_DETAIL_SUCCESS';
export const GET_STUDY_MODULES_DETAIL_FAILED = 'GET_STUDY_MODULES_DETAIL_FAILED';




