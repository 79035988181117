import React, { useState } from 'react'
import Container from '../container'
import LazyImage from '../LazyImage'
import Logo from '../../assets/images/study_small.png'
import InfoBox from '../InfoBox/SmallInfobox'
import { useHistory } from 'react-router-dom'
const Header = ({ }) => {
    const [showInfo, setShowInfo] = useState(false)
    const history = useHistory()
    return (
        <Container>
            <div className='py-5 flex items-center justify-between'>
                <div className='md:mr-10 xl:mr-14 hidden md:block'>
                    <LazyImage src={Logo} alt='Logo' className="w-36 h-36" onClick={() => history.push('/')}/>
                </div>

                <div onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)} className='cursor-pointer border border-darkest py-6 px-10 rounded-2xl w-40 h-12 lg:w-56 lg:h-16 flex items-center justify-center relative'>
                    <p className=' text-body-large  text-darkest ' onClick={() => history.push('/')}>Student</p>
                    {showInfo && (
                        <div className='absolute z-10 top-14 md:top-20'>
                            <InfoBox
                                text='Switch back to the student view here - get back
                                to studying.'/>
                        </div>)}
                </div>
            </div>
        </Container>
    )
}

export default Header
