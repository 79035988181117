export const getHolidaysLoading =  ({store})  => store.getHolidaysReducer.loading;

export const getHolidaysError =  ({store})  => store.getHolidaysReducer.error;

export const getHolidaysData =  ({store})  => store.getHolidaysReducer.data;


export const postHolidaysLoading =  ({store})  => store.postHolidaysReducer.loading;

export const postHolidaysError =  ({store})  => store.postHolidaysReducer.error;

export const postHolidaysData =  ({store})  => store.postHolidaysReducer.data;


export const deleteHolidaysLoading =  ({store})  => store.deleteHolidaysReducer.loading;

export const deleteHolidaysError =  ({store})  => store.deleteHolidaysReducer.error;

export const deleteHolidaysData =  ({store})  => store.deleteHolidaysReducer.data;