import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,useHistory } from "react-router-dom";
import "./index.css";
import App from "./App";
import "react-responsive-modal/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "typeface-ubuntu";
import "typeface-indie-flower";
import { Provider } from "react-redux";
import history from "./history.js";
import store from "./redux/store/settings";
import ContextProvider from "./services/ContextProvider";

const maintainence = false;

ReactDOM.render(
  
  <Provider store={store}>
    <ContextProvider>
    <BrowserRouter history={history}>
    
    {maintainence === true ? (
      <div className="body-maintenence">
        <article className="article-maintenence">
          <h1 className="h1-maintainence" style={{fontSize: '40px', fontWeight: 'bolder'}}>We&rsquo;ll be back soon!</h1>
          <div>
            <p className="mt-10" style={{fontSize: '30px'}}>
              Sorry for the inconvenience but we&rsquo;re performing some
              maintenance at the moment. If you need to you can always{" "}
              <a className="a-maintenence" href="mailto:info@simplestudying.com">contact us</a>, otherwise
              we&rsquo;ll be back online shortly!
            </p>
            <p className="mt-10" style={{fontSize: '30px'}}>You can visit the old site on{" "}
                <a className="wordpress-link-maintenence" href="https://old.simplestudying.com/">Wordpress</a>
            </p>
            <p className="mt-4" style={{fontSize: '30px'}}>&mdash; The SimpleStudying Team</p>
          </div>
        </article>
      </div>
    ) : (
      <App />
    )}
</BrowserRouter>
    
    </ContextProvider>
  </Provider>
  ,
  document.getElementById("root")
);
