import React from 'react'


const InfoBox = ({ text }) => {
    return (
        <div className='wayPosInfoBox min-w-150 md:min-w-168 h-8 md:h-12 p-3 md:p-5 flex flex-row justify-center items-center bg-white text-small-text text-darkGrey rounded-xl' style={{ boxShadow: "0px 7px 25px rgba(0, 0, 0, 0.12)" }}>
            {text}
        </div>
    )
}

export default InfoBox
