import React, {useContext, useState} from 'react'
import Button from '../../components/EmptyButton'
import {useHistory} from "react-router-dom";
import UKFlag from "../../assets/images/UK_flag.jpg";
import USFlag from "../../assets/images/US_flag.jpg";
import Stepper from "../../components/SignUpStepper"
import {connect, useDispatch} from "react-redux";
import {createErrorAlert, updateUser} from "../../redux/actions";
import { getUser } from '../../helpers';
import Context from '../../services/Context';

const Card = ({title, image, margin, setValue}) => {

    return (
        <div
            className={`bg-white ${margin && "mt-10 custom2:mt-0 border-4 border-borderGreen"} flex flex-col p-5 md:px-24 md:py-10 items-center cursor-pointer rounded-3xl`}
            onClick={() => {
            if (title === 'UK') {
                setValue(0)
            } else {
                setValue(1)
            }
        }}>
            <img alt="img" src={image} className="w-48 h-full"/>
            <p className="mt-6 md:mt-9 text-darkest font-bold text-body-small md:text-h4 text-center">{title}</p>
        </div>
    )
}

const StepZero = (props) => {
    const {updateUser} = props;
    const history = useHistory()
    const {value, updateValue} = useContext(Context)
    const [degree, setDegree] = useState(-1)
    const user = JSON.parse(getUser())
    const dispatch = useDispatch()
    const updateStepOne = () => {
        history.push("/content-creator/step-one")
        let obj = {
            username: user?.username,
            user_currency: degree === 0 ? 'UK' : 'US'
          }
        updateValue((prev) => ({
            ...prev,
            contentRegion: degree,
            userCurrency: degree === 0 ? 'UK' : 'US'
        }))
        updateUser(obj);

    }
    return (
        <div className='bg-white bg-main-page-back w-full bg-no-repeat bg-cover'>
            <Stepper step={1} exit={() => history.push('/')} totalSteps={4}/>
            <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">Where have you studied?</p>
            <div className="pb-16 custom2:pb-72">

                <div className="flex flex-col lg:flex-row items-center justify-center gap-14">

                    <Card title="UK" image={UKFlag} setValue={setDegree} margin={degree === 0}/>
                    <Card title="US" image={USFlag} setValue={setDegree} margin={degree === 1}/>
                </div>
                <div className='pt-4 flex justify-center mb-10 mt-12'>
                    <div className='w-full px-4 md:px-0 md:w-40 h-51'>
                        <Button onClick={updateStepOne} text='Continue' filled/></div>
                </div>
            </div>
        </div>

    )
}
const mapDispatchToProps = dispatch => ({
    updateUser: (body) => dispatch(updateUser(body)),
})
export default connect(null, mapDispatchToProps)(StepZero)
