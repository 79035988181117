import apiRequest from "../../helpers/apiRequest";


export default async (page, search, slug) => {
    let url = 'content/note/criminal-law'
    if (slug) {
        return await apiRequest({
            method: 'get',
            url: `content/note/${slug}`,
            params: {page: page, title: search}
        })
    } else {
        return await apiRequest({
            method: 'get',
            url: url,
            params: {page: page, title: search}
        })
    }

}
