import React, {useEffect, useRef, useState} from "react";
import { zIndex } from "tailwindcss/defaultTheme";

import ConnectPointsWrapper from "./connector";

let matchAns = []
let stateArray = []

const MatchCard = ({
                       textQuestion,
                       textAnswer,
                       handler,
                       addArrow,
                       boxId,
                       items,
                       color,
                       selected,
                       handleCheckMCQS,
                       match_id
                   }) => {
    const ref0 = useRef();

    const [showFullText, setShowFullText] = useState(false);
    const [colors, setColor] = useState(color)
    const [isPointerEventsEnabled, setPointerEvents] = useState(false);

    let refs

    const handleSeeMore = (e) => {
        setShowFullText(!showFullText);
      };

      const handleMouseEnter = () => {
        setPointerEvents(true);
        handleSeeMore()
      };
    
      const handleMouseLeave = () => {
        setPointerEvents(false);
        handleSeeMore()
      };
    
    
      const handleTitleEllipsis = (title, length) => {
        if (title?.length > length && !showFullText) {
          return title?.substring(0, length) + "...";
        } else {
          return title;
        }
      };

    return (
        <div
            id={boxId}
            ref={ref0}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
                ref0.current.style.pointerEvents = 'none'
                stateArray.push(e.dataTransfer.getData('arrow'))
                
                if (e.dataTransfer.getData("arrow") === boxId) {
                    items.selected = true;
                } else {
                    refs = {start: e.dataTransfer.getData("arrow"), end: boxId};

                    for(let i=0; i<items.length; i++){
                        for(let j=0;j<items[i].sub_matches.length; j++){
                            if(refs.start === items[i].sub_matches[j].match_question){
                                matchAns.push({match_question: refs.start, match_answer: refs.end, match_id: items[i].sub_matches[j].id})
                            }
                        }
                    }


                    localStorage.setItem('matchAnswer', JSON.stringify(matchAns))
                    addArrow(refs);

                    for (let i = 0; i < items.length; i++) {
                        const currentItem = items[i];
                        for (let j = 0; j < currentItem.sub_matches.length; j++) {
                            const currentSubMatch = currentItem.sub_matches[j]
                          if (refs.start === currentSubMatch.match_question) {
                            currentItem.selected = true;
                          }
                          if (refs.start === currentSubMatch.match_question && refs.end === currentSubMatch.match_answer) {
                            setColor("#216857");
                            currentSubMatch.status = "#216857"
                          } else if (refs.start === currentSubMatch.match_question) {
                            setColor("#BE1E1E");
                            currentSubMatch.status = "#BE1E1E"
                          } else if (refs.end === currentSubMatch.match_answer) {
                            setColor("#BE1E1E");
                            currentSubMatch.status = "#BE1E1E"
                          }
                        }
                      }
                      
                    }
            }}
            className={`w-320 custom2:w-450 p-6 bg-white flex items-center rounded-3xl font-bold text-body-large mt-5 cursor-pointer`}
            style={{
                boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                position: "relative",
                backgroundColor: handler === "right" ? "#fff" : colors,
                color: colors !== "#ffffff" && handler === "left" ? "#ffffff" : "black",
                userSelect: 'none',
                zIndex: 1000,
            }}
        >
            <div>
            <p>{handler === "left" ? handleTitleEllipsis(textAnswer, 40) : handler === "right" ? textQuestion : ''} {!showFullText && handler === "left" && "...see more"}</p>
            </div>
            {handler === "right" && !stateArray.includes(textQuestion) ? (
                <ConnectPointsWrapper boxId={boxId} handler={handler} ref0={ref0} end={refs?.end ? refs?.end : ''}/>
            ) : null}
        </div>
    );
};

export default MatchCard;