import apiRequest from "../../helpers/apiRequest";


export default async (page, title,slug) => {
    let url=`content/casestudy/${slug}/`;
    if(title && page===null){

      url= url+ `?title=${title}`;
    }
    else if(page && title){
        url= url+ `?title=${title}&page=${page}`;
    }
    else if(page && title===null){
        url= url+ `?page=${page}`;
    }
    
    return await apiRequest({
        method: 'get',
        url: url,
       
    })
}
