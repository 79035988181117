import * as t from '../types';
import {GetNotes} from '../../services'


export const getNotes = (page, search,slug) => async (dispatch) => {

    dispatch({
        type: t.GET_NOTES_REQUEST
    });

    return await GetNotes(page, search,slug)

        .then((data) => {
            dispatch({
                type: t.GET_NOTES_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_NOTES_FAILED,
                payload: err.response
            });
        });
};
