import * as t from '../types';

const initialState = {
    data: '',
    loading: false,
    error: '',
};

export default function quizReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.GET_ALL_QUIZ_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case t.GET_ALL_QUIZ_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data,
            };
        case t.GET_ALL_QUIZ_FAILED:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}
