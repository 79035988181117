import React from "react";

function NavItemTooltip({ text }) {
  return (
    <div className="navitem-custom-tooltip">
      <span>{text}</span>
    </div>
  );
}

export default NavItemTooltip;
