import React, {useEffect, useState} from 'react';
import {Modal} from 'react-responsive-modal';
import './modal.css';
import Button from '../EmptyButton';
import Badge from '../../components/SessionScheduleContainer/badge';
import {
    tutorAvailabilityData, tutorAvailabilityError, tutorAvailabilityLoading,
} from '../../redux/selectors';
import {getTutorAvailability} from '../../redux/actions';
import {connect} from 'react-redux';
import FullLoader from '../Loader/FullLoader';

const closeIcon = (<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.729 8.07251L13.9993 12.9657L9.26968 8.07251C8.98291 7.77583 8.51579 7.77583 8.22902 8.07251C7.94502 8.36634 7.94502 8.84067 8.22902 9.1345L12.9658 14.0351L8.22902 18.9356C7.94502 19.2294 7.94502 19.7038 8.22902 19.9976C8.51579 20.2943 8.98291 20.2943 9.26968 19.9976L13.9993 15.1044L18.729 19.9976C19.0158 20.2943 19.4829 20.2943 19.7697 19.9976C20.0537 19.7038 20.0537 19.2294 19.7697 18.9356L15.0329 14.0351L19.7697 9.1345C20.0537 8.84067 20.0537 8.36634 19.7697 8.07251C19.4829 7.77583 19.0158 7.77583 18.729 8.07251Z"
        fill="#434343" stroke="#434343" stroke-width="0.3"/>
</svg>);

const CustomModal = ({
                         isOpen,
                         closeModal,
                         title,
                         buttonOneText,
                         buttonTwoText,
                         onClickFirstButton,
                         onClickSecondButton,
                         loading,
                         error,
                         dates,
                         data,
                         datesArray,
                         slug,
                     }) => {
    const [time, setTime] = useState({id: null, start: 0, end: 0});
    const [select, setSelect] = useState(data);
    const [slots, setSlot] = useState([])
    let arr = []
    useEffect(() => {
        if (data.length) {
            setSelect(data);
        }
    }, [data]);
    
    const selectedTimeSlot = (selected) => {
        let obj = [...data];
        if (obj.length) {
            obj.map((subItem) => {
                subItem.available_slot.map((item) => {
                    if (item.id === selected.id) {
                        item.is_active = false;
                    } else {
                        item.is_active = true;
                    }
                });
            });
        }
        setSelect(obj);
        arr = []
    };
    return (<>
        {loading ? <FullLoader/> :
            <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center  classNames={{
                overlay: 'custom-overlay-class',
               modal: 'react-modal-class2', 
              }}>
                
                
                <p className="text-darkest text-large md:text-h5 font-bold mb-5">{title}</p>
                <div className="flex flex-row  flex-wrap w-full">
                    
                    {select?.length ? select?.map((item) =>
                        item?.available_slot?.map((subItem) => {
                            if(!arr.includes(subItem.slot.slot)){
                                arr.push(subItem.slot.slot)
                                arr = arr.filter((item, index) => {
                                    return arr.indexOf(item) === index
                                })
                                return(
                                    <Badge setTime={setTime} startTime={subItem.slot.slot} onClick={selectedTimeSlot}
                                        item={subItem}
                                        id={subItem.id}
                                        dates={dates}
                                        dateArray={datesArray}
                                        selected={subItem.is_active}
                                        endTime={subItem?.end_time}/>
                                )
                            }
                        }
                            ),
                    ) : <h3 style={{textAlign: 'center'}}>No Sessions Available</h3>}
                </div>
                <div
                    className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
                    <div className="w-56">
                        <Button simple onClick={onClickFirstButton}>
                            <p className="text-center text-14 xl:text-body-large">{buttonOneText}</p>
                        </Button>
                    </div>
                    <div className="w-56 mt-5 md:mt-0">
                        <Button filled onClick={() => onClickSecondButton(time)}
                                disabled={select?.length === 0 || select[0].available_slot.length === 0}>
                            <p className="text-center text-14 xl:text-body-large">{buttonTwoText}</p>
                        </Button>
                    </div>
                </div>
            </Modal>
        }
    </>);
};

export default CustomModal;
