import React, { useEffect, useState } from "react";
import Container from "../../components/container";
import { Modal } from "react-responsive-modal";
import Pdf from "react-to-pdf";
import { apiRequest, getApiKey } from "../../helpers";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { FaTimesCircle } from "react-icons/fa";
import { FiAlertTriangle,  } from "react-icons/fi";
import { connect } from "react-redux";
import { allPlanAction, viewHistoryAction } from "../../redux/actions";
import { allPlanData, viewHistoryData, viewHistoryError } from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";


const AllSubscription = (props) => {
  const {allPlan, allPlanData, viewHistoryAction, viewHistoryData} = props
  let user = JSON.parse(localStorage.getItem("app_user"));
  const ref = React.createRef();

  const [isOpen, closeModal] = useState(false);
  const [data, setData] = useState();
  const [showCancelMessage, setShowCancelMessage] = useState(localStorage.getItem('cancelSubscription'))
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false)
  const history = useHistory();

  useEffect(() => {
    allPlan()

    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if(allPlanData.length > 0){
      setData(allPlanData)
    }
  },[allPlanData])

  useEffect(() => {
    if(viewHistoryData.url){
      setLoading(false)
      window.location.replace(viewHistoryData.url)
    }
  },[viewHistoryData])

  const modalView = (data) => {
    setModalData(data);
    closeModal(true);
  };

  const manageSubscriptions = async () => {
    if (getApiKey()) {
      history.push('/subscription-cancel')
    }
  };

  const subscriptionList = () => {
    history.push("/subscription");
  };

  const headingHandle = (subscription) => {
    if(subscription !== 'Basic Subscription') {
      if(subscription === 'QUICK BOOST OF YOUR GRADES'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING'
      } else if(subscription === 'GET COVERED FOR THE EXAM PERIOD'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING'
      } else if(subscription === 'IDEAL IF YOU AIM FOR A FIRST CLASS'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING'
      }
    } else {
      return 'Free Subscription'
    }
  }

  const handleCancelMessage = () => {
    localStorage.removeItem('cancelSubscription')
    setShowCancelMessage(false)
  }

  const viewHistory = () =>{
    setLoading(true)
    viewHistoryAction()
  }

  return (
    <div className="custom2:pb-24 bg-basket-bg bg-white">
      {loading && <FullLoader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Your Membership</title>
        <meta name="description" content="Your Membership Description" />
        <link
          rel="canonical"
          href="https://simplestudying.com/all-subscriptions"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Your Membership" />
        <meta property="og:description" content="Your Membership Description" />
        <meta
          property="og:url"
          content="https://simplestudying.com/all-subscriptions"
        />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <div className="">
        <Container>
          <Modal
            open={isOpen}
            onClose={() => closeModal(false)}
            focusTrapped={false}
            center
          >
            <div className="flex justify-center mb-10">
              {/* <img src={Success} alt="success" /> */}
            </div>
            <div ref={ref} className="invoiceMain">
              <div
                className="invoiceHead"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      background: "lightgrey",
                      border: "1px solid grey",
                      padding: "10px",
                    }}
                  >
                    Invoice {modalData?.invoice?.id}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      background: "lightgrey",
                      border: "1px solid grey",
                      padding: "2%",
                    }}
                  >
                    BILL TO:
                  </div>
                  <div
                    style={{
                      padding: "2%",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <p>Nini Sarishviii</p>
                    <p>Nini.Sarishviii@simplestudying.com</p>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    background: "lightgrey",
                    border: "1px solid grey",
                    padding: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  <p>DESCRIPTION</p>
                  <p>AMOUNT</p>
                </div>
                <div
                  style={{
                    padding: "2%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{modalData?.plan}</p>
                  <p>€ {modalData?.invoice?.total / 100}</p>
                </div>
              </div>
              <hr />
              <div>
                <p>Subtotal: € {modalData?.invoice?.total / 100}</p>
                <p>Discount: € 0</p>
                <p style={{ fontWeight: "bold" }}>
                  Total Price: $ {modalData?.invoice?.amount}
                </p>
                <p>Payment Status: {modalData?.invoice?.status}</p>
              </div>
              <div
                className="addInfoText"
                style={{
                  background: "lightgrey",
                  border: "1px solid grey",
                  padding: "3%",
                  width: "40%",
                }}
              >
                ADDITIONAL INFO:
              </div>
              <p>Payment Date: {modalData?.created_at}</p>
            </div>

            <div
              style={{
                textAlign: "center",
              }}
            >
              <Pdf targetRef={ref} filename="receipt.pdf">
                {({ toPdf }) => (
                  <button
                    onClick={toPdf}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Print
                  </button>
                )}
              </Pdf>
            </div>
          </Modal>
          <div className="pt-10 lg:pt-20">
          {showCancelMessage && <div class="borderMessage mb-4">
          <div class="flex items-center">
            <div class="mr-4 text-primary text-42">
              <FiAlertTriangle />
            </div>
            <div class="text-left px-4">Your Premium Subscription will expire soon and you will no longer have access to the SimpleStudying materials to maximise your chances of getting a First Class law degree. You can get your subscription back if you change your mind.</div>
            <div class="ml-auto text-primary cursor-pointer">
              <FaTimesCircle onClick={() => handleCancelMessage()}/>
            </div>
          </div>
        </div>}
            <p className="text-h4 md:text-h3 md:text-30 lg:text-36 font-bold themeClr mb-4  lg:mb-20">
              Your Membership
            </p>
          </div>
          {getApiKey() ? (
            <div>
              <div className="flex flex-col w-full mb-12 lg:mb-20">
                <div className="overflow-x-auto min-w-full ">
                  <div className="py-4 inline-block min-w-full w-full">
                    <div className="
                    overflow-y-hidden 
                    sm:overflow-x-scroll 
                    "
                    // lg:overflow-x-hidden

                    >
                      <table className=" memberShipTable min-w-full text-center w-full">
                        <thead className="border-b border-t borderClr bg-white text-white bg-primary">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-6 py-2 lg:py-4"
                            >
                              Membership
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-6 py-2 lg:py-4"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-6 py-2 lg:py-4 "
                            >
                              Expiration/Renewal date
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-6 py-2 lg:py-4"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bg-white border-b borderClr">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {headingHandle(user?.subscription_name)}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {showCancelMessage ? 'Cancelled' : 'Active'}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {user?.subscription_end_date !== null
                                ? user?.subscription_end_date
                                : "N/A"}
                            </td>
                            <td className="text-sm  font-light px-6 py-4 whitespace-nowrap ">
                            {user?.subscription_name !== 'Basic Subscription' && !showCancelMessage &&
                              <a
                                className="block linkClr"
                                onClick={manageSubscriptions}
                              >
                                Cancel your Subscription
                              </a>
                            }
                              {/*{user?.user_type === 'STUDENT' && user?.subscription_name !== 'Basic Subscription' ?*/}
                              {/*    <div className="flex justify-end">*/}
                              {/*        <a*/}
                              {/*            className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"*/}
                              {/*            onClick={manageSubscriptions}*/}
                              {/*        >*/}
                              {/*            Change your Subscriptions*/}
                              {/*        </a>*/}
                              {/*    </div>*/}
                              {/*    : null*/}
                              {/*}*/}
                              <a
                                onClick={subscriptionList}
                                className="block linkClr"
                              >
                                Change your subscription
                              </a>
                              <a
                                onClick={viewHistory}
                                className="block linkClr"
                              >
                                View History
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="flex flex-col w-full ">
                <div className=" min-w-full ">
                  <div className="py-4 inline-block min-w-full w-full">
                    <div className="overflow-y-hidden	sm:overflow-x-scroll xl:overflow-x-hidden">
                      <table className=" memberShipTable min-w-full text-center w-full">
                        <thead className="border-b border-t bg-white text-white bg-primary borderClr">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Invoice #
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Membership
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Payment Status
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => {
                            return (
                              <tr className="bg-white border-b borderClr	">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item?.invoice?.id}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {handlePastHeading(item?.plan)}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  £ {item?.invoice?.total / 100}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                                  {item?.invoice?.status}
                                </td>
                                <td className="text-sm  font-light px-4 py-4 whitespace-nowrap ">
                                  {item?.created_at}
                                </td>
                                <td className="text-sm  font-light px-4  py-4 xl:whitespace-nowrap ">
                                  <button
                                    className="border-b border-t bg-white text-white bg-primary borderClr"
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "0px",
                                      padding: "0 20px 0 20px",
                                    }}
                                    onClick={() => modalView(item)}
                                  >
                                    View Receipt
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          ) : (
            "You dont have any Subscription Yet"
          )}
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allPlanData: allPlanData(state),
  viewHistoryData: viewHistoryData(state) || viewHistoryError(state)
});
const mapDispatchToProps = (dispatch) => ({
  allPlan: (body, history) => dispatch(allPlanAction(body, history)),
  viewHistoryAction: () => dispatch(viewHistoryAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(AllSubscription);
