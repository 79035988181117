import React from 'react'

const Container = ({ children, lessPadding, off }) => {

    let more = 'px-56'
    let bg = ""
    if (lessPadding) more = "px-40"
    if (off) bg = "bg-offWhite"
    return (
        <div
            className={`
            BoostPWrapper px-4
            md:px-8
            lg:px-36
            custom2:${more}
            ${bg}
            xl: px - 60n`}>
            {children}
        </div>
    )
}

export default Container
