import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import "./modal.css";
import Button from "../EmptyButton";
import RemoveIcon from "../../assets/images/removeIcon.webp";
import UploadIcon from "../../assets/images/uploadDocumentIcon.png";
import Dropzone from "react-dropzone";
import Input from "../../components/Input/InputForm";

const CustomModal = ({
  isOpen,
  closeModal,
  title,
  buttonOneText,
  onClickFirstButton,
}) => {
  const [files, setFiles] = useState([]);

  const onDrop = (filesNow) => {
    let alreadyUploaded = false;
    let uploadedFiles = [...files];
    for (let i = 0; i < filesNow.length; i++) {
      const file = {
        name: filesNow[i]?.name,
        file: filesNow[i],
        preview: URL.createObjectURL(filesNow[i]),
      };
      uploadedFiles.forEach((elem) => {
        if (elem.name === filesNow[i]?.name) {
          alreadyUploaded = true;
        }
      });
      if (!alreadyUploaded) {
        uploadedFiles.push(file);
      }
    }

    setFiles([...uploadedFiles]);
  };

  const deleteFile = (name) => {
    let uploadedFiles = [...files];

    uploadedFiles.forEach((elem, index) => {
      if (elem.name === name) {
        uploadedFiles.splice(index, 1);
      }
    });

    setFiles([...uploadedFiles]);
  };

  return (
    <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center classNames={{
            overlay: 'custom-overlay-class',
           modal: 'react-modal-class2', 
          }}>
      <p className="text-darkest text-center text-largevtext-h5 md:text-h4 font-bold mb-12">
        {title}
      </p>
      <div className="flex flex-row items-center justify-center  flex-wrap w-full">
        <Dropzone onDrop={onDrop} accept="application/pdf">
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            isDragAccept,
          }) => (
            <div
              className="dropzoneSection"
              style={{
                borderColor: isDragReject
                  ? "red"
                  : isDragAccept
                  ? "green"
                  : "#B4B4B4",
              }}
            >
              <div
                {...getRootProps()}
                className="dropzoneInner cursor-pointer flex flex-col justify-center items-center"
              >
                <input {...getInputProps()} className="dropzoneInner" />
                <img alt="upload" src={UploadIcon} />
                <p className="text-darkGrey2 font-normal text-body-large text-center pt-8">
                  {!isDragActive
                    ? "Drop your files here"
                    : "Drop them like they are hot potatoes"}
                </p>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="mt-10 px-6">
        {files.map((item, index) => (
          <div
            className="flex flex-row justify-between items-center mt-6"
            key={index}
          >
            <p className="text-darkest font-normal text-16 md:text-body-large">
              {item?.name}
            </p>
            <img
              className="cursor-pointer"
              onClick={() => deleteFile(item?.name)}
              src={RemoveIcon}
              alt="remove"
            />
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
        <div className="w-36 mt-5 md:mt-0">
          <Button
            disabled={files.length === 0}
            filled
            onClick={onClickFirstButton}
          >
            <p className="text-center text-14 xl:text-body-large">
              {buttonOneText}
            </p>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
