import * as t from '../types';
import {GetGroupParticipants, GetGroupSession, GetHoliday, GetTutorGroupSessions, PostHoliday} from '../../services'


export const postHolidaysAction = (obj) => async (dispatch) => {

    dispatch({
        type: t.POST_HOLIDAYS_REQUEST
    });

    return await PostHoliday(obj)

        .then((data) => {
            dispatch({
                type: t.POST_HOLIDAYS_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.POST_HOLIDAYS_FAILED,
                payload: err.response
            });
            return err.response.status
        });
};
