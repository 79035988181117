import React from 'react'
import {useHistory} from "react-router-dom";
import { getApiKey } from '../../helpers';
import LazyImage from '../LazyImage';

const NotesTile = ({image, text, type, prevSlug, slug}) => {

    const history = useHistory();


    let selected = text;

    function notesDetail() {
        if (type === "notes" && text.is_free === true) {
            history.push(`/${prevSlug}/notes/${slug}`);
        } else if (type === "notes") {
            if (!getApiKey()) {
                history.push({
                    pathname: `/${prevSlug}/notes/${slug}`,
                    state: {selected},
                });
            } else {
                history.push({
                    pathname: `/${prevSlug}/notes/${slug}`,
                    state: {selected},
                });
            }
        } else if (type === "essays") {
            if (getApiKey() === null) {
                history.push({
                    pathname: `/${prevSlug}/model-essays/${slug}`,
                    state: {selected},
                });
            } else {
                history.push({
                    pathname: `/${prevSlug}/model-essays/${slug}`,
                    state: {selected},
                });
            }
        } else {
            history.push(`/account/model-essays/${text.id}`);
        }
    }
    return(
        <div
            className="cursor-pointer w-full h-full bg-white rounded-3xl flex"
            style={{boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.08)"}}
            onClick={() => notesDetail()}
        >
            <div className="flex justify-center pt-5 pl-3 ">
                <div
                 className="w-max h-max m-auto "
                 >
                    <LazyImage src={image} alt={""}/>
                </div>
            </div>
            <div className="essayBox pt-4 md:pt-8 lg:pt-12 px-2 md:px-3 lg:px-5 pb-2 md:pb-4 lg:pb-5 text-left ">
                <p className="font-bold text-h5 xl:text-h4  text-left">
                    {text.title.replace("-", " ").toUpperCase()}
                </p>
            </div>
        </div>
    )    
}

export default NotesTile