import apiRequest from "../../helpers/apiRequest";

export default async (email, study) => {
    let obj = {
        email: email,
        tags: study
    }
    return await apiRequest({
        method: 'post',
        url: `content/subscribe/`,
        data: obj
    })
}
