import * as t from '../types';
import {DeleteContentEssay} from '../../services'


export const deleteContentEssayAction = (id) => async (dispatch) => {
    dispatch({
        type: t.DELETE_CONTENT_ESSAY_REQUEST
    });

    return await DeleteContentEssay(id)

        .then((data) => {
            dispatch({
                type: t.DELETE_CONTENT_ESSAY_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.DELETE_CONTENT_ESSAY_FAILED,
                payload: err.response
            });
        });
};
