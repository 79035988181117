import * as t from '../types';
import {GetSpecificStudyModules, GetStudyModuleDetail} from '../../services'

export const getSpecificStudyModules = (degree) => async (dispatch) => {
    dispatch({
        type: t.GET_STUDY_MODULES_REQUEST
    });

    return await GetSpecificStudyModules(degree)
        .then((data) => {
            dispatch({
                type: t.GET_STUDY_MODULES_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_STUDY_MODULES_FAILED,
                payload: err.response
            });
        });
};
export const getSpecificStudyModuleDetail = (slug) => async (dispatch) => {
    dispatch({
        type: t.GET_STUDY_MODULES_DETAIL_REQUEST
    });

    return await GetStudyModuleDetail(slug)
        .then((data) => {
            dispatch({
                type: t.GET_STUDY_MODULES_DETAIL_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_STUDY_MODULES_DETAIL_FAILED,
                payload: err.response
            });
        });
};
