import * as t from '../types';
import {GetTutor} from '../../services'


export const getTutor = (slug) => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_REQUEST
    });

    return await GetTutor(slug)

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_FAILED,
                payload: err.response
            });
        });
};
