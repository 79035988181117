import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./Calendar.css";
import CalendarToolbar from "./CalendarToolbar";
import apiRequest from "../../helpers/apiRequest";
import { setDate } from "date-fns";
import { connect } from "react-redux";
import { getHolidays, unAvailableTutorAction } from "../../redux/actions";
import { getHolidaysData, unAvailableTutorData } from "../../redux/selectors";

moment.locale("en-gb", {
  week: {
    dow: 1,
    doy: 1,
  },
});

const localizer = momentLocalizer(moment);

const formats = {
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, "dddd", culture),
};
const CalendarCard = ({
  tutor,
  setIsModalOpen,
  onNavigate,
  getHolidaysData,
  getHolidays,
  getUnAvailableTutor,
  getUnAvailableTutorData
}) => {
  const [myEvents, setMyEvents] = useState([]);
  let date;

  useEffect(() => {
    for (let i = 0; i < getHolidaysData?.length; i++) {
      if (
        myEvents.filter(function (e) {
          return e.title === "Tutor Holiday";
        }).length < getHolidaysData.length
      ) {
        myEvents.push({
          title: "Unavailable",
          start: new Date(getHolidaysData[i].date),
          end: new Date(getHolidaysData[i].date),
        });
      }
    }
  }, [getHolidaysData]);

  useEffect(() => {
    if (
      myEvents.filter(function (e) {
        return e.title === "Unavailable Tutor";
      }).length < getUnAvailableTutorData?.length
    ) {
      for (let i = 0; i < getUnAvailableTutorData.length; i++) {
        for (let j = 0; j < getUnAvailableTutorData[i].booked_appointment?.length; j++) {
          date = new Date(getUnAvailableTutorData[i]?.booked_appointment[j]);
          if (
            !myEvents.some((obj) => obj.start.getTime() === date.getTime())
          ) {
            myEvents.push({
              title: "Unavailable Tutor",
              start: date,
              end: date,
            });
          }
        }
      }
    }

  },[getUnAvailableTutorData])

  useEffect(() => {
    getHolidays(tutor)
    getUnAvailableTutor(tutor)
  }, [tutor]);

  return (
    <>
      <Calendar
        selectable={true}
        localizer={localizer}
        views={["month"]}
        formats={formats}
        events={myEvents}
        components={{
          toolbar: CalendarToolbar,
        }}
        onSelectSlot={(e) => {
          setIsModalOpen(true);
          onNavigate(e.start, null);
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "lightgrey",
            color: "black",
            borderRadius: "0px",
            border: "none",
          };

          if (event.title === "Holiday") {
            newStyle.backgroundColor = "lightgrey";
            newStyle.color = "black";
          } else if (event.title === "Tutor Holiday") {
            newStyle.backgroundColor = "lightgrey";
            newStyle.color = "black";
          } else if (event.title === "Unavailable Tutor") {
            newStyle.backgroundColor = "lightgrey";
            newStyle.color = "black";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}
        style={{ height: "100%", maxWidth: "100%" }}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  getHolidaysData: getHolidaysData(state),
  getUnAvailableTutorData: unAvailableTutorData(state)
});

const mapDispatchToProps = (dispatch) => ({
  getHolidays: (user) => dispatch(getHolidays(user)),
  getUnAvailableTutor: (tutor) => dispatch(unAvailableTutorAction(tutor))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCard);
