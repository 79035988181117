import * as t from '../types';
import { GetCheckList } from '../../services'

export const checkListAction = () => async (dispatch) => {
    dispatch({
        type: t.GET_CHECKLIST_REQUEST
    });

    return await GetCheckList()
        .then((data) => {
            dispatch({
                type: t.GET_CHECKLIST_SUCCESS,
                payload: data.data,
                
            
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CHECKLIST_FAILED,
                payload: err.response
            });
        });
};
