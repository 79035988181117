import * as t from '../types';
import {GetCaseSummaries} from '../../services'


export const getCaseSummaries = (page, title,slug) => async (dispatch) => {
    dispatch({
        type: t.GET_CASE_SUMMARIES_REQUEST
    });

    return await GetCaseSummaries(page, title,slug)
        .then((data) => {
            dispatch({
                type: t.GET_CASE_SUMMARIES_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CASE_SUMMARIES_FAILED,
                payload: err.response
            });
        });
};
