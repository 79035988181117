import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import Button from '../../components/EmptyButton';
import LetterPagination from '../../components/LettersPagination';
import {getCaseSummaries} from '../../redux/actions';
import {caseSummariesData, caseSummariesError, caseSummariesLoading} from '../../redux/selectors';
import FullLoader from '../../components/Loader/FullLoader';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {getApiKey, getUser, slugToText} from '../../helpers';
import {Helmet} from "react-helmet";

const SingleCaseSummary = ({data}) => {

    const params = useParams()
    const history = useHistory();
    const location = useLocation()


    const readCaseStudy = () => {
        history.push({
            pathname: `/${data.slug}/`,
            state: {module: slugToText(params.slug), paidModules: location?.state?.paidModules},
        });
        localStorage.setItem('studyModule', params.slug)
    };

    return (

        <div className="afterPagSubSec bg-white md:w-full lg:w-full lg:mb-30 md:mb-20 mb-12  cursor-pointer"
             style={{boxShadow: '4px 8px 20px rgba(2, 23, 54, 0.03)', borderRadius: '25px'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Case Summaries | {slugToText(params.slug)}</title>
                <meta name="description" content="Case Summaries Description"/>
                <link rel="canonical" href={`https://simplestudying.com/case-studies/${params.slug}/`}/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Simple Studying | Case Summaries | ${slugToText(params.slug)}`}/>
                <meta property="og:description" content="Case Summaries Description"/>
                <meta property="og:url" content={`https://simplestudying.com/case-studies/${params.slug}/`}/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="aftPagMainSec p-2 md:p-4 lg:p-6">
                <p className="font-bold md:text-h5 xl:text-h3">{data.title}</p>
                <p className="custom2:text-16 xl:text-body-large  mt-2 md:mt-5 mb-6 lg:mb-11" style={{userSelect: 'none'}}>
                    <div className="dangerousSummary" dangerouslySetInnerHTML={{__html: window.innerWidth < 900 ? data.content.split(' ')
                        .slice(0, 30)
                        .join(' ') : data.content}}/>
                </p>
                <div className="w-full md:w-56 h-8 md:h-10 lg:h-12">
                    <Button filled onClick={readCaseStudy}>
                        <p className="readCBtn text-center text-14 xl:text-body-large" onClick={readCaseStudy}>Read case
                            study</p>
                    </Button>
                </div>

            </div>
        </div>
    );
};

const CaseSummaries = (props) => {
    const {
        caseSummariesData,
        loading,
        getCaseSummaries,
    } = props;

    const params = useParams()
    const history = useHistory()
    const user = JSON.parse(getUser())

    const [state, setState] = useState({
        caseSummary: [],
        next: null,
        page: 1,
        title: null,
        prev: null
    })

    const breadCrumbItem = [
        {title: "Get study modules", link: "/"},
        {
            title: slugToText(params.slug),
            link: `/study-module/${params.slug}`,
        },
    ];

    useEffect(() => {
        getCaseSummaries(null, null, params.slug);
    }, []);
    useEffect(() => {
        if (caseSummariesData?.results?.length) {
            setState((prev) => ({
                ...prev,
                caseSummary: caseSummariesData.results,
                next: caseSummariesData.next,
                prev: caseSummariesData.previous,
            }))
        }
    }, [caseSummariesData]);

    const letterChange = (title) => {
        setState((prev) => ({
            ...prev,
            title: title,
        }))
        getCaseSummaries(null, title, params.slug);
    };
    const subscription = () => {
        if (getApiKey()) {
            history.push('/subscription');
        } else {
            history.push('/subscription');
        }
    };

    const nextCase = () => {
        if (state.next && state.title) {
            setState((prev) => ({
                ...prev,
                page: prev.page+1,
            }))
            getCaseSummaries(state.page + 1, state.title, params.slug);
        } else if (state.next && state.title === null) {
            setState((prev) => ({
                ...prev,
                page: prev.page+1,
            }))
            getCaseSummaries(state.page + 1, null, params.slug);
        }
    };
    const prevCase = () => {
        if (state.prev && state.title) {
            setState((prev) => ({
                ...prev,
                page: prev.page-1,
            }))
            getCaseSummaries(state.page - 1, state.title, params.slug);
        } else if (state.prev && state.title === null) {
            setState((prev) => ({
                ...prev,
                page: prev.page-1,
            }))
            getCaseSummaries(state.page - 1, null, params.slug);
        }
    };

    return (
        <>
            {loading ? <FullLoader/> : null}
            <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Simple Studying | Case Summaries</title>
                    <link rel="canonical" href="http://mysite.com/example"/>
                </Helmet>
                <Container>
                    {getApiKey() ? (
                        <div className=" py-4 md:py-10 lg:py-14">
                            <Breadcrumb items={breadCrumbItem} current={"Case Summaries"}/>
                        </div>
                    ) : null}
                    <p className="caseHeadText text-36 font-bold">Cases: {slugToText(params.slug)}</p>
                    <div className="letterPagMain pt-4 md:pt-8 lg:pt-14 flex justify-center space-x-1 flex-wrap">
                        <LetterPagination onPageChange={letterChange}/>
                    </div>
                    {caseSummariesData?.results?.length === 0 ? (
                        <p className='noResultText' style={{textAlign: "center", marginTop: "50px"}}>
                            No Results Found
                        </p>
                    ) : (
                        <div className="afterPagSec mt-4 md:mt-8 lg:mt-11">
                            {state.caseSummary ? (
                                state.caseSummary?.map((item, index) => (
                                    <SingleCaseSummary
                                        data={item}
                                    />
                                ))
                            ) : (
                                <p>No results found</p>
                            )}
                        </div>
                    )}
                    <div className="nextprevBtnSec py-2 md:py-3 lg:py-5 flex items-center justify-between md:justify-between">
                        {state.prev ? (
                            <Button
                                onClick={() => prevCase()}
                                className="cursor-pointer border border-darkest py-6 rounded-2xl w-40 h-8  md:h-10 lg:w-56 lg:h-16 flex items-center justify-center relative"
                            >
                                <p className=" text-body-large">
                                    Previous case studies
                                </p>
                            </Button>
                        ) : (
                            <div></div>
                        )}
                        {state.next && caseSummariesData?.results?.length > 0 ? (
                            <Button
                                onClick={() => nextCase()}
                                className="cursor-pointer border border-darkest py-6 rounded-2xl w-40 h-6 md:h-10  lg:w-56 lg:h-14 flex items-center justify-center relative"
                            >
                                <p className=" text-body-large">
                                    Next case studies
                                </p>
                                </Button>
                        ) : null}
                    </div>
                    <div className="pt-10 md:pt-18 lg:pt-24 pb-10 md:pb-18 lg:pb-24">
                        <div className="flex flex-col items-center md:flex-row custom:justify-center px-12 md:px-20">
                            {!getApiKey() || user?.subscription_name === 'FREE_PLAN' ?
                                <div className="w-10/12 custom:w-1/3 xl:w-96">
                                    <Button filled onClick={subscription}>
                                        <p className="text-center text-14 xl:text-body-large">Get all the Study
                                            Materials <br/>for
                                            £11.25/month</p>
                                    </Button>
                                </div>: null}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    loading: caseSummariesLoading(state),
    caseSummariesError: caseSummariesError(state),
    caseSummariesData: caseSummariesData(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCaseSummaries: (page, title, slug) =>
        dispatch(getCaseSummaries(page, title, slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CaseSummaries);
