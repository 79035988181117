
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'SIGNUP_FAILED';


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';


export const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';
export const LOGIN_GOOGLE_SUCCESS = 'LOGIN_GOOGLE_SUCCESS';
export const LOGIN_GOOGLE_FAILED = 'LOGIN_GOOGLE_FAILED';

export const LOGIN_FACEBOOK_REQUEST = 'LOGIN_FACEBOOK_REQUEST';
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS';
export const LOGIN_FACEBOOK_FAILED = 'LOGIN_FACEBOOK_FAILED';



