import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Slider = ({children, responsive}) => {

    const Arrow = ({ onClick, ...rest}) => (
        <button className="arrow left z-20" onClick={onClick} style={{background: 'red'}}>
            {/* <ArrowBackIcon style={{ fontSize: "50px" }} /> */}
            btn
        </button>
    )
    return (
        <Carousel 
            swipeable
            draggable
            arrows
            autoPlay={false}
            infinite
            responsive={responsive}
            renderButtonGroupOutside={true}
            // customRightArrow={<Arrow />}
            // customLeftArrow={<Arrow />}
            containerClass="admin-slider-class"
            sliderClass='slider-clas'
            itemClass="z-10"
            >
            {children}
        </Carousel>
    )
}

export default Slider
