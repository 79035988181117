import * as t from '../types';

const initialState = {
    message: '',
    loading: false,
    error: ''
};

export function passwordReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.CHANGE_PASSWORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.CHANGE_PASSWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.data
            };
        case t.CHANGE_PASSWORDS_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}


const initialStateForConfirmPassword = {
    message: '',
    loading: false,
    error: ''
};

export function passwordConfirmReducer(state = initialStateForConfirmPassword, action) {
    const {type, payload} = action;
    switch (type) {
        case t.CONFIRM_PASSWORDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.CONFIRM_PASSWORDS_SUCCESS:
            return {
                ...state,
                loading: false,
                message: payload.data
            };
        case t.CONFIRM_PASSWORDS_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
