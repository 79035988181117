import * as t from '../types';
import {SubscribedPackage} from '../../services'

export const subscribedPackageAction = (obj) => async (dispatch) => {
    dispatch({
        type: t.POST_SUBSCRIBED_PACKAGE_REQUEST
    });

    return await SubscribedPackage(obj)
        .then((data) => {
            dispatch({
                type: t.POST_SUBSCRIBED_PACKAGE_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.POST_SUBSCRIBED_PACKAGE_FAILED,
                payload: err.response
            });
        });
};
