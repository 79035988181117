import React from 'react'
import LoadingSpinner from './LoadingSpinner'


const FullLoader = ({ className, color }) => {
    return (
        <div className='fullLoader'>
            <LoadingSpinner color='#cc6828'></LoadingSpinner>
        </div>
    )
}

export default FullLoader
