import * as t from '../types';
import {UpdateSessionOne2OneData} from '../../services'
import {
    UPDATE_TUTOR_ONE_2_ONE_FAILED,
    UPDATE_TUTOR_ONE_2_ONE_REQUEST,
    UPDATE_TUTOR_ONE_2_ONE_SUCCESS,
} from "../types";


export const tutorSessionOneToOneUpdate = (obj, slug) => async (dispatch) => {

    dispatch({
        type: t.UPDATE_TUTOR_ONE_2_ONE_REQUEST
    });

    return await UpdateSessionOne2OneData(obj, slug)

        .then((data) => {
            dispatch({
                type: t.UPDATE_TUTOR_ONE_2_ONE_SUCCESS,
                payload: data
            });
            window.location.reload();
        })
        .catch((err) => {
            dispatch({
                type: t.UPDATE_TUTOR_ONE_2_ONE_FAILED,
                payload: err.response
            });
        });
};
