import React, {useEffect, useState} from "react";
import LazyImage from "../LazyImage";
import {Modal} from "react-responsive-modal";

import {Viewer, Worker} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import InfoBox from "../InfoBox/ExtraSmallInfobox";
import InfoImg from "../../assets/icons/information.svg";

const StudyMaterial = ({img, alt, children, data}) => {
    const [modal, setModal] = useState(false);
    const [pdfState, setPdfState] = useState('')
    const [url, setUrl] = useState('')
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const handleClosed = () => {
        setModal(false);
    };

    useEffect(() => {
        for(let i=0; i<data?.length; i++){
            if(alt?.props?.children[0] === 'Revision '){
                setPdfState(data[i].note)
            } else if(alt?.props?.children[0] === 'Model '){
                setPdfState(data[i].essay)
            } else if(alt?.props?.children[0] === 'Tutorial '){
                setUrl(data[i].tutorial_video)
            } else if(alt?.props?.children === 'Quizzes & Flashcards'){
                setUrl('https://somup.com/c0jvDrAxJh')
            } else if(alt?.props?.children === 'Case Summaries') {
                setUrl('https://somup.com/c0jvDFAxHP')
            } else if(alt?.props?.children === 'Interactive Learning Platform '){
                setUrl('https://linktr.ee/SimpleStudying_studygroups')
            }
        }
    },[alt?.props.children[0], data?.length])

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {ZoomOut} = slots;
                return (
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <div style={{padding: "0px 2px"}}></div>
                        ...
                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [showInfo, setShowInfo] = useState(false)

    return (
        <div className="flex flex-col items-center xl:mt-12" onMouseEnter={() => setShowInfo(true)}
             onMouseLeave={() => setShowInfo(false)}>
            <LazyImage src={img} alt={alt} onClick={() => {
                if(pdfState.includes('.pdf')){
                    setShowInfo(false)
                    setModal(true)
                } else{
                    window.open(url, "_blank")
                }
            }}
            />
            <div className="flex items-baseline relative wayPossSec">
                <img
                    src={InfoImg}
                    alt="info-img"
                    className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4 wayPossImg "
                />
                {showInfo && (
                    <div>
                        {alt?.props?.children[0] === 'Revision ' ? <InfoBox
                                text='Summary of all you need to know from textbooks, court judgments and journal articles in few pages. Save time by focusing on what matters.'/>
                            : alt?.props?.children[0] === 'Model ' ? <InfoBox
                                    text='First and 2.1 Class answers to learn structuring problem and essay questions.'/>
                                : alt?.props?.children[0] === 'Tutorial ' ?
                                    <InfoBox text='You dont like reading a lot? learn with our videos!'/>
                                    : alt?.props?.children === 'Quizzes & Flashcards' ?
                                        <InfoBox text='Designed specifically to practice your knowledge and memorise.'/>
                                        : alt?.props.children === 'Case Summaries' ? <InfoBox
                                                text='Simple but detailed case summaries with relevant pictures to easily memorise.'/>
                                            : alt?.props?.children === 'Interactive Learning Platform ' ? <InfoBox
                                                    text='Join our study groups to interact with other students to share experience and study tips'/>
                                                : null
                        }
                    </div>
                )}
                <p className="text-body-large font-bold text-14 sm:text-14 md:text-h4 custom:text-h3">{children}</p>
            </div>
            {/*<img*/}
            {/*    src={InfoImg}*/}
            {/*    alt="info-img"*/}
            {/*    className="mr-1 w-16 sm:w-24 custom:w-5 custom:mr-4"*/}
            {/*    onMouseEnter={() => setShowInfo(true)}*/}
            {/*    onMouseLeave={() => setShowInfo(false)}/>*/}
            {/*{showInfo && (*/}
            {/*    <div className="absolute z-10 top-14 -left-6">*/}
            {/*        <InfoBox*/}
            {/*            text={alt}/>*/}
            {/*    </div>)}*/}
            {/*{children}*/}

            <Modal open={modal} onClose={handleClosed} focusTrapped={false} center>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <div style={{height: "720px"}}>
                        <Viewer
                            fileUrl={pdfState}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </div>
                </Worker>
                {/* <Document
          file={
            
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page height="600" pageNumber={pageNumber} />

          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document> */}
            </Modal>
        </div>
    );
};

export default StudyMaterial;
