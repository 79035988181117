import * as t from '../types';
import {GetTeam} from '../../services'

export const getTeam = () => async (dispatch) => {
    dispatch({
        type: t.GET_TEAM_REQUEST
    });

    return await GetTeam()
        .then((data) => {
            dispatch({
                type: t.GET_TEAM_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TEAM_FAILED,
                payload: err.response
            });
        });
};
