import * as t from '../types';
import {ContentTerms} from '../../services'


export const getContentTermsAction = () => async (dispatch) => {
    dispatch({
        type: t.GET_CONTENT_TERMS_REQUEST
    });

    return await ContentTerms()

        .then((data) => {
            dispatch({
                type: t.GET_CONTENT_TERMS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CONTENT_TERMS_FAILED,
                payload: err.response
            });
        });
};
