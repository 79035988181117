import * as t from '../types';

const initialState = {
    data: '',
    loading: false,
    error: ''
};

export default function flashCardsReducer(state = initialState, action) {
    const {type, payload} = action;
    
    switch (type) {
        case t.GET_FLASHCARDS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.GET_FLASHCARDS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload
            };
        case t.GET_FLASHCARDS_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
