import * as t from '../types';
import {GetTutoring} from '../../services'


export const getTutoring = () => async (dispatch) => {

    dispatch({
        type: t.GET_TUTORING_REQUEST
    });

    return await GetTutoring()

        .then((data) => {
            dispatch({
                type: t.GET_TUTORING_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTORING_FAILED,
                payload: err.response
            });
        });
};
