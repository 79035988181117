import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import './modal.css'
import Button from '../EmptyButton'

import Input from "../Input/InputForm";

const CustomModal = ({ isOpen, closeModal, title, buttonOneText, onClickFirstButton }) => {
    const [email, setEmail] = useState('')
    const [university, setUniversity] = useState('')
    return (
        <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center>
            <p className="text-darkest text-large md:text-h5 font-bold mb-5 text-center">{title}</p>
            <div className='text-darkest font-normal mt-14 w-full mb-3 md:mb-9'>
                <p className='mb-8'>University</p>
                <Input type="text" placeholder="Enter your university" value={university} setValue={setUniversity} />
            </div>
            <div className="text-darkest font-normal w-full mb-3 md:mb-9 " >
                <p className='mb-8'>
                    Email
                </p>
                <Input type="email" placeholder="Enter your email" value={email} setValue={setEmail} />
            </div>
            <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">

                <div className="w-56 mt-5 md:mt-0">
                    <Button filled onClick={onClickFirstButton}>
                        <p className='text-center text-14 xl:text-body-large'>{buttonOneText}</p>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CustomModal
