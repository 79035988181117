import React, { useContext, useState } from "react";
import LazyImage from "../../components/LazyImage";
import Button from "../EmptyButton";
import ListDot from "../../assets/icons/list-dot.svg";
import { updateUser } from "../../redux/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updatedUserData, userData } from "../../redux/selectors";
import { FaCheckCircle } from "react-icons/fa";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import InfoImg from "../../assets/icons/information.svg";
import Context from "../../services/Context";
import { getUser } from "../../helpers";
let divs = [];

// import StripeCheckout from "react-stripe-checkout";

const SubscriptionPlanCard = ({
  isTutoring = false,
  image,
  id,
  headline,
  description,
  price,
  packageDetails,
  total,
  value,
  best,
  selected,
  handleClick,
}) => {
  const [showInfo, setShowInfo] = useState(null);
  const [trialExpiredInfo, setTrialExpiredInfo] = useState(false);
  const user = JSON.parse(getUser())

  const [basicBullets, setBasicBullets] = useState([
    {
      heading: "Unlimited Case Summaries",
    },
    {
      heading: "Short Summaries of key legal principles",
    },
    {
      heading: "Cancel anytime",
    },
  ]);

  const [bulletDetail, setBulletDetail] = useState([
    {
      heading: "1 hour free tutoring",
      info: "Personalized support from a tutor",
      show: false,
    },
    {
      heading: "Revision Notes",
      info: "<p>Summary of all you need to know from textbooks, court judgments and journal articles in few pages. Save time on focusing what matters. Materials prepared by a team of successful law students and graduates who recently were in your position and achieved 2.1 or First Class in their respective law degrees.</p> <br/><p style='color:red'>On average, SimpleStudying will save you 738 hours of reading time per year compared to textbooks. That's 2213 LESS pages to read per year! </p>",
      show: false,
    },
    {
      heading: "Case Summaries",
      info: "Simple but detailed case summaries with relevant pictures to easily memorise.",
      show: false,
    },
    {
      heading: "Essay examples",
      info: "First and 2.1 Class answers to learn structuring problem and essay questions.",
      show: false,
    },
    {
      heading: "Quiz",
      info: "Designed specifically to practice your knowledge and memorise.",
      show: false,
    },
    {
      heading: "Flashcards",
      info: "One sentence summaries of key principles. ",
      show: false,
    },
    {
      heading: "Tutorial videos",
      info: "You don't like reading a lot? learn with our videos!",
      show: false,
    },
    {
      heading: "Cancel anytime",
      info: "First and 2.1 Class answers to learn structuring problem and essay questions ",
      show: false,
    },
  ]);

  const context = useContext(Context)

  const userCurrency = context?.value?.userCurrency || user?.user_currency


  const mouseEnter = (headlines, item) => {
    if (window.innerWidth < 576) {
      if (item === 1) {
        let divs = [
          document.getElementById(headlines + "_" + (item + 1)),
          document.getElementById(headlines + "_" + (item + 2)),
          document.getElementById(headlines + "_" + (item + 3)),
          document.getElementById(headlines + "_" + (item + 4)),
          document.getElementById(headlines + "_" + (item + 5)),
        ];
        for (let i = 0; i < divs.length; i++) {
          divs[i].style.display = " none";
        }
      } else {
        document.getElementById(headlines + "_" + (item + 1)).style.display =
          "none";
      }
    } else {
      if (item === 1) {
        let divs = [
          document.getElementById(headlines + "_" + (item + 1)),
          document.getElementById(headlines + "_" + (item + 2)),
          document.getElementById(headlines + "_" + (item + 3)),
        ];
        for (let i = 0; i < divs.length; i++) {
          divs[i].style.display = " none";
        }
      } else {
        document.getElementById(headlines + "_" + (item + 1)).style.display =
          "none";
      }
    }
  };

  const mouseLeave = (headlines, item) => {
    if (window.innerWidth < 576) {
      if (item === 1) {
        let divs = [
          document.getElementById(headlines + "_" + (item + 1)),
          document.getElementById(headlines + "_" + (item + 2)),
          document.getElementById(headlines + "_" + (item + 3)),
          document.getElementById(headlines + "_" + (item + 4)),
          document.getElementById(headlines + "_" + (item + 5)),
        ];
        for (let i = 0; i < divs.length; i++) {
          divs[i].style.display = " block";
        }
      } else {
        document.getElementById(headlines + "_" + (item + 1)).style.display =
          "block";
      }
    } else {
      if (item === 1) {
        let divs = [
          document.getElementById(headlines + "_" + (item + 1)),
          document.getElementById(headlines + "_" + (item + 2)),
          document.getElementById(headlines + "_" + (item + 3)),
        ];
        for (let i = 0; i < divs.length; i++) {
          divs[i].style.display = " block";
        }
      } else {
        document.getElementById(headlines + "_" + (item + 1)).style.display =
          "block";
      }
    }
  };

  const bulletRender = (index) => {
    return (
      index === 0 &&
      (headline?.props?.children === "QUICK BOOST OF YOUR GRADES" ||
        headline?.props?.children === "GET COVERED FOR THE EXAM PERIOD")
    );
  };

  const headingHandle = () => {
    if (
      headline?.props?.children ===
      "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)"
    ) {
      return "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING";
    } else if (headline?.props?.children === "QUICK BOOST OF YOUR GRADES") {
      return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING";
    } else if (
      headline?.props?.children ===
      "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)"
    ) {
      return "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING";
    } else if (headline?.props?.children === "GET COVERED FOR THE EXAM PERIOD") {
      return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING";
    } else if (
      headline?.props?.children ===
      "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)"
    ) {
      return "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING";
    } else if (
      headline?.props?.children === "IDEAL IF YOU AIM FOR A FIRST CLASS"
    ) {
      return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING";
    }
  };

  return (
    // ${best && 'xl:w-full'}
    <div
      className={`
            flex 
            flex-col 
            w-full 
            lg:pb-14
            md:pb-8
            pb-2 
            bg-white 
            mt-2 md:mt-7 
            relative 
            ${selected && "border-4 border-borderGreen"}
         
            lg:mr-5
            subpackgBox
           
            `}
      style={style.cardContainer}
      id={`card-${id}`}
    >
      <div
        className={`${
          headline?.props?.children ===
            "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)" ||
          headline?.props?.children ===
            "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" ||
          headline?.props?.children ===
            "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)"
            ? "grey-top"
            : "purple-top"
        } lg:pt-14 md:pt-8 pt-2 px-1 md:px-2 lg:px-5`}
      >
        {/* <div className="font-bold text-center mb-4">
          <p>Your Subscription Details</p>
        </div> */}
        {headline?.props?.children === "IDEAL IF YOU AIM FOR A FIRST CLASS" ? (
          <p
            className={
              "absolute right-0 top-7 text-white text-14 py-2 px-2.5 rounded-l-lg xl:text-15 linear-background"
            }
          >
            <b>BEST VALUE</b>
          </p>
        ) : null}

        <div className="flex justify-center">
          <div className="h-36 w-36 xl:h-60 xl:w-60">
            <LazyImage alt="img" src={image} />
          </div>
        </div>
        {headline?.props?.children ===
          "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)" ||
        headline?.props?.children ===
          "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" ||
        headline?.props?.children ===
          "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)" ? (
          <p
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="text-darkGrey"
          >
            <b>BASIC</b>
          </p>
        ) : (
          <p
            style={{ textAlign: "center", marginBottom: "10px" }}
            className="text-darkGrey"
          >
            <b>PREMIUM</b>
          </p>
        )}
        <div className="w-full text-center text-h5 lg:text-h4 font-bold custom2:text-body-large xl:text-h3">
          {headingHandle()}
          {/*  */}
          <div
            className="flex flex-col justify-center items-center"
            style={{ width: "100%", marginTop: "12px", minHeight: "115px" }}
          >
            <p
              className="mt-2 lg:mt-5 text-15 text-darkGrey xl:w-80 xl:m-auto"
              dangerouslySetInnerHTML={{ __html: description }}
            >
              {/*{description}*/}
            </p>
          </div>
        </div>
        <div className="mt-2 md-mt-6 lg:mt-11 ">
          <div className="text-center relative">
            <span className="priceText text-42 font-bold xl:text-h1 linear-color relative ">
              {price}
              <span
                className="
                        absolute 
                        -inset-y-2
                        left-14
                        sm:left-20
                        text-large-medium 
                        font-bold 
                        linear-color
                        xl:text-36 curncyIcon priceText currency-sign"
              >
                {userCurrency === "UK" ? "£" : "$"}
              </span>
            </span>
          </div>
          <p className="monthText text-center text-16 font-bold xl:text-body-large">
            {headline?.props?.children ===
              "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)" ||
            headline?.props?.children === "QUICK BOOST OF YOUR GRADES" ? (
              "Billed Monthly"
            ) : headline?.props?.children ===
                "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" ||
              headline?.props?.children ===
                "GET COVERED FOR THE EXAM PERIOD" ? (
              <div>
                <p>
                  Billed Quarterly{" "}
                  <span style={{ color: "red" }}>(Saving 16%)</span>
                </p>
                <br />
                {headline?.props?.children ===
                "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" ? (
                  <p>{userCurrency === "UK" ? "£" : "$"} 2.5/month</p>
                ) : headline?.props?.children ===
                  "GET COVERED FOR THE EXAM PERIOD" ? (
                  <p>{userCurrency === "UK" ? "£" : "$"} 12.5/month</p>
                ) : null}
              </div>
            ) : (
              (headline?.props?.children ===
                "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)" ||
                headline?.props?.children ===
                  "IDEAL IF YOU AIM FOR A FIRST CLASS") && (
                <p>
                  Billed Annually{" "}
                  <span style={{ color: "red" }}>(Saving 25%)</span>
                </p>
              )
            )}
            <div className="mt-4">
              {headline?.props?.children ===
              "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)" ? (
                <p>{userCurrency === "UK" ? "£" : "$"}2.24/month</p>
              ) : headline?.props?.children ===
                "IDEAL IF YOU AIM FOR A FIRST CLASS" ? (
                <p>{userCurrency === "UK" ? "£" : "$"}11.25/month</p>
              ) : null}
            </div>
          </p>
        </div>
        {/* <div className="mt-2 md:mt-6 lg:mt-10 w-full flex flex-col flex-start">
        {packageDetails.map((elem, index) => (
          <div className="flex items-center xl:mt-5" key={index}>
            <LazyImage alt="Dot" src={ListDot} />
            <p className="subBultText ml-3  text-16 font-bold xl:text-body-large">
              {elem.text}
            </p>
          </div>
        ))}
      </div> */}
        <div className="mt-4 md:mt-8 lg:mt-14 text-center">
          {/* <p className="text-h6 md:text-h5 font-bold linear-color">
          Total £{price === 8.33 ? "25" : price === 7.5 ? "90" : 10}
        </p> */}
          {/* <p className="text-15 text-darkGrey text-center mt-2.5">{value}</p> */}
          {!description?.includes(
            "Saving you thousands of hours in case law research"
          ) ? (
            <p className="text-18 text-darkGrey text-left mt-6">
              Everything in Basic plus...
            </p>
          ) : (
            <div className="mt-28"></div>
          )}
        </div>
        <div className="subPckSelectBtn mt-2 md:mt-6 lg:mt-10 text-h6 xl:text-h4 cursor-pointer">
          {!description?.includes(
            "Saving you thousands of hours in case law research"
          ) ? (
            <div>
              {bulletDetail?.length > 0
                ? bulletDetail?.map((item, index) => {
                    return (
                      <div className="subPlan">
                      <div className="tickBulletSec1 sub-card">
                        {bulletRender(index) ? null : <FaCheckCircle />}
                        <h5>{bulletRender(index) ? null : item?.heading}</h5>
                        {bulletRender(index) ||
                        item.heading === "Cancel anytime" ? null : (
                          <div className="toolTipMain ">
                            <img
                              id={
                                headline.props.children.split(" ")[0] +
                                "_" +
                                index
                              }
                              src={InfoImg}
                              alt="info-img"
                              className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                              onMouseEnter={() => {
                                setShowInfo(index);
                                mouseEnter(
                                  headline.props.children.split(" ")[0],
                                  index
                                );
                              }}
                              onMouseLeave={() => {
                                setShowInfo(null);
                                mouseLeave(
                                  headline.props.children.split(" ")[0],
                                  index
                                );
                              }}
                            />
                            {showInfo === index && (
                              <div className=" tooltipSSec cases">
                                <InfoBox text={item?.info} />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      </div>
                    );
                  })
                : null}
            </div>
          ) : (
            <div>
              {basicBullets?.length > 0 &&
                basicBullets?.map((item, index) => {
                  return (
                    <div className="tickBulletSec1 ">
                      <FaCheckCircle />
                      <h5>{item?.heading}</h5>
                    </div>
                  );
                })}
            </div>
          )}

          <div className="selectBtnBox">
            {/* <div className="mb-4"> */}
            {headline?.props?.children.includes('STARTER PACK FOR IMPROVING') && 
              <div className="infoAboutMain w-24">
              <div className="FreeTrailBtnSec text-right flex  pb-2 relative">
                
              
                <div className=" relative flex flex-row-reverse" >
                <p>Important Notice</p>


                  <img
                    src={InfoImg}
                    alt="info-img"
                    className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4 info-icon-img"
                    onMouseEnter={() => setTrialExpiredInfo(true)}
                    onMouseLeave={() => setTrialExpiredInfo(false)}
                  />
                  {trialExpiredInfo && (
                    <div className=" tooltipSSec free-tool">
                      <InfoBox text={"Basic starter pack does not give access to the full notes, example essays, quizzes, flashcards and videos that are essential for a First Class. If you want to unlock all our simple study materials to save on average 800 hours per year of reading time whilst maximising your chances of getting a First Class law degree, you should get the premium subscription instead starting from just £11.25/month!"} />                    </div>
                    )}
                </div>
              </div>
            </div>
            }

              
            {/* </div> */}
            <Button
              text={selected ? "Selected" : "Select"}
              filled={selected}
              onClick={handleClick}
              disabled={selected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  cardContainer: {
    boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.1)",
    borderRadius: "25px",
  },
};

SubscriptionPlanCard.propTypes = {
  image: PropTypes.any,
  headline: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  packageDetails: PropTypes.array,
  total: PropTypes.string,
  value: PropTypes.string,
  best: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  data: updatedUserData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (body) => dispatch(updateUser(body)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanCard);
