import React from 'react';

import './index.css';

const SingleCheckbox = ({id, text, correct, handleChange}) => {
    return (<div key={id} className={'abc'}>
        <div className="checkbox flex items-center  mt-4" id={'blur_options'}>
            <input type="checkbox" onChange={(e) => handleChange(e.target.value, e.target.checked)}
                   value={text?.length > 0 ? text : id}
                   className="input-assumpte" id={id} checked={correct}/>
            <label htmlFor={text}
                   className="pnpModel text-16 custom2:w-10/12 md:text-body-large text-darkest flex items-center">{text}</label>
        </div>
    </div>);
};

export default SingleCheckbox;
