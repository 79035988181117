import apiRequest from "../../helpers/apiRequest";

export default async (data) => {

    const res = await apiRequest({
        method: 'put',
        url: `accounts/users/update_profile/`, data,
    });

    return res.data
}
