import * as t from '../types';
import {CreateModule} from '../../services'
import { createErrorAlert } from './errorAlertAction';


export const createModuleAction = (formData) => async (dispatch) => {
    dispatch({
        type: t.CREATE_MODULE_REQUEST
    });

    return await CreateModule(formData)

        .then((data) => {
            dispatch({
                type: t.CREATE_MODULE_SUCCESS,
                payload: data
            });
            if(data.status === 200){
                dispatch(createErrorAlert(200, '', 'Study Module Created Sucessfully', 200))
                localStorage.setItem('studyModuleId', data.data.study_module)
            }
            return 'success'
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_MODULE_FAILED,
                payload: err.response
            });
            dispatch(createErrorAlert(400, '', 'Something went wrong', 400))
            return 'err'
        });
};
