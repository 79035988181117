import * as t from '../types';
import {DeleteHoliday, GetGroupParticipants, GetGroupSession, GetHoliday, GetTutorGroupSessions} from '../../services'


export const deleteHolidays = (id) => async (dispatch) => {

    dispatch({
        type: t.DELETE_HOLIDAYS_REQUEST
    });

    return await DeleteHoliday(id)

        .then((data) => {
            dispatch({
                type: t.DELETE_HOLIDAYS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.DELETE_HOLIDAYS_FAILED,
                payload: err.response
            });
        });
};
