import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiRequest } from "../../helpers";
import Container from "../../components/container";

const BlogsDetail = () => {
  const params = useParams();
  const [state, setState] = useState({
    blog: null,
  });

  useEffect(() => {
    getBlogDetail();
  }, []);

  const getBlogDetail = async () => {
    await apiRequest({
      url: `content/blog/${params.slug}`,
    }).then((res) => {
      setState((prev) => ({
        ...prev,
        blog: res.data,
      }));
    });
  };

  return (
    <Container>
      <div className="caseSummarMain">
        <div className="caseSummarSubMain flex justify-between pt-16 flex-wrap pb-40">
          <div className="flex flex-col items-center">
            <p className="text-h5 md:text-h3 xl:text-36 font-bold fk text-center mb-20">
              {state?.blog?.title}
            </p>
          </div>
          <p
            id={`content`}
            className={`text-body-large alink`}
            dangerouslySetInnerHTML={{ __html: state?.blog?.content }}
          ></p>
        </div>
      </div>
    </Container>
  );
};

export default BlogsDetail;
