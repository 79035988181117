import React, { useState, useEffect } from "react";
import Select from "../../components/Select";
import Button from "../../components/EmptyButton";
import Dropzone from "react-dropzone";
import RemoveIcon from "../../assets/images/removeIcon.webp";
import UploadIcon from "../../assets/newImages/docIcon.png";
import { apiRequest } from "../../helpers";
import { createErrorAlert } from "../../redux/actions";
import { useDispatch } from "react-redux";
const Review = () => {

  const [words, setWords] = useState([]);
  const [files, setFiles] = useState([]);
  const [postObj, setPostObj] = useState({
    word_id: "",
    file: files[0]?.file,
    description: ""
  })

  const dispatch = useDispatch()

  const handleSelectedItem = (item) => {
    setPostObj((prev) => ({
        ...prev,
        word_id: item.id,
    }))
  };

  const handleFetchWords = async () => {
    await apiRequest({
        method: "get",
        url: "subscriptions/appointment/words/"
    }).then((res) => {
        let array = []
        for(let i=0; i<res?.data?.data?.length; i++){
            array.push({
                id: res?.data?.data[i]?.id,
                value: res?.data?.data[i]?.words,
                label: (
                    <span>
                        {res?.data?.data[i]?.words} words
                        <span style={{ color: '#d76422', marginLeft: "80%" }}> £{res?.data?.data[i]?.price}</span>
                    </span>
                )
            })
        }
        setWords(array)
    }).catch((err) => {
        dispatch(createErrorAlert(400, '', 'Failed to fetch words', 400))
    })
  }

  useEffect(() => {
    handleFetchWords()
  },[])

  const handleSubmit = async () => {

    const formData = new FormData()
    formData.append("word_id", postObj.word_id)
    formData.append("file", files[0]?.file)
    formData.append("description", postObj.description)
    await apiRequest({
        method: "post",
        url: "content/confirm/indvidual/appointment/",
        data: formData
    }).then((res) => {
        window.location.href = res?.data?.checkout_url
    }).catch((err) => {
        console.log(err, "error")
    })
  }


  const onDrop = (filesNow) => {
    let alreadyUploaded = false;
    let uploadedFiles = [...files];
    for (let i = 0; i < filesNow.length; i++) {
      const file = {
        name: filesNow[i]?.name,
        file: filesNow[i],
        preview: URL.createObjectURL(filesNow[i]),
      };
      uploadedFiles.forEach((elem) => {
        if (elem.name === filesNow[i]?.name) {
          alreadyUploaded = true;
        }
      });
      if (!alreadyUploaded) {
        uploadedFiles.push(file);
      }
    }

    setFiles([...uploadedFiles]);
  };

  const deleteFile = (name) => {
    let uploadedFiles = [...files];

    uploadedFiles.forEach((elem, index) => {
      if (elem.name === name) {
        uploadedFiles.splice(index, 1);
      }
    });

    setFiles([...uploadedFiles]);
  };
  return (
    <div
      className="  my-10 px-4
        md:px-6
        lg:px-8
        custom:px-56
        custom2:px-56
        xl:px-60
        w-full sm-md:w-80% "
    >
      <p className="text-primary text-h3">
        REVIEW AND FEEDBACK ON A COURSEWORK
      </p>
      <p className="fw-semibold my-5">
        Please indicate the length of your coursework, as the price depends on
        it.
      </p>
      <div className="w-full">
        <Select
          placeholder="Select how many words your coursework is"
          values={words}
          handleSelected={handleSelectedItem}
        />
      </div>
      <p className=" fw-semibold my-5">Please upload your coursework Here</p>

      <div className="flex flex-row items-center justify-center  flex-wrap w-full">
        <Dropzone onDrop={onDrop} accept="application/pdf">
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragReject,
            isDragAccept,
          }) => (
            <div
              className="w-full py-10"
              style={{
                borderColor: isDragReject
                  ? "red"
                  : isDragAccept
                  ? "green"
                  : "#B4B4B4",
              }}
            >
              <div
                {...getRootProps()}
                className="dropzoneInner cursor-pointer flex flex-col justify-center p-7 items-center"
              >
                <input {...getInputProps()} className="dropzoneInner" />
                <img alt="upload" src={UploadIcon} />
                <p className="text-black font-normal text-14 text-center pt-8">
                  {!isDragActive
                    ? "Upload lectures, your notes, reading materials"
                    : "Drop them like they are hot potatoes"}
                </p>
                <p className="text-darkGrey2 font-normal text-14 text-center pt-1">
                  Drop or choose a file from your computer
                </p>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      <div className="mt-10 px-6">
        {files.map((item, index) => (
          <div
            className="flex flex-row justify-between items-center mt-6"
            key={index}
          >
            <p className="text-darkest font-normal text-16 md:text-body-large">
              {item?.name}
            </p>
            <img
              className="cursor-pointer"
              onClick={() => deleteFile(item?.name)}
              src={RemoveIcon}
              alt="remove"
            />
          </div>
        ))}
      </div>
      <p className=" fw-semibold mb-5 ">
        Kindly provide any details that might assist the tutor in giving better
        feedback
      </p>

      <textarea
        rows={8}
        className="w-full p-5 my-5 rounded-2xl"
        onChange={(e) => 
            setPostObj((prev) => ({
                ...prev,
                description: e.target.value
            }))
        }
        placeholder="Please provide a brief description of the Uploaded Coursework"
      ></textarea>
      <p className="my-5">
        <strong>Note: </strong>
        Please consider that we may take up to{" "}
        <span className="text-primary">2 working days</span> to review your work
        and deliver feedback. It will be{" "}
        <span className="text-primary">
          sent to you by email. Please check your spam folder too.
        </span>
      </p>

      <div className="w-150 h-fit my-3">
        <Button className="confrmBtn" disabled={files.length === 0 || postObj.description.length === 0} filled onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Review;
