export const GET_TUTOR_SESSION_REQUEST = 'tutorSessionReducer/GET_TUTOR_SESSION_REQUEST';
export const GET_TUTOR_SESSION_SUCCESS = 'tutorSessionReducer/GET_TUTOR_SESSION_SUCCESS';
export const GET_TUTOR_SESSION_FAILED = 'tutorSessionReducer/GET_TUTOR_SESSION_FAILED';

export const CREATE_TUTOR_GROUP_SESSION_REQUEST = 'CREATE_TUTOR_GROUP_SESSION_REQUEST';
export const CREATE_TUTOR_GROUP_SESSION_SUCCESS = 'CREATE_TUTOR_GROUP_SESSION_SUCCESS';
export const CREATE_TUTOR_GROUP_SESSION_FAILED = 'CREATE_TUTOR_GROUP_SESSION_FAILED';

export const CREATE_TUTOR_ONE_TO_ONE_SESSION_REQUEST = 'CREATE_TUTOR_ONE_TO_ONE_SESSION_REQUEST';
export const CREATE_TUTOR_ONE_TO_ONE_SESSION_SUCCESS = 'CREATE_TUTOR_ONE_TO_ONE_SESSION_SUCCESS';
export const CREATE_TUTOR_ONE_TO_ONE_SESSION_FAILED = 'CREATE_TUTOR_ONE_TO_ONE_SESSION_FAILED';