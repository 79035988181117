import * as t from '../types';
import {
    POST_TUTOR_SESSION_ONE_FAILED,
    POST_TUTOR_SESSION_ONE_REQUEST,
    POST_TUTOR_SESSION_ONE_SUCCESS,
    UPDATE_TUTOR_GROUP_REQUEST
} from "../types";

const initialState = {
    data: '',
    loading: false,
    error: ''
};

export default function tutorSessionUpdateReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.UPDATE_TUTOR_GROUP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case t.UPDATE_TUTOR_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            };
        case t.UPDATE_TUTOR_GROUP_FAILED:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}
