
const INITIAL_STATE = {
    showAlert: false,
    title: '',
    message: '',
    status: '',
};

export default function errorAlertReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 403: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 401: {
            window.history.replaceState('', action.title, window.location.href);
            // window.location.replace(`${window.location.href}`);
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 0: {
            window.history.replaceState('', '', window.location.href);
            // window.location.assign(`${window.location.href}`);
            // removeKey();
            // removeUser();
            return {...state, showAlert: false, title: '', message: '', status: ''};
            
        }
        case 400: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 2: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 200: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 201: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 203: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 204: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 209: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 100: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 101: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 211: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 700: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        case 800: {
            return {...state, showAlert: true, title: action.title, message: action.message, status: action.status};
        }
        default: {
            return {...state, showAlert: false, title: action.title, message: action.message, status: action.status};
        }
    }
}