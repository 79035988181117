import * as t from '../types';
import { GetMainPdf} from '../../services'

export const getMainPdf = () => async (dispatch) => {
    dispatch({
        type: t.GET_MAIN_PDF_REQUEST
    });

    return await GetMainPdf()
        .then((data) => {
            dispatch({
                type: t.GET_MAIN_PDF_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_MAIN_PDF_FAILED,
                payload: err.response
            });
        });
};
