import * as t from '../types';
import {GetDegrees} from '../../services'

export const getDegrees = (university) => async (dispatch) => {
    dispatch({
        type: t.GET_DEGREES_REQUEST
    });

    return await GetDegrees(university)
        .then((data) => {
            dispatch({
                type: t.GET_DEGREES_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_DEGREES_FAILED,
                payload: err.response
            });
        });
};
