export default function convertSlugToText(text) {
  if (text !== undefined) {
    let slug = text.split("-");
    for (let i = 0; i < slug.length; i++) {
      let word = slug[i];
      slug[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return slug.join(" ");
  }
}
