import React from "react";
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);



const DateRangeSelect = ({ onChange, maxDate, dateRange }) => {
  return (
    <DateRangePicker
      onSelect={onChange}
      showSelectionPreview={true}
      minDate={new Date(maxDate)}
      color="#c8631e"
      rangeColors={["#c8631e"]}
      closeOnFirstDateSelect={false}
    />
  );
};

export default DateRangeSelect;
