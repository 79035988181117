import React from 'react'

import StatueImage from '../../assets/images/shopping-statue (2).webp'
import CloseModal from '../../assets/images/closeModal.webp'

const ShoppingCard = ({ title, image, university, classNumber, price, currency, onDelete }) => {
    return (
        <div className='flex flex-col md:flex-row md:justify-between md:items-center h-full lg:h-36 mac:h-40 overflow-hidden bg-white pr-10' style={{ borderRadius: '25px', boxShadow: ' 4px 8px 20px rgba(2, 23, 54, 0.03)' }}>
            <div className='flex h-full'>
                <div className='hidden md:block w-56 min-h-full'>
                    <img src={image} alt='Statue' className='h-full w-full' />
                </div>
                <div className=' ml-7 flex justify-center items-center'>
                    <p className='text-body-large mac:text-h5 font-bold text-darkest'>{title}</p>
                    </div>
            </div>
            <div className='ml-7 mb-5 md:mb-0 md:ml-0 flex items-center relative'>
                <p className='text-primary text-body-large mac:text-h5 font-bold md:mr-14 mac:mr-20'>{currency?currency:"£"} {price}</p>
                <img src={CloseModal} alt='Close' onClick={onDelete} className='cursor-pointer absolute -right-8 bottom-10 md:relative md:right-0 md:bottom-0'/>
            </div>
        </div>
    )
}

export default ShoppingCard