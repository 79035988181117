import React from 'react'

const MainContainer = ({ title, subtitle, content, additionalContent, customSubtitle }) => {
    return (
        <div className="flex flex-col w-full custom2:w-2/3 pr-1.5 " >
            <p className="font-bold text-h4 custom2:text-36 custom2:pl-12">
                {title}
            </p>
            {subtitle && <p className="text-15 mt-0.5 text-darkGrey mb-11 custom2:pl-12">
                {subtitle}
            </p>}
            {customSubtitle && <>{customSubtitle}</>}

            <div className="bg-white " style={{ borderRadius: '25px' }}>
                <div className="space-y-3.5 max-h-573 overflow-y-auto px-4 custom2:px-12 " id='session-container' >
                    {content}
                </div>
                {additionalContent}
            </div>
        </div>
    )
}

export default MainContainer
