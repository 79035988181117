import React, {useEffect, useState} from "react";
import { getApiKey, getUser } from "../../helpers";
import "./index.css";

let count = 0

const CheckBoxQuestion = ({title, answers, checkSelect, checkSelectMulti, multiAnswers, question_id ,option_id, quiz_id, correct, statement, statement_title, type, statementId, quiz_id_multi, quiz_question_id, paidModule, studyModule}) => {
    const [answersList, setAnswerList] = useState(answers);
    const [statements, setStatements] = useState()
    const [correctAns, setCorrectAns] = useState();
    const [multiList, setMultiList] = useState(multiAnswers)
    const [statementIds, setStatementIds] = useState()
    const [statementQuestion, setStatementQuestion] = useState('')
    const user = JSON.parse(getUser())

    useEffect(() => {
        if (answers) {
            setAnswerList(answers)
        } else {
            setStatements(statement)
        }
        if (correct.length !== 0) {
            for (let i = 0; i < correct.length; i++) {
                if (correct[i].is_correct === true) {
                    setCorrectAns(correct[i].option)
                }
            }
        }
        if(statement){
            setStatementIds(statementId)
        }
    }, [])

    const handleChangeMulti = (checked, value, title) => {
        for(let j=0; j<multiAnswers.length; j++){
            if(multiAnswers[j].statement === value){
                checkSelectMulti(checked, value, multiAnswers[j].question_statement, statementIds, quiz_id_multi, quiz_question_id)
            }
        }
        let list = [...multiAnswers]

        for (let i = 0; i < list.length; i++) {
            if (value === list[i].option && checked === true) {
                list[i].is_correct = true;
                if (list[i].is_correct === true && list[i].option === correctAns) {
                    count++
                }
            } else {
                list[i].is_correct = false;
            }
        }
        setMultiList(list);
    }

    const handleChange = (checked, value) => {
        let list = [...answers];

        for (let i = 0; i < list.length; i++) {
            if (value === list[i].option && checked === true) {
                list[i].is_correct = true;
                if (list[i].is_correct === true && list[i].option === correctAns) {
                    count++
                }
            } else {
                list[i].is_correct = false;
            }
        }
        setAnswerList(list);
    };

    const disableFunction = () => {
        if (
            !getApiKey() ||
            (getApiKey() &&
              (user.subscription_name === "Basic Subscription" || user?.subscription_name.includes('STARTER')) &&
              user?.free_trial === false &&
              user?.user_type !== "TEACHER" && !paidModule?.includes(studyModule))
          ){
            return true
          } else{
            return false
          }
    }

    return (<div>
        <p className="text-h5 md:text-h3 font-bold pt-2 md:pt-6 lg:pt-8">{title ? title : statement_title}</p>
        {answersList?.length > 0 ? answersList?.map((elem, index) => {// <SingleCheckbox
            return(
                <div className="checkbox flex items-center  mt-2 md:mt-4" key={index}>
                    <input id={elem.option + `${'__'+question_id}`} type={type} className="input-assumpte"
                           value={elem.option.replace(/(<([^>]+)>)/ig, "").replace('&amp;', "") + `${'__'+question_id}`}
                           checked={null}
                           disabled={disableFunction()}
                           name={type === 'checkbox' ? '' : title}
                           onChange={(e) => {
                               handleChange(e.target.checked, elem.option)
                               checkSelect(e.target.checked, elem.id, quiz_id, question_id, count, index)
                           }}/>
                    <label htmlFor={elem.option + `${'__'+question_id}`}
                           className="text-16 custom2:w-10/12 md:text-body-large flex items-center">{elem.option}</label>
                </div>
                )
        }) : <div className="checkbox flex items-center  mt-2 md:mt-4">
            <input id={statements} type="checkbox" className="input-assumpte"
                   value={statements}
                   checked={null}
                   onChange={(e) => {
                       handleChangeMulti(e.target.checked, statements,statementQuestion, statementIds)
                   }}/>
            <label htmlFor={statements}
                   className="text-16 custom2:w-10/12 md:text-body-large flex items-center">{statements}</label>
        </div>}
    </div>);
};

export default CheckBoxQuestion;
