import * as t from '../types';
import {SubmitReview} from '../../services'


export const submitContentModuleAction = (data) => async (dispatch) => {
    dispatch({
        type: t.SUBMIT_CONTENT_MODULE_REQUEST
    });

    return await SubmitReview(data)

        .then((data) => {
            dispatch({
                type: t.SUBMIT_CONTENT_MODULE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.SUBMIT_CONTENT_MODULE_FAILED,
                payload: err.response
            });
        });
};
