import React, {useState, useEffect} from 'react';
import './index.css';

import Container from '../../components/container';
import TutorSessionStudents from '../../components/TutorSiteComponents/tutorSessionsStudents';
import TutorSessionModal from '../../components/TutorSessionModal/tutorSessionModal';
import {
     tutorSchedulesData, tutorSchedulesError, tutorSchedulesLoading,
} from '../../redux/selectors';
import {getTutorSchedule} from '../../redux/actions';
import addIcon from '../../assets/images/addIcon.webp';
import {connect} from 'react-redux';

const TutorDashboard = (props) => {

    const {getTutorSchedule, data} = props

    useEffect(() => {
        getTutorSchedule()
    }, [])

    useEffect(() => {
        if (data) {
        }
    }, [data])

    const [modal, setModal] = useState(false);
    const [schedules, setSchedules] = useState([{
        id: 1, persons: 0, open: false, status: 'open',
    }, {
        id: 2, persons: 0, open: false, status: 'open',
    }, {
        id: 3, persons: 0, open: false, status: 'open',
    }, {
        id: 4, persons: 0, open: false, status: 'canceled',
    }, {
        id: 5, persons: 0, open: false, status: 'open',
    }, {
        id: 6, persons: 0, open: false, status: 'open',
    }]);

    const openSchedule = (id) => {
        const schedulesCopy = [...schedules];
        schedulesCopy.forEach(elem => {
            if (elem.id === id) {
                elem.open = true;
            }
        });
        setSchedules([...schedulesCopy]);
    };

    const closeSchedule = (id) => {
        const schedulesCopy = [...schedules];
        schedulesCopy.forEach(elem => {
            if (elem.id === id) {
                elem.open = false;
            }
        });
        setSchedules([...schedulesCopy]);
    };

    const togglePersons = (id, type) => {
        const schedulesCopy = [...schedules];
        const schedule = schedulesCopy.find(value => value.slug === id);

        if (type === 'enlarge') {
            schedule.persons = schedule.persons + 1;
        } else {
            if (!(schedule.persons === 0)) {
                schedule.persons = schedule.persons - 1;
            }
        }

        setSchedules([...schedulesCopy]);
    };

    const times = [{slot: '06:00'}, {slot: '07:00'}, {
        slot: '08:00'
    }, {slot: '09:00'}, {slot: '10:00'}, {slot: '11:00'}, {
        slot: '12:00'
    }];
    
    const [timeState, setTimeState] = useState([{
                day: 'Saturday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Sunday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Monday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Tuseday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Wednesday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Thursday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: false,
                }, {
                    id: 2, slot: '09:00 am', status: false,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }, {
                day: 'Friday', times: [{
                    id: 0, slot: '09:00 am', status: false,
                }, {
                    id: 1, slot: '09:00 am', status: true,
                }, {
                    id: 2, slot: '09:00 am', status: true,
                }, {
                    id: 3, slot: '09:00 am', status: false,
                }, {
                    id: 4, slot: '09:00 am', status: true,
                }, {
                    id: 5, slot: '09:00 am', status: false,
                }, {
                    id: 6, slot: '09:00 am', status: true,
                },
                
                ],
            }],
        );
    const handleChange = (day, value, checked) => {


        let data = [...timeState];
        data.map(add);
        
        function add(item) {
            if (item.day === day) {
                item.times.map(check);

                function check(subItem) {
                    if (subItem.id === value.id) {
                        subItem.status = checked;
                    }
                }
            }
        }
        
        setTimeState(data);
        
    };
    return (<div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
        <TutorSessionModal
            isOpen={modal}
            buttonOneText="Add to calendar"
            buttonTwoText="Finish"
            onClickFirstButton={() => setModal(false)}
            onClickSecondButton={() => setModal(false)}
            closeModal={() => setModal(false)}
            title="Congratulations!"/>
        <div className="">
            <Container>
                <div className="pt-10 lg:pt-14 flex mb-10">

                    <div className="text-darkest flex justify-between w-full">
                        <div><p className="tutorialVidText text-h3 custom2:text-36 font-bold mb-10 custom2:mb-12 ">Your Sessions</p>
                            <p className="text-body-large">Please scroll down and book the session you are
                                interested in.</p></div>
                        <div class="text-center">
                            <button onClick={() => setModal(true)} class="addSessBtn">
                                <img class="inline-block mr-2" src={addIcon}/>
                                Add Session
                            </button>
                        </div>
                    </div>
                </div>
                <>
                    <div className="table-responsive tutorTable">
                        <table className="table table-curved">
                            <tbody>
                            
                            {timeState.map((item) => (<tr>
                                <>
                                    <th>{item.day}</th>
                                    {item.times.map((subItem) => (
                                        <td>
                                            <label className="slotCheck">
                                                <input
                                                    type="checkbox"
                                                    value={subItem.id}
                                                    checked={subItem.status}
                                                    onChange={(e) => handleChange(item.day, subItem, e.target.checked)}
                                                />
                                                <span></span>
                                            </label>
                                        </td>
                                    ))}
                                </>
                            </tr>))}
                            
                            
                            </tbody>
                            <tfoot>
                            <tr>
                                <th></th>
                                {times.map((item) => (<th>{item.slot}</th>))}
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </>
                <div className="space-y-3.5 max-h-925 px-3.5" id="session-container">
                    {schedules.length ? schedules?.map(elem => (<TutorSessionStudents
                        status={elem.status}
                        open={elem.open}
                        numOfPersons={elem.persons}
                        data={elem}
                        date={props?.location?.state?.calendarDate}
                        openSchedule={() => openSchedule(elem.id)}
                        closeSchedule={() => closeSchedule(elem.id)}
                        enlargePersons={() => togglePersons(elem.id, 'enlarge')}
                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                        book={() => setModal(true)}/>)) : null}
                </div>
            </Container>

        </div>
    </div>);
};

const mapStateToProps = state => ({
    loading: tutorSchedulesLoading(state),
    error: tutorSchedulesError(state),
    data: tutorSchedulesData(state),
});
const mapDispatchToProps = {
    getTutorSchedule
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorDashboard);
