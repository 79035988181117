import React, {useState} from "react";
import Search from '../../assets/images/search.webp'
import {getNotes} from '../../redux/actions';


const SearchInput = ({
                         placeholder,
                         search,
                         text,
                         auth,
                         setValue,
                         value,
                         handleClick,
                     }) => {


    return (
        <div className='w-full flex relative h-12'>
            <input style={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderTopLeftRadius: '14px',
                borderTopRightRadius: '14px'
            }} className={`p-4 mr-0 ${auth ? 'bg-offWhite' : 'bg-white'} w-full border outline-none text-16`}
                   placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           handleClick()
                       }
                   }}
            />
            {search ? (
                <div
                    className={`${auth ? 'bg-offWhite' : 'bg-white'}  text-gray-800 -ml-5 w-70 flex items-center justify-center border border-primary`}
                    style={{borderRadius: '14px'}}>
                    <img src={Search} alt='Search' className='w-6' onClick={handleClick}/>
                </div>
            ) : (
                <div
                    className="bg-white  text-gray-800 -ml-5 flex items-center justify-center border border-primary bg-primary text-white text-16"
                    style={{borderRadius: '14px', width: '130px', cursor: 'pointer'}} onClick={handleClick}>
                    {text}
                </div>
            )}
        </div>
    );
};


export default SearchInput;
