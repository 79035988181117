import apiRequest from '../../helpers/apiRequest';

export default async (slug, title) => {
    let url = `content/quizzes/${slug}`;
    if (slug && title) {
        return await apiRequest({
            method: 'get',
            url: `content/question/${title}`,
            params: {page: title}
        });
    } else {
        return await apiRequest({
            method: 'get',
            url: url,
            params: {page: title}
        });
    }
    
}
