import * as t from '../types';
import {GetFlashcards} from '../../services'

export const getflashCards = (page,slug) => async (dispatch) => {
    dispatch({
        type: t.GET_FLASHCARDS_REQUEST
    });

    return await GetFlashcards(page,slug)
        .then((data) => {
            dispatch({
                type: t.GET_FLASHCARDS_SUCCESS,
                payload: data.data
            
            });
            
        })
        .catch((err) => {
            dispatch({
                type: t.GET_FLASHCARDS_FAILED,
                payload: err.response
            });
        });
};
