import * as t from '../types';
import {ChangePassword,ConfirmPass} from '../../services'
import {createErrorAlert} from "./errorAlertAction";


export const changePassword = (params) => async (dispatch) => {
    dispatch({
        type: t.CHANGE_PASSWORDS_REQUEST
    });

    return await ChangePassword(params)

        .then((data) => {
            dispatch({
                type: t.CHANGE_PASSWORDS_SUCCESS,
                payload: data
            });
            dispatch(
                createErrorAlert(200, "Success", "Your Password has been Updated Successfully", 200)
            );
        })
        .catch((err) => {
            dispatch({
                type: t.CHANGE_PASSWORDS_FAILED,
                payload: err.response
            });
        });
};
export const confirmResetPassword = (params) => async (dispatch) => {
    dispatch({
        type: t.CONFIRM_PASSWORDS_REQUEST
    });
    return await ConfirmPass(params)
        .then((data) => {
            dispatch({
                type: t.CONFIRM_PASSWORDS_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: t.CONFIRM_PASSWORDS_FAILED,
                payload: error.response.data
            });
            return error.response.data.detail
        });
};