import React, {useState} from 'react';
import './input.css';
import {useField} from 'formik';
import Eye from '../../assets/images/eye.webp';

const SearchInput = ({placeholder, type, autoComplete, isPassword, readOnly, ...props}) => {
    const [field, meta] = useField(props);
    return (
        <div>
            
            <div
                className={`${!(meta.touched && meta.error) ? 'text-input-container' : 'text-input-container-error'} ${props.inputType === 'password' && 'relative flex'}`}
            >
                <input {...props}
                       {...field}
                       onChange={props.onChange}
                       className="text-input focus:bg-offWhite focus:outline-none focus:border-none focus:ring-1 focus:ring-lightPrimary"
                       type={props.inputType === 'password' && isPassword ? 'text' : type}
                       autoComplete={autoComplete}
                       readOnly={readOnly}
                       placeholder={placeholder}/>
                {props.inputType === 'password' && <span className="eyeIconSec absolute top-3.5 right-5 cursor-pointer"
                                                         onClick={() => props.setShowPassword(!props.showPassword)}><img
                    src={Eye}/></span>}
            </div>
            {meta.touched && meta.error ? (
                <div className="formik-error">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default SearchInput;
