import React, {useState} from 'react'
import Button from '../../components/EmptyButton'
import {useHistory} from "react-router-dom";
import SignupImage1 from "../../assets/images/signupImage1.webp";
import SignupImage2 from "../../assets/images/signupImage2.webp";
import Stepper from "../../components/SignUpStepper"
import {connect, useDispatch} from "react-redux";
import {createErrorAlert, updateUser} from "../../redux/actions";
import { getUser } from '../../helpers';

const Card = ({title, image, margin, setValue}) => {

    return (
        <div
            className={`bg-white ${margin && "mt-10 custom2:mt-0 border-4 border-borderGreen"} flex flex-col p-5 md:px-24 md:py-10 items-center cursor-pointer rounded-3xl`}
            onClick={() => {
            if (title === 'Undergraduate degree') {
                setValue(0)
            } else {
                setValue(1)
            }
        }}>
            <img alt="img" src={image} className="w-200"/>
            <p className="mt-6 md:mt-9 text-darkest font-bold text-body-small md:text-h4 text-center">{title}</p>
        </div>
    )
}

const SignUpStepOne = (props) => {
    const {updateUser} = props;
    const history = useHistory()
    const [degree, setDegree] = useState(-1)
    const user = JSON.parse(getUser())
    const dispatch = useDispatch()
    const updateStepOne = () => {
        let obj = {
            study_level: parseInt(degree),
            username: user.username
        }
        if(degree === -1){
            dispatch(createErrorAlert(400, "", "Please select a Degree", 400))
        } else{
            updateUser(obj);
            history.push("/signup/step-two")
        }
    }
    return (
        <div className='bg-white bg-main-page-back w-full bg-no-repeat bg-cover'>
            <Stepper step={1} exit={() => history.push('/')} totalSteps={3}/>
            <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">What
                are you studying?</p>
            <div className="pb-16 custom2:pb-72">

                <div className="flex flex-col lg:flex-row items-center justify-center gap-14">

                    <Card title="Undergraduate degree" image={SignupImage1} setValue={setDegree} margin={degree === 0}/>
                    <Card title="Postgraduate degree" image={SignupImage2} setValue={setDegree} margin={degree === 1}/>
                </div>
                <div className='pt-4 flex justify-center mb-10 mt-12'>
                    <div className='w-full px-4 md:px-0 md:w-40 h-51'>
                        <Button onClick={updateStepOne} text='Continue' filled/></div>
                </div>
            </div>
        </div>

    )
}
const mapDispatchToProps = dispatch => ({
    updateUser: (body) => dispatch(updateUser(body)),
})
export default connect(null, mapDispatchToProps)(SignUpStepOne)
