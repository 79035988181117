import apiRequest from "../../helpers/apiRequest";

export default async (singleChoiceArray, multiChoiceArray, matchCaseArray, quiz_ids) => {
    return await apiRequest({
        method: 'post',
        url: `content/correct/answer/`,
        data: {
            quiz_id: quiz_ids,
            singleChoice: singleChoiceArray,  
            multiChoice: multiChoiceArray, 
            matchCase: matchCaseArray
        }
    })
}
