import React from 'react';

import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import FreeUsers from './FreeUsers';

import {slugToText} from '../../helpers';
import PaidUsersEssay from './PaidUsersEssay';

const PreviewEssay = ({match, location}) => {
    const slug1 = match.params.slug1;
    const slug2 = match.params.slug2;
    const breadCrumbItem = [{title: 'Get study modules', link: '/'}, {
        title: slugToText(slug1),
        link: `/study-module/${slug1}`,
    }, {title: 'Model Essays', link: `/${slug1}/model-essays`}];
    
    return (
        <div className="bg-white">
            <Container>
                {slug2 && !location?.state?.selected?.label ? <div className="hidden md:block md:pt-12  bd-show">
                    <Breadcrumb items={breadCrumbItem} current={slugToText(slug2)}/>
                </div> : null}
            
            </Container>
            {match.params.type === 'free' ? (
                <FreeUsers data={location?.state?.selected} slug={slug1}/>
            ) : (
                <PaidUsersEssay data={location?.state?.selected} type={'essay'} slug={slug2} slugModule={slug1}/>
            )}
        
        </div>
    );
};

export default PreviewEssay;
