import * as t from '../types';
import {MySessionsGroup} from '../../services'


export const mySessionsGroup = (slug, passedDate) => async (dispatch) => {

    dispatch({
        type: t.MY_SESSIONS_GROUP_REQUEST
    });

    return await MySessionsGroup(slug, passedDate)

        .then((data) => {
            dispatch({
                type: t.MY_SESSIONS_GROUP_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.MY_SESSIONS_GROUP_FAILED,
                payload: err.response
            });
        });
};
