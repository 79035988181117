import * as t from '../types';
import {ViewHistory} from '../../services'


export const viewHistoryAction = () => async (dispatch) => {
    dispatch({
        type: t.GET_VIEW_HISTORY_REQUEST
    });

    return await ViewHistory()

        .then((data) => {
            dispatch({
                type: t.GET_VIEW_HISTORY_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_VIEW_HISTORY_FAILED,
                payload: err.response
            });
            return err
        });
};
