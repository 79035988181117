import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-responsive-modal';
import {Field, Form, Formik} from 'formik';
import Input from '../Input/InputComponent';
import Button from '../EmptyButton';
import * as Yup from 'yup';
import {
    passwordError,
    passwordLoading,
    passwordMessage,
} from '../../redux/selectors';
import {changePassword} from '../../redux/actions';

const ResetUserPasswordModal = ({open, setOpen, error, loading, message, changePassword}) => {
    const [showNewPassword, setNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const validateSchema = Yup.object({
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        newPassword: Yup.string()
            .required('Password is required.')
            .min(7, 'Password is too short - should be 6+ chars minimum.')
            .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Password should contain Capital letter,Small letter and digits.'),
    });
    useEffect(() => {
        if (message) {
            setOpen(false);
        }
    }, [message]);
    const updatePassword = (values) => {
        changePassword(values);
    };
    return (
        <Modal open={open} onClose={() => setOpen(false)} focusTrapped={false} center
            //    classNames={{modal: 'xl:w-650'}}
            
            classNames={{
                overlay: 'custom-overlay-class',
               modal: 'react-modal-class2', 
              }}>
            <Formik
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => updatePassword(values)}
            >
                {({isValid, handleSubmit, values}) => (
                    <Form className='resetPswrdForm'>
                        <form
                            onSubmit={(e) =>
                                e.preventDefault()
                            }
                        >
                            <div className="flex flex-col text-darkest items-center">
                                <div className="w-full mb-3 md:mb-9 ">
                                    <p className="mb-8">
                                        New Password
                                    </p>
                                    <Field
                                        name="newPassword"
                                        as={Input}
                                        placeholder="Enter new password"
                                        type={showNewPassword ? 'text' : 'password'}
                                        setShowPassword={setNewPassword}
                                        showPassword={showNewPassword}
                                        inputType="password"
                                    />
                                </div>
                                <div className="w-full mb-3 md:mb-9 ">
                                    <p className="mb-8">
                                        Confirm New Password
                                    </p>
                                    <Field
                                        name="confirmPassword"
                                        as={Input}
                                        placeholder="Confirm your password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        setShowPassword={setShowConfirmPassword}
                                        showPassword={showConfirmPassword}
                                        inputType="password"
                                    />
                                </div>
                            </div>
                            <div>
                                <p style={{textAlign: 'center'}}>{message ? message.detail : null}</p>
                            </div>
                            <div className="w-full mt-2 md:mt-8 flex items-center justify-center">
                                <div className="efUpdateBtnSec w-full xl:w-450 mt-5 md:mt-0">
                                    <Button className="buttonClass" onClick={handleSubmit} text="Update" filled
                                            disabled={values.newPassword === '' || values.confirmPassword === '' || !isValid}
                                            loading={loading}/>
                                </div>
                            </div>
                        </form>
                    </Form>)}
            </Formik>
        </Modal>
    );
};
const mapStateToProps = state => ({
    
    message: passwordMessage(state),
    loading: passwordLoading(state),
    error: passwordError(state),
});
const mapDispatchToProps = dispatch => ({
    changePassword: body => dispatch(changePassword(body)),
    
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetUserPasswordModal);
