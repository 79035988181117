import * as t from '../types';
import allPlans from '../../services/Subscriptions/allPlans';


export const allPlanAction = () => async (dispatch) => {
    dispatch({
        type: t.GET_ALL_PLANS_REQUEST
    });

    return await allPlans()
        .then((data) => {
            dispatch({
                type: t.GET_ALL_PLANS_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_ALL_PLANS_FAILED,
                payload: err.response
            });
            return err.response
        });
};
