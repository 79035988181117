import React from 'react'

import ReactPaginate from 'react-paginate';

import Arrow from '../../assets/icons/arrow.svg'

const Pagination = ({ onPageChange, pageCount }) => {
    let activePage = JSON.parse(localStorage.getItem('pageData'))
    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel={<img src={Arrow} alt='arrow' className='transform rotate-180 pr-5 md:pr-10 cursor-pointer' />}
            onPageChange={onPageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            breakLinkClassName='font-bold mr-3'
            previousLabel={<img src={Arrow} alt='arrow' className='pr-5 md:pr-10 cursor-pointer' />}
            containerClassName='flex items-center'
            pageClassName='w-12 h-12 bg-white rounded-xl flex items-center justify-center mr-3 text-body-large hover:border-gray'
            activeLinkClassName='text-body-large linear-background h-full w-full rounded-xl flex items-center justify-center text-white'
            renderOnZeroPageCount={null}
            forcePage={activePage?.currentPage ? activePage?.currentPage : null}
        />
    )
}

export default Pagination
