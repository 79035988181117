import * as t from '../types';
import {CreateSlots} from '../../services'


export const createSlots = (body) => async (dispatch) => {

    dispatch({
        type: t.CREATE_SLOTS_REQUEST
    });

    return await CreateSlots(body)

        .then((data) => {
            dispatch({
                type: t.CREATE_SLOTS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_SLOTS_FAILED,
                payload: err.response
            });
        });
};
