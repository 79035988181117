import React from "react";
import Trophy from "../../assets/icons/trophy.svg";
import Essays from "../../assets/images/main-page-materials/essays.webp";
import notes from "../../assets/images/main-page-materials/notes.webp";
import platform from "../../assets/images/main-page-materials/platform.svg";
import quiz from "../../assets/images/main-page-materials/quiz.webp";
import tips from "../../assets/images/main-page-materials/tips.svg";
import StudyMaterial from "../../components/StudyMaterials";
import tutorials from "../../assets/images/main-page-materials/tutorials.webp";
import Button from "../../components/EmptyButton";
import Frame1 from "../../assets/images/subFrame1.png"
import Frame2 from "../../assets/images/subFrame2.png"
import { useHistory } from "react-router-dom";

const Step1Content = ({ handleNext }) => {
  const user = JSON.parse(localStorage.getItem("app_user"));
  const history = useHistory()
  const materials = [
    {
      id: 1,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Revision <br className="hidden xl:block" /> Notes
        </p>
      ),
      image: notes,
    },
    {
      id: 2,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Model <br className="hidden xl:block" /> Essays
        </p>
      ),
      image: Essays,
    },
    {
      id: 3,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Tutorial <br className="hidden xl:block" /> Videos
        </p>
      ),
      image: tutorials,
    },
    {
      id: 4,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Quizzes & Flashcards
        </p>
      ),
      image: quiz,
    },
    {
      id: 5,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Case Summaries
        </p>
      ),
      image: tips,
    },
    {
      id: 6,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Interactive Learning Platform{" "}
        </p>
      ),
      image: platform,
    },
  ];

  return (
    <div>
      <div className="flex flex-col sm-md:flex-row gap-5 justify-center ">
        <div className=" w-full sm-md:w-1/2 bg-white">
          <div className="flex justify-center ">
            <img src={Frame1}/>
          </div>
          <div className="mt-5">
            <p>
              {user?.first_name} we would be sorry if you had lost your access
              to our simple study materials made by First Class graduates from
              top UK universities to maximise your chances of getting a first
              class degree
            </p>
          </div>
        </div>
        <div className=" w-full sm-md:w-1/2">
          <div className="flex justify-center">
            <img src={Frame2}/>
          </div>
          <div className="mt-5">
            <p>
              If you cancel your premium subscription to SimpleStudying, You
              would be losing access to all the study materials below that are
              handpicked, top quality materials prepared by First Class law
              graduates to teach you how to get a First-Class law degree
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 justify-items-center mt-14 lg:flex lg:justify-between">
        {materials.map((elem) => (
          <div className="w-116 xl:w-44">
            <StudyMaterial img={elem.image} alt={elem.name}>
              {elem.name}
            </StudyMaterial>
          </div>
        ))}
      </div>
      <div className="flex flex-col sm-md:flex-row justify-between px-26 pb-6  sm-md:space-x-10 md:space-x-24">
        <Button onClick={() => {history.push('/')}} className='mb-5 mt-5 sm-md:mt-0'>
          Keep Subscription
        </Button>
        <Button
          onClick={handleNext}
        >
          Request Cancellation
        </Button>
      </div>
      <p className="text-center">
        Note: You could cancel later. You will be reminded 7 days before each
        subscription renewal.{" "}
      </p>
    </div>
  );
};
export default Step1Content;
