import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import the default styles
import { apiRequest } from '../../helpers';

function Calendly({ handleDateChange, tutorHolidays, selectedTutor }) {
  const [date, setDate] = useState();
  const [disableDates, setDisbaleDates] = useState(null)

  useEffect(() => {
    const dateButton = document.getElementsByClassName('react-calendar__tile react-calendar__month-view__days__day')
    for(let i=0; i<dateButton?.length; i++) {
      if(dateButton[i].disabled === true){
        dateButton[i].style.cursor = "not-allowed"
      }
    }
  },[tutorHolidays])

  useEffect(() => {
    getUnavailableSlots()
  },[selectedTutor])

  const getUnavailableSlots = async () => {
    await apiRequest({
      method: 'get',
      url: `accounts/unavailable-dates/${selectedTutor.name}/`
    }).then((res) => {
      setDisbaleDates(res?.data?.unavailable_slots)
    })
  }

  return (
    <div className='reactCalender'>
      <Calendar 
        onChange={handleDateChange} 
        value={date} 
        minDate={new Date()} 
        maxDate={new Date('2025-12-31')}
        tileDisabled={({ date, view }) => {
          if (view === 'month') {
            const formattedDate = date.toISOString().split('T')[0];
  
            // Check if disabledDates is an array and not null
            const isDisabled = Array.isArray(disableDates) && disableDates.includes(formattedDate);
  
            // Check against tutorHolidays if needed
            const isHolidayDisabled = Array.isArray(tutorHolidays) && tutorHolidays.length > 0 && 
              tutorHolidays.some(disabledDate => disabledDate.date === formattedDate);
  
            return isDisabled || isHolidayDisabled;
          }
          return false;
        }}
      />
    </div>
  );
}

export default Calendly;
