import React, { useState, useEffect } from 'react'
import Page from '../../components/PlanYourStudyModule/Page'
import NotesAdditionalContent from './NotesAdditionalContent'
import Modal from '../../components/Modal/UploadDocument'
import ModalName from '../../components/Modal/DocumentName'
import NotesContent from './NotesContent'
import ModelEssaysPage from './ModelEssaysPage'
import PricingPage from './PricingPage'
import PicturePage from './PicturePage'
const CustomPricingSubtitle = () => {
    return (<div className="text-darkest  mt-3.5 custom2:pl-12 relative z-0 custom2:text-h5 text-16">
        <p className="font-bold ">Our recommendation: </p>
        <p>Recommended price for First Class notes and Model essays: £15 -£30 depending on how many model essays are offered.Adding tutorial videos can increase the price. Maximum price should not be more than £35

        </p>
    </div>)
}

const CustomPictureSubtitle = () => {
    return (<div className="text-darkest custom2:text-h5 text-16 mt-3.5 custom2:pl-12 relative z-0 mb-10">

        <p>Please upload a picture to be assigned to the study module.
            We recommend a picture related to the study module you are uploading.
        </p>
    </div>)
}


const PlanYourStudyModule = () => {
    const [indexActive, setIndexActive] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isNameModalOpen, setIsNameModalOpen] = useState(false)
    useEffect(() => {
        if (isModalOpen || isNameModalOpen) {
            document.body.style.overflowY = "hidden"
            document.body.style.position = "fixed"
        }
        else if (!isModalOpen && !isNameModalOpen) {
            document.body.style.overflowY = "unset"
            document.body.style.position = "unset"
        }

    }, [isModalOpen, isNameModalOpen])

    const modules = [{ index: 1, title: "Notes", subtitle: "You can upload documents in .pdf format", content: <NotesContent />, additionalContent: <NotesAdditionalContent setIsModalOpen={setIsModalOpen} /> }, { index: 2, title: "Model essays/Problem questions", subtitle: "You can upload documents in .pdf format", content: <ModelEssaysPage />, additionalContent: <NotesAdditionalContent setIsModalOpen={setIsModalOpen} /> },
    { index: 3, title: "Pricing", customSubtitle: <CustomPricingSubtitle />, content: <PricingPage />, additionalContent: "" }, { index: 4, title: "Picture", customSubtitle: <CustomPictureSubtitle />, content: <PicturePage />, additionalContent: "" }]
    return (
        <div>
            <Modal isOpen={isModalOpen}
                buttonOneText='Upload'
                onClickFirstButton={() => {
                    setIsModalOpen(false)
                    setIsNameModalOpen(true)
                }}
                closeModal={() => setIsModalOpen(false)}
                title='Upload document' />
            <ModalName isOpen={isNameModalOpen}
                buttonOneText='OK'
                onClickFirstButton={() => setIsNameModalOpen(false)}
                closeModal={() => setIsNameModalOpen(false)}
                title='Please enter document name' />
            <Page indexActive={indexActive} setIndexActive={setIndexActive} title={modules[indexActive - 1].title} subtitle={modules[indexActive - 1]?.subtitle} customSubtitle={modules[indexActive - 1]?.customSubtitle} content={modules[indexActive - 1].content} additionalContent={modules[indexActive - 1].additionalContent} />

        </div >
    )
}

export default PlanYourStudyModule

