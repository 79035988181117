const INITIAL_STATE = {
    universities: [],
    selectedUniversity: null
}

export default function universityReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "ADD_UNIVERSITIES": {
            return { ...state, universities: action.payload }
        }
        case "REMOVE_UNIVERSITIES": {
            return { ...state, universities: null }
        }
        case "ADD_CSRF": {
            return { ...state, csrf: action.payload }
        }
        case "REMOVE_CSRF": {
            return { ...state, csrf: null }
        }
        default: {
            return { ...state }
        }
    }
}