import * as t from "../types";
import { CouponVerify } from "../../services";
import { createErrorAlert } from "./errorAlertAction";

export const couponVerifyAction = (obj) => async (dispatch) => {

  dispatch({
    type: t.POST_COUPON_VERIFY_REQUEST,
  });

  return await CouponVerify(obj)
    .then((data) => {
      dispatch(
        createErrorAlert(
          204,
          "Thank You",
          "Your Email has been saved as a Content Creator",
          204
        )
      );
      dispatch({
        type: t.POST_COUPON_VERIFY_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: t.POST_COUPON_VERIFY_FAILED,
        payload: err.response,
      });
    });
};
