export const  CREATE_NOTE_REQUEST='createNoteReducer/CREATE_NOTE_REQUEST';
export const  CREATE_NOTE_SUCCESS='createNoteReducer/CREATE_NOTE_SUCCESS';
export const  CREATE_NOTE_FAILED='createNoteReducer/CREATE_NOTE_FAILED';

export const  CREATE_ESSAY_REQUEST='createEssayReducer/CREATE_ESSAY_REQUEST';
export const  CREATE_ESSAY_SUCCESS='createEssayReducer/CREATE_ESSAY_SUCCESS';
export const  CREATE_ESSAY_FAILED='createEssayReducer/CREATE_ESSAY_FAILED';

export const  CREATE_MODULE_REQUEST='createModuleReducer/CREATE_MODULE_REQUEST';
export const  CREATE_MODULE_SUCCESS='createModuleReducer/CREATE_MODULE_SUCCESS';
export const  CREATE_MODULE_FAILED='createModuleReducer/CREATE_MODULE_FAILED';

export const  GET_CONTENT_ESSAY_REQUEST='getContentEssayReducer/GET_CONTENT_ESSAY_REQUEST';
export const  GET_CONTENT_ESSAY_SUCCESS='getContentEssayReducer/GET_CONTENT_ESSAY_SUCCESS';
export const  GET_CONTENT_ESSAY_FAILED='getContentEssayReducer/GET_CONTENT_ESSAY_FAILED';

export const  GET_CONTENT_NOTE_REQUEST='getContentNoteReducer/GET_CONTENT_NOTE_REQUEST';
export const  GET_CONTENT_NOTE_SUCCESS='getContentNoteReducer/GET_CONTENT_NOTE_SUCCESS';
export const  GET_CONTENT_NOTE_FAILED='getContentNoteReducer/GET_CONTENT_NOTE_FAILED';

export const  DELETE_CONTENT_NOTE_REQUEST='deleteContentNoteReducer/DELETE_CONTENT_NOTE_REQUEST';
export const  DELETE_CONTENT_NOTE_SUCCESS='deleteContentNoteReducer/DELETE_CONTENT_NOTE_SUCCESS';
export const  DELETE_CONTENT_NOTE_FAILED='deleteContentNoteReducer/DELETE_CONTENT_NOTE_FAILED';

export const  DELETE_CONTENT_ESSAY_REQUEST='deleteContentEssayReducer/DELETE_CONTENT_ESSAY_REQUEST';
export const  DELETE_CONTENT_ESSAY_SUCCESS='deleteContentEssayReducer/DELETE_CONTENT_ESSAY_SUCCESS';
export const  DELETE_CONTENT_ESSAY_FAILED='deleteContentEssayReducer/DELETE_CONTENT_ESSAY_FAILED';

export const  DELETE_CONTENT_MODULE_REQUEST='deleteContentModuleReducer/DELETE_CONTENT_MODULE_REQUEST';
export const  DELETE_CONTENT_MODULE_SUCCESS='deleteContentModuleReducer/DELETE_CONTENT_MODULE_SUCCESS';
export const  DELETE_CONTENT_MODULE_FAILED='deleteContentModuleReducer/DELETE_CONTENT_MODULE_FAILED';

export const  UPDATE_CONTENT_NOTE_REQUEST='updateContentNoteReducer/UPDATE_CONTENT_NOTE_REQUEST';
export const  UPDATE_CONTENT_NOTE_SUCCESS='updateContentNoteReducer/UPDATE_CONTENT_NOTE_SUCCESS';
export const  UPDATE_CONTENT_NOTE_FAILED='updateContentNoteReducer/UPDATE_CONTENT_NOTE_FAILED';

export const  UPDATE_CONTENT_ESSAY_REQUEST='updateContentEssayReducer/UPDATE_CONTENT_ESSAY_REQUEST';
export const  UPDATE_CONTENT_ESSAY_SUCCESS='updateContentEssayReducer/UPDATE_CONTENT_ESSAY_SUCCESS';
export const  UPDATE_CONTENT_ESSAY_FAILED='updateContentEssayReducer/UPDATE_CONTENT_ESSAY_FAILED';

export const  UPDATE_CONTENT_MODULE_REQUEST='updateContentModuleReducer/UPDATE_CONTENT_MODULE_REQUEST';
export const  UPDATE_CONTENT_MODULE_SUCCESS='updateContentModuleReducer/UPDATE_CONTENT_MODULE_SUCCESS';
export const  UPDATE_CONTENT_MODULE_FAILED='updateContentModuleReducer/UPDATE_CONTENT_MODULE_FAILED';


export const  SUBMIT_CONTENT_MODULE_REQUEST='submitContentModuleReducer/SUBMIT_CONTENT_MODULE_REQUEST';
export const  SUBMIT_CONTENT_MODULE_SUCCESS='submitContentModuleReducer/SUBMIT_CONTENT_MODULE_SUCCESS';
export const  SUBMIT_CONTENT_MODULE_FAILED='submitContentModuleReducer/SUBMIT_CONTENT_MODULE_FAILED';