import {combineReducers} from 'redux';

import errorAlertReducer from './errorAlertReducer';
import universityReducer from './universityReducer';
import {userReducer, updateUserReducer} from './userReducer';

import universitiesReducer from './universitiesReducer';
import notesReducer from './notesReducer';
import degreeReducer from './degreesReducer';
import caseSummariesReducer from './caseSummariesReducer';
import tutorialVideosReducer from './tutorialVideosReducer';
import essaysReducer from './essaysReducer';
import subscriptionReducer from './subscriptionReducer';
import flashCardsReducer from './flashCardsReducer';
import catalogueReducer from './catalogueReducer';
import {studyModulesReducer, specificReducer} from './studyModulesReducer';
import {groupAppointmentReducer} from './appointmentReducer';
import libraryReducer from './libraryReducer';
import quizReducer from './quizReducer';
import siteSearchReducer from './siteSearchReducer';
import packageReducer from './packageReducer';
import tutorReducer from './tutorReducer'
import {
    tutorSessionReducer,
    tutorCreateGroupSessionReducer,
    tutorCreateOneToOneSessionReducer
} from './tutorSessionReducer'
import tutorSessionOne2OneUpdateReducer from './tutorSessionOne2OneUpdateReducer'
import {passwordReducer, passwordConfirmReducer} from './passwordReducer';
import {signupReducer, loginReducer, loginGoogleReducer, loginFacebookReducer} from './authenticationReducer';
import tutoringReducer from './tutoringReducer';
import tutorAvailabilityReducer from "./tutorAvailabilityReducer";
import tutorGroupSessionReducer from "./tutorGroupSessionReducer";
import tutorScheduleReducer from "./tutorScheduleReducer";
import userInSessionsReducer from "./userInSessionsReducer";
import createSlotsReducer from "./createSlotsReducer";
import tutorOne2OneReducer from "./tutorOne2OneReducer";
import groupParticipantsReducer from "./groupParticipantsReducer";
import groupSessionTutorReducer from "./getGroupSessionReducer";
import tutorSessionPostOneReducer from "./tutorSessionPostOneReducer";
import tutorSessionUpdateReducer from "./tutorSessionUpdateReducer";
import mySessionsGroupReducer from "./mySessionsGroupReducer";
import mySessionsOneToOneReducer from "./mySessionsOneToOneReducer";
import getHolidaysReducer from "./getHolidaysReducer";
import postHolidaysReducer from "./postHolidaysReducer";
import deleteHolidaysReducer from "./deleteHolidaysReducer";
import teamReducer from './teamReducer';
import partnersReducer from './partnersReducer';
import quizQuestionReducer from './quizQuestionReducer'
import quizResultsReducer from "./quizResultsReducer";
import privacyReducer from './privacyReducer';
import termsConditionsReducer from './termsConditionsReducer';
import cookiesReducer from './cookiesReducer';
import getGradesReducer from './gradesReducer';
import checkListReducer from './checkListReducer';
import mainPdfReducer from './mainPdfReducer';
import postMailChimpReducer from './postMailChimpReducer';
import cancelSubscriptionReducer from './cancelSubscriptionReducer';
import allPlanReducer from './allPlanReducer';
import contactUsReducer from './contactUsReducer';
import checkTimeReducer from './checkTimeReducer';
import contentEmailReducer from './contentEmailReducer';
import cancelAppointmentReducer from './cancelAppointmentReducer';
import confirmAppointmentReducer from './confirmAppointmentReducer';
import subscribedPackageReducer from './subscribedPackageReducer';
import couponVerifyReducer from './couponVerifyReducer';
import unAvailableTutorReducer from './universitiesReducer';
import viewHistoryReducer from './viewHistoryReducer';
import dynamicReducer from './dynamicReducer';
import contentTermsReducer from './contentTermsReducer';
import createNoteReducer from './createNoteReducer';
import createEssayReducer from './createEssayReducer';
import createModuleReducer from './createModuleReducer';
import getContentEssayReducer from './getContentEssayReducer';
import getContentNoteReducer from './getContentNoteReducer';
import deleteContentNoteReducer from './deleteContentNoteReducer'
import deleteContentEssayReducer from './deleteContentEssayReducer';
import deleteContentModuleReducer from './deleteContentModuleReducer';
import updateContentEssayReducer from './updateContentEssayReducer';
import updateContentModuleReducer from './updateContentModuleReducer';
import submitContentModuleReducer from './submitContentModuleReducer';

export default combineReducers({
    errorAlertReducer,
    universityReducer,
    userReducer,
    updateUserReducer,
    universitiesReducer,
    notesReducer,
    degreeReducer,
    caseSummariesReducer,
    tutorialVideosReducer,
    essaysReducer,
    subscriptionReducer,
    flashCardsReducer,
    catalogueReducer,
    studyModulesReducer,
    specificReducer,
    libraryReducer,
    passwordReducer,
    passwordConfirmReducer,
    siteSearchReducer,
    signupReducer,
    loginReducer,
    loginGoogleReducer,
    loginFacebookReducer,
    quizReducer,
    tutoringReducer,
    packageReducer,
    tutorReducer,
    tutorSessionReducer,
    tutorAvailabilityReducer,
    tutorGroupSessionReducer,
    tutorScheduleReducer,
    userInSessionsReducer,
    groupAppointmentReducer,
    tutorCreateGroupSessionReducer,
    tutorCreateOneToOneSessionReducer,
    createSlotsReducer,
    tutorOne2OneReducer,
    groupParticipantsReducer,
    groupSessionTutorReducer,
    tutorSessionPostOneReducer,
    tutorSessionUpdateReducer,
    mySessionsGroupReducer,
    mySessionsOneToOneReducer,
    getHolidaysReducer,
    postHolidaysReducer,
    deleteHolidaysReducer,
    tutorSessionOne2OneUpdateReducer,
    teamReducer,
    partnersReducer,
    quizQuestionReducer,
    quizResultsReducer,
    privacyReducer,
    termsConditionsReducer,
    cookiesReducer,
    getGradesReducer,
    checkListReducer,
    mainPdfReducer,
    postMailChimpReducer,
    cancelSubscriptionReducer,
    allPlanReducer,
    contactUsReducer,
    checkTimeReducer,
    contentEmailReducer,
    cancelAppointmentReducer,
    confirmAppointmentReducer,
    subscribedPackageReducer,
    couponVerifyReducer,
    unAvailableTutorReducer,
    viewHistoryReducer,
    dynamicReducer,
    contentTermsReducer,
    createNoteReducer,
    createEssayReducer,
    createModuleReducer,
    getContentEssayReducer,
    getContentNoteReducer,
    deleteContentNoteReducer,
    deleteContentEssayReducer,
    deleteContentModuleReducer,
    updateContentEssayReducer,
    updateContentModuleReducer,
    submitContentModuleReducer
});
