import React, {useEffect} from 'react'
import {Helmet} from "react-helmet";

const StudyTips = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0});
    },[])
    return (
        <div className=" my-4 sm:my-6 md:my-10 bg-white" style={{scrollBehavior: 'smooth'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Study Tips</title>
                <meta name="description" content="Study Tips Description"/>
                <link rel="canonical" href="https://simplestudying.com/study-tips"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Study Tips"/>
                <meta property="og:description" content="Study Tips Description"/>
                <meta property="og:url" content="https://simplestudying.com/study-tips"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                <div className="tipsHeadText text-large-small font-bold text-darkest md:text-large-medium">
                    <h2>Study Tips</h2>
                    <br/>
                </div>
                <p className='policyPara'>When undertaking legal studies, it is important to possess the ability to
                    organise
                    and plan your
                    studying in an efficient way which maximizes one’s ability to achieve desirable results. Good
                    organisation and hard work is key to succeeding at the university level. However, if these are not
                    your strong points then do not be alarmed as we are here to teach you and give you some useful study
                    tips based on previous experiences.
                    <br/>
                    <br/>

                    Usually, you are advised to read all the essential and additional reading at the law school which
                    could amount to 300 pages a week. This can be very challenging due to the complicated legal jargon
                    and language used in these books and the vast amount of time necessary to comprehend the material
                    read.
                    <br/>
                    <br/>

                    Since this task is very hard to complete if not impossible, we have decided to simplify your work
                    and do all the reading for you. We have a dedicated team of successful law students and graduates
                    who have completed all the required reading and created excellent summary of all the important
                    information you need to know in the simplest terms. After reading our materials, for additional
                    reading you could go through few recommended articles or reading important judgments in full if you
                    have some time. Also it is very important that you read your lecture slides. Since grading is
                    subjective, you need to understand what your professor’s requirements are from your lectures.
                    <br/>
                    <br/>
                    Moreover, it is very important to know exam techniques and generally being able to identify the
                    relevant law, apply it to the real life scenario and provide analysis. Please refer to our exam
                    tips, model essays, tutorial videos and quizzes to learn application of law in the questions. Also,
                    you can visit our chatroom to discuss different problem questions with your fellow law students from
                    UK universities or abroad.
                    <br/>
                    <br/>
                    Check out Professor Kappes’ scientifically proven psychological methods for achieving academic
                    success here.
                    <br/>
                    <br/>
                    To sum up, best way to study is to have:
                    <br/>
                    <br/>
                    <p style={{padding: '0 20px'}}>
                        <ul style={{listStyle: 'disc'}} className='policyPara'>
                            <li>
                                Condensed, simplified materials to learn core information;
                            </li>
                            <li>
                                Practicing exercises to learn application of law to facts;
                            </li>
                            <li>
                                Visual learning materials to memorise cases and legal principles;
                            </li>
                            <li>
                                Interactive learning platform to practice with other students.
                            </li>
                        </ul>
                    </p>
                    <br/>
                    We covered it for you! Please register here to become a premium member for free.
                </p>
            </div>
        </div>
    );
}

export default StudyTips