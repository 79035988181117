import React, { useEffect, useState } from 'react';
import Context from './Context';
const ContextProvider = ({ children }) => {
    const storedValue = JSON.parse(localStorage.getItem('myContextValue')) || null;
    const [value, setValue] = useState(storedValue || null);
    useEffect(() => {
      localStorage.setItem('myContextValue', JSON.stringify(value));
    }, [value]);
    const updateValue = (newValue) => {
      setValue(newValue);
    };
  return (
    <Context.Provider value={{ value, updateValue }}>
      {children}
    </Context.Provider>
  );
};
export default ContextProvider;