import { apiRequest } from ".";

export default function fetchNotes(finalSlug, studyModuleRes, setState) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiRequest({
                method: "get",
                url: `content/notes/search/?notes=${finalSlug}`,
            });

            setState((prev) => ({
                ...prev,
                loadingState: false
            }))

            for (let i = 0; i < studyModuleRes.length; i++) {

                if (parseInt(res?.data[0]?.study_module.id) === parseInt(studyModuleRes[i].id)) {
                    if (res?.data[0]?.slug === finalSlug) {
                        if (res.data[0]?.content !== '') {
                            setState((prev) => ({
                                ...prev,
                                documentFull: false
                            }))
                        }
                        setState((prev) => ({
                            ...prev,
                            studyModuleState: studyModuleRes[i].title,
                            openModule: studyModuleRes[i]
                        }))
                        resolve(res);
                    }
                }
            }

            resolve(null);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}