/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import moment from 'moment'
import Left from '../../assets/images/leftCalendar.png'
import Right from '../../assets/images/rightCalendar.png'
const CalendarToolbar = (toolbar) => {
    const goToBack = () => {
        toolbar.onNavigate('PREV')
    }
    const goToNext = () => {
        toolbar.onNavigate('NEXT')
    }
    const goToCurrent = () => {
        toolbar.onNavigate('TODAY')
    }

    const label = () => {
        const date = moment(toolbar.date)
        return (
            <span>
                <span>{date.format('DD')} </span>
                <span>{date.format('MMMM')} </span>
                <span> {date.format('YYYY')}</span>
            </span>
        )
    }

    return (
        <div className='big-calendar-header mb-30'>

            <div className='flex justify-center items-center '>
                <div>

                    <button
                        type='button'
                        className='btn calendar-prev-btn mr-1'
                        onClick={goToBack}>
                        <img src={Right} alt="right arrow" />
                    </button>
                    <label className="font-bold text-15 text-darkGrey mx-5 " >{label()}</label>
                    <button
                        type='button'
                        className='btn calendar-next-btn'
                        onClick={goToNext}>
                        <img src={Left} alt="left arrow" />
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CalendarToolbar
