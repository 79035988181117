// SearchInput.js
import React, { useEffect, useState } from "react";
import './input.css';
import Eye from '../../assets/images/eye.webp';

const SearchInput = ({ placeholder, value, setValue, type, autoComplete, isPassword, readonly, reschedule, freeDate, stateKey }) => {
  const [show, setShow] = useState(!isPassword);
  const [maxDate, setMaxDate] = useState();

  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    if (month < 10) {
      month = '0' + month.toString();
    }
    if (day < 10) {
      day = '0' + day.toString();
    }

    let dates;
    if (reschedule === true && freeDate !== true) {
      dates = year + '-' + month + '-' + (day + 1);
    } else if (reschedule === true && freeDate === true) {
      dates = year + '-' + month + '-' + (day + 3);
    } else {
      dates = year + '-' + month + '-' + day;
    }

    setMaxDate(dates);
  }, []);

  return (
    <div className={`text-input-container ${isPassword && "relative flex"}`}>
      <input
        className="text-input focus:bg-offWhite focus:border-none"
        type={show && isPassword ? 'text' : type}
        autoComplete={autoComplete}
        value={value}
        id={'dateInput'}
        readOnly={readonly}
        min={maxDate}
        onChange={(e) => stateKey !== undefined ? setValue((prevValue) => ({ ...prevValue, [stateKey]: e.target.value })) : setValue(e.target.value)}
        placeholder={placeholder}
      />
      {isPassword && (
        <span className="absolute top-3.5 right-5 cursor-pointer" onClick={() => setShow(!show)}>
          <img src={Eye} alt="Toggle Password Visibility" />
        </span>
      )}
    </div>
  );
};

export default SearchInput;
