import * as t from '../types';
import {DeleteContentModule} from '../../services'


export const deleteContentModuleAction = (id) => async (dispatch) => {
    dispatch({
        type: t.DELETE_CONTENT_MODULE_REQUEST
    });

    return await DeleteContentModule(id)

        .then((data) => {
            dispatch({
                type: t.DELETE_CONTENT_MODULE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.DELETE_CONTENT_MODULE_FAILED,
                payload: err.response
            });
        });
};
