import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { slugToText } from "../../helpers";
import { FaVolumeUp, FaVolumeDown, FaVolumeMute } from "react-icons/fa";
import logo from "../../assets/brand/small_logo.png";
import { MdOutlineRepeat, MdOutlineRepeatOne } from "react-icons/md";

const AudioPlayer = ({ audioState }) => {
  const [audio] = useState(new Audio(audioState));
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [repeatAudio, setRepeatAudio] = useState(false);
  const [showVolumeRange, setShowVolumeRange] = useState(false);

  const params = useParams();

  useEffect(() => {
    const progressBar = document.querySelector(".progress");

    const updateProgressBar = () => {
      progressBar.style.width =
        (audio.currentTime / audio.duration) * 100 + "%";
      setCurrentTime(audio.currentTime);
      if (audio.currentTime === audio.duration) {
        let audioPlayer = document.getElementById("audioPlayerId");
        let image = document.getElementById("image");
        image.classList.remove("imgRotate");
        audioPlayer.style.animation = "none";
        setIsPlaying(false);
      }
    };

    const handleLoadedData = () => {
      const timeLength = getTimeCodeFromNum(audio.duration);
      document.querySelector(".time .length").textContent = timeLength;
      setDuration(audio.duration);
      audio.volume = volume;
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const intervalId = setInterval(updateProgressBar, 500);

    audio.addEventListener("loadeddata", handleLoadedData);
    audio.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      clearInterval(intervalId);
      audio.removeEventListener("loadeddata", handleLoadedData);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [audio, volume]);

  const handleTimelineClick = (e) => {
    const timelineWidth = window.getComputedStyle(
      document.querySelector(".timeline")
    ).width;
    const timeToSeek =
      (e.nativeEvent.offsetX / parseInt(timelineWidth)) * audio.duration;
    audio.currentTime = timeToSeek;
  };

  const handleVolumeSliderClick = (e) => {
    const sliderWidth = window.getComputedStyle(
      document.querySelector(".volume-slider")
    ).width;
    const newVolume = e.nativeEvent.offsetX / parseInt(sliderWidth);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  const handleVolumeSliderChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  const handleVolumeAnimation = () => {
    const range = document.getElementById("range");
    range.classList.toggle("rangeAnimate");
  };

  const handlePlayPauseClick = () => {
    let audioPlayer = document.getElementById("audioPlayerId");
    if (audio.paused) {
      setIsPlaying(true);
      audioPlayer.style.animation =
        "cssAnimation_0 3000ms infinite ease-in-out 2393ms";
      audio.play();
    } else {
      setIsPlaying(false);
      audio.pause();
      audioPlayer.style.animation = "none";
    }
  };

  const handleVolumeButtonClick = () => {
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
    setDisabled(!disabled);
  };
  const handleVolumeButtonHover = (isHovered) => {
    setShowVolumeRange(isHovered);
  };

  const handleLoop = () => {
    setRepeatAudio(!repeatAudio);
    audio.loop = !audio.loop;
  };

  return (
    <div>
      <div className="audioPlayerBorder audioBack" id="audioPlayerId">
        <div className="flex justify-center items-center my-3 relative">
          <div className="non-rotating-img">
            <img src={logo} alt="Logo" id="image" />
          </div>
          <div
            className="flex justify-center items-center my-3 rotating-div"
            style={{
              width: "100px",
              height: "100px",
              background: "white",
              borderRadius: "50px",
              borderTop: "1px solid #c86313",
              borderBottom: "1px solid #c86313",

              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              animation: "rotateAnimation 2s infinite linear",
            }}
          ></div>
        </div>
        <div className="nameAudio">{slugToText(params.slug2)}</div>
        <div className="audio-player">
          <div className="timeline" onClick={(e) => handleTimelineClick(e)}>
            <div className="progress"></div>
          </div>
          <div className="controls">
            <div className="flex justify-start gap-2">
              <div className="play-container">
                <div
                  className={`toggle-play ${isPlaying ? "pause" : "play"}`}
                  onClick={() => handlePlayPauseClick()}
                ></div>
              </div>
              <div className="time">
                <div className="current">{getTimeCodeFromNum(currentTime)}</div>
                <div className="divider">/</div>
                <div className="length">{getTimeCodeFromNum(duration)}</div>
              </div>
              <div className="loopIcon cursor-pointer">
                {repeatAudio === false ? (
                  <MdOutlineRepeat
                    onClick={() => handleLoop()}
                    style={{ fontSize: "22px" }}
                  />
                ) : (
                  <MdOutlineRepeatOne
                    onClick={() => handleLoop()}
                    style={{ fontSize: "22px" }}
                  />
                )}
              </div>
            </div>

            <div className="volume-container">
              {showVolumeRange && (
                <div
                  id="range"
                  className={`slide-in ${showVolumeRange ? "show" : ""}`}
                  style={{
                    transition: "opacity 5s ease-in-out",
                    opacity: showVolumeRange ? 1 : 0,
                  }}
                >
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    style={{ accentColor: "#fff" }}
                    disabled={disabled}
                    value={volume}
                    onChange={(e) => handleVolumeSliderChange(e)}
                    onMouseEnter={() => handleVolumeButtonHover(true)}
                    onMouseLeave={() => handleVolumeButtonHover(false)}
                  />
                </div>
              )}
              <div className="volume-button">
                <div
                  onClick={handleVolumeButtonClick}
                  onMouseEnter={() => handleVolumeButtonHover(true)}
                  onMouseLeave={() => handleVolumeButtonHover(false)}
                >
                  {volume <= 0.5 && volume > 0 && !disabled ? (
                    <FaVolumeDown className="volumeIcon" />
                  ) : volume > 0.5 && !disabled ? (
                    <FaVolumeUp className="volumeIcon" />
                  ) : (
                    (disabled === true || volume === 0) && (
                      <FaVolumeMute className="volumeIcon" />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Function to convert seconds into time format
function getTimeCodeFromNum(num) {
  let seconds = parseInt(num);
  let minutes = parseInt(seconds / 60);
  seconds -= minutes * 60;
  const hours = parseInt(minutes / 60);
  minutes -= hours * 60;

  if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
  return `${String(hours).padStart(2, 0)}:${minutes}:${String(
    seconds % 60
  ).padStart(2, 0)}`;
}

export default AudioPlayer;