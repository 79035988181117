import * as t from '../types';
import {GetSiteSearch} from '../../services/'


export const getSiteSearch = (text) => async (dispatch) => {
    dispatch({
        type: t.GET_SITE_SEARCH_REQUEST
    });

    return await GetSiteSearch(text)
        .then((data) => {
            dispatch({
                type: t.GET_SITE_SEARCH_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_SITE_SEARCH_FAILED,
                payload: err.response
            });
        });
};
