import React from 'react'
import { Modal } from 'react-responsive-modal';
// import './modal.css'
import Button from '../EmptyButton'
import Success from '../../assets/images/success.png'


const ShoppingSucessModal = ({ isOpen, closeModal, title, buttonTwoText, onClickSecondButton }) => {
    return (
        <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center 
        // classNames={{modal: 'xl:w-650'}}
        classNames={{
            overlay: 'custom-overlay-class',
           modal: 'react-modal-class2', 
          }}
        >
            <div className='flex justify-center mb-10'>
                <img src={Success} alt='success'/>
            </div>
    
            <p className="text-darkest text-large md:text-h5 font-bold mb-5 text-center">{title}</p>
            <p className='text-center text-15 text-darkest'>Your payment was successful, and your order is complete.</p>
            <div className="w-full mt-4 md:mt-12 flex items-center justify-center">
                <div className="w-full xl:w-450 mt-5 md:mt-0">
                    <Button filled onClick={onClickSecondButton}>
                        <p className='text-center text-14 xl:text-body-large'>{buttonTwoText}</p>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ShoppingSucessModal
