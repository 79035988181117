import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getApiKey } from "../../helpers";

// handle the public routes
function PrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				getApiKey() ? <Component {...props} /> : <Redirect to={"/"} />
			}
		/>
	);
}

export default PrivateRoute;
