import React, { useState, useEffect } from 'react'

import Container from '../../components/container'
import Header from './Header'
import MainContainer from './MainContainer'
import Stepper from './Stepper'

const Page = ({ indexActive, setIndexActive, title, subtitle, content, additionalContent, customSubtitle }) => {
    return (
        <div className='bg-white bg-subscription-bottom bg-bottom bg-no-repeat'>
            <Header />
            <Container>
                <div className="mt-50 pl-3  text-body-large flex flex-col custom2:flex-row custom2:space-x-7  text-darkest">
                    <Stepper indexActive={indexActive} setIndexActive={setIndexActive} />
                    <MainContainer title={title} subtitle={subtitle} content={content} additionalContent={additionalContent} customSubtitle={customSubtitle} />
                </div>
            </Container>
        </div>
    )
}

export default Page
