import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import './index.css'
import UploadIcon from '../../assets/images/uploadDocumentIcon.png'
import DeleteIcon from '../../assets/images/deleteImageIcon.webp'
export const PicturePage = () => {
    const [image, setImage] = useState(null)

    const isImage = (name) => name.substring(0, name.indexOf('/')) === 'image'

    const onDrop = (files) => {
        let lastImage = null
        for (let i = files.length - 1; i >= 0; i--) {
            if (isImage(files[i].type)) {
                if (lastImage == null) {
                    lastImage = {
                        file: files[i],
                        preview: URL.createObjectURL(files[i]),
                    }
                }
            }
        }
        if (lastImage != null) {
            setImage({ ...lastImage })
        }
    }


    const deleteFile = (name) => {
        setImage(null)
    }

    return (
        <div className="flex flex-row items-center justify-center  flex-wrap w-full mb-10">

            {image ?

                <div className="selectedImage">
                    <img className="object-cover selectedImage" src={image.preview} alt="uploaded image" />
                    <img onClick={() => deleteFile()} className="w-5 h-auto absolute top-8 right-8 cursor-pointer" src={DeleteIcon} alt="delete" />
                </div>
                :
                <Dropzone
                    onDrop={onDrop}
                    accept='image/*'>
                    {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragReject,
                        isDragAccept,
                    }) => (
                        <div
                            className='dropzoneImageSection'
                            style={{
                                borderColor: isDragReject
                                    ? 'red'
                                    : isDragAccept
                                        ? 'green'
                                        : '#B4B4B4',
                            }}>
                            <div
                                {...getRootProps()}
                                className='dropzoneImageInner cursor-pointer flex flex-col justify-center items-center'>
                                <input
                                    {...getInputProps()}
                                    className='dropzoneInner'
                                />
                                <img alt="upload" src={UploadIcon} />

                                {!isDragActive
                                    ? <div className="p-6">
                                        <p className="text-darkGrey2 font-normal text-15 md:text-16 custom2:text-body-large text-center pt-8">Drop your picture here</p>
                                        <p className="text-darkGrey2 font-normal text-15 md:text-16 custom2:text-body-large text-center pt-3">or</p>
                                        <p className="text-primary font-bold cursor-pointer text-15 md:text-16 custom2:text-body-large text-center pt-5">Select a Picture from our Library</p>
                                    </div>
                                    : <p className="text-darkGrey2 font-normal text-15 md:text-16 custom2:text-body-large text-center pt-8">'Drop them like they are hot potatoes</p>}

                            </div>
                        </div>
                    )}
                </Dropzone>
            }


        </div>
    )
}

export default PicturePage