import React, { useState } from 'react'
import MoreIcon from '../../assets/images/moreIcon.png'
import EyeIcon from '../../assets/images/eyeIcon.webp'
import EditIcon from '../../assets/images/editIcon.webp'
import MoreInfoBox from '../../components/InfoBox/MoreInfobox'
import InfoBox from '../../components/InfoBox/ExtraSmallInfobox'

const Document = ({ title }) => {
    const [showEdit, setShowEdit] = useState(false)
    const [showPreview, setShowPreview] = useState(false)
    return (
        <div className="flex flex-row text-15 md:text-16 custom2:text-body-large items-center justify-between mt-3.5 md:mt-0 w-full md:w-2/4 pb-3 md:pb-6">
            <p>{title}</p>
            <div className="flex flex-row">
                <div onMouseEnter={() => setShowEdit(true)}
                    onMouseLeave={() => setShowEdit(false)} className="relative cursor-pointer">
                    <img src={EditIcon} alt="edit" />
                    {showEdit && (
                        <div className='absolute z-10 -top-8 md:-top-12 -right-14'>
                            <InfoBox
                                text='Edit document name' />
                        </div>)}
                </div>
                <div onMouseEnter={() => setShowPreview(true)}
                    onMouseLeave={() => setShowPreview(false)} className="relative cursor-pointer">
                    <img className="ml-7" src={EyeIcon} alt="preview" />
                    {showPreview && (
                        <div className='absolute z-10 -top-8 md:-top-12 -right-4 '>
                            <InfoBox
                                text='Allow preview' />
                        </div>)}
                </div>
            </div>
        </div>
    )
}

const Item = ({ documents, index, title }) => {
    const [showMore, setShowMore] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    return (
        <div className="flex flex-col pb-6">
            <div className="md:pb-6 flex flex-row justify-between items-center" key={index}>
                <p onClick={() => setShowMore(!showMore)} className="text-darkest text-16 md:text-body-large custom2:text-h5 font-bold  cursor-pointer">
                    {title}
                </p>
                <div onMouseEnter={() => setShowOptions(true)}
                    onMouseLeave={() => setShowOptions(false)} className="relative">
                    <img className="cursor-pointer" src={MoreIcon} alt="more" />
                    {showOptions && (
                        <div className='absolute z-10 -right-2 custom2:-right-10'>
                            <MoreInfoBox
                                text1='Edit name'
                                text2="Download content" />
                        </div>)}

                </div>

            </div>

            {
                showMore ? <div className="pl-5">
                    {documents.map((item, index) => <Document title={item?.name} key={index} />)}
                </div> : null
            }


        </div >
    )
}

const NotesContent = () => {
    const [notes, setNotes] = useState([{ title: 'Offer', documents: [{ name: 'test.pdf' }, { name: 'test1.pdf' }, { name: 'test2.pdf' }] }, { title: 'Acceptance', documents: [{ name: 'test3.pdf' }, { name: 'test4.pdf' }, { name: 'test5.pdf' }] }, { title: 'Consideration', documents: [{ name: 'test.pdf' }, { name: 'test1.pdf' }, { name: 'test2.pdf' }] }, { title: 'Intention to create legal relations', documents: [{ name: 'test.pdf' }, { name: 'test1.pdf' }, { name: 'test2.pdf' }] }, { title: 'Exemption Clauses', documents: [{ name: 'test56.pdf' }] }, { title: 'Misrepresentation', documents: [{ name: 'test.pdf' }, { name: 'test1.pdf' }, { name: 'test2.pdf' }] }, { title: 'Remedies', documents: [{ name: 'test.pdf' }, { name: 'test1.pdf' }, { name: 'test2.pdf' }] },])

    return (
        <>
            {notes.map((item, index) => <Item title={item?.title} index={index} documents={item?.documents} />
            )}

        </>
    )
}

export default NotesContent
