import apiRequest from "../../helpers/apiRequest";


export default async (page, search,slug) => {
    if(search === null){
        return await apiRequest({
            method: 'get',
            url: `content/essay/${slug}`,
            params: {page: page, title: search}
        })
    } else{
        return await apiRequest({
            method: 'get',
            url: `content/essay/${slug}`,
            params: {page: page, title: search}
        })
    }
}
