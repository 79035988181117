import React from 'react'
import './index.css'
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

const StudyCard = ({image, altText, price, cardTitle, item, cardDescription, classNum}) => {
    const history = useHistory()

    const handleSelect = (item) => {
        history.push({
            pathname: `/study-module/${item.slug}`,
            state: { item },
          });
    }

    return (
        <div className=' bg-white study-card-container ' onClick={() => handleSelect(item.study_module)}>
            <div className='w-full md:h-44 xl:h-48'>
                <img src={image} alt={altText} className='w-full h-full'/>
            </div>
            <p className='p-2 text-h4 font-bold text-darkest title'>{cardTitle}</p>
            {/* <p className='text-body-large text-darkGrey desc p-2'>{cardDescription}</p> */}

            <div className='card-description-number p-2'>
                <p className='text-primary'>{price}</p>
                <p className='text-body-large text-greenGradient font-bold mb-1 class'>{classNum && classNum}</p>
            </div>
            
        </div>
    )
}

StudyCard.propTypes = {
    altText: PropTypes.string,
    cardTitle: PropTypes.string,
    cardDescription: PropTypes.string,
    classNum: PropTypes.string
  };

export default StudyCard