import * as t from '../types';
import {CreateNote} from '../../services'


export const createNoteAction = (formData) => async (dispatch) => {
    dispatch({
        type: t.CREATE_NOTE_REQUEST
    });

    return await CreateNote(formData)

        .then((data) => {
            dispatch({
                type: t.CREATE_NOTE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_NOTE_FAILED,
                payload: err.response
            });
        });
};
