export const  GET_HOLIDAYS_REQUEST='getHolidaysReducer/GET_HOLIDAYS_REQUEST';
export const  GET_HOLIDAYS_SUCCESS='getHolidaysReducer/GET_HOLIDAYS_SUCCESS';
export const  GET_HOLIDAYS_FAILED='getHolidaysReducer/GET_HOLIDAYS_FAILED';


export const  POST_HOLIDAYS_REQUEST='postHolidaysReducer/POST_HOLIDAYS_REQUEST';
export const  POST_HOLIDAYS_SUCCESS='postHolidaysReducer/POST_HOLIDAYS_SUCCESS';
export const  POST_HOLIDAYS_FAILED='postHolidaysReducer/POST_HOLIDAYS_FAILED';


export const  DELETE_HOLIDAYS_REQUEST='deleteHolidaysReducer/DELETE_HOLIDAYS_REQUEST';
export const  DELETE_HOLIDAYS_SUCCESS='deleteHolidaysReducer/DELETE_HOLIDAYS_SUCCESS';
export const  DELETE_HOLIDAYS_FAILED='deleteHolidaysReducer/DELETE_HOLIDAYS_FAILED';
