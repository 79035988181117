import * as t from '../types';
import {GetOne2OnePackage} from '../../services'


export const getTutorOne2One = (slug) => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_ONE_2_ONE_REQUEST
    });

    return await GetOne2OnePackage(slug)

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_ONE_2_ONE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_ONE_2_ONE_FAILED,
                payload: err.response
            });
        });
};
