import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getApiKey} from "../../helpers";

// handle the private routes
function PublicRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => !getApiKey() ? <Component {...props} /> : <Redirect to={'/login'}/>}
        />
    )
}

export default PublicRoute;
