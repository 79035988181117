import * as t from '../types';
import {GetTutorAvailablity} from '../../services'


export const getTutorAvailability = (slug, passedDate) => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_AVAILABILITY_REQUEST
    });

    return await GetTutorAvailablity(slug, passedDate)

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_AVAILABILITY_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_AVAILABILITY_FAILED,
                payload: err.response
            });
            return err.response.data
        });
};
