import React, { useState } from 'react'
import { Modal } from 'react-responsive-modal';
import './modal.css'

import Input from "../Input/InputForm";
import Button from '../EmptyButton'
const CustomModal = ({ isOpen, closeModal, title, buttonOneText, onClickFirstButton }) => {
    const [documentName, setDocumentName] = useState('')

    return (
        <Modal open={isOpen} onClose={closeModal} focusTrapped={false} center>
            <p className="text-darkest text-center text-largevtext-h5 md:text-h4 font-bold mb-12">{title}</p>
            <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
                <div className="w-10/12 " >

                    <Input type="text" placeholder="Enter document name" value={documentName} setValue={setDocumentName} />

                </div>
                <div className="w-36 mt-5 md:mt-12">
                    <Button disabled={documentName === '' && !documentName} filled onClick={onClickFirstButton}>
                        <p className='text-center text-14 xl:text-body-large'>{buttonOneText}</p>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CustomModal
