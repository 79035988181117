import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/container";
import Header from "../../components/PlanYourStudyModule/Header";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";
import AddIcon from "../../assets/images/addIcon.webp";
import StudyCard from "../../components/StudyCard/DashboardCard";
import { apiRequest, getUser } from "../../helpers";
import FullLoader from "../../components/Loader/FullLoader";
import Context from "../../services/Context";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import Slider from "../../components/MainAdminSlider";

const ContentCreatorDashboard = () => {
  const history = useHistory();

  const [state, setState] = useState({
    user: JSON.parse(getUser()),
    modules: [],
    loading: false,
    key: ["degree-selected", "content-module", "studyModuleId"],
  });

  const { value, updateValue } = useContext(Context);
  const userCurrency = value?.userCurrency || state.user?.user_currency;

  useEffect(() => {
    console.log(value, "value");
    handleGetModules();
    state.key.forEach(function (key) {
      localStorage.removeItem(key);
    });
  }, []);

  const handleGetModules = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    if (value?.dashboardModules) {
      let array = [];
      value?.dashboardModules?.map((item) => {
        if (
          item.slug !== "macroeconomics" &&
          item.slug !== "microeconomics" &&
          item.slug !== "business" &&
          item.slug !== "marketing" &&
          item.slug !== "our-company" &&
          item.slug !== "useful-articles" &&
          item.slug !== "uncategorized" &&
          item.slug !== "test" &&
          item.slug !== "partners"
        ) {
          if (item.author === state.user.author) {
            array.push(item);
          }
        }
      });
      setState((prev) => ({
        ...prev,
        modules: array,
        loading: false,
      }));
    } else {
      await apiRequest({
        method: "get",
        url: "studies/dashboard/studymodules",
      }).then((res) => {
        if (res.status === 200) {
          updateValue((prev) => ({
            ...prev,
            dashboardModules: res.data,
          }));
          let array = [];
          value?.dashboardModules?.map((item) => {
            if (
              item.slug !== "macroeconomics" &&
              item.slug !== "microeconomics" &&
              item.slug !== "business" &&
              item.slug !== "marketing" &&
              item.slug !== "our-company" &&
              item.slug !== "useful-articles" &&
              item.slug !== "uncategorized" &&
              item.slug !== "test" &&
              item.slug !== "partners"
            ) {
              if (item.author === state.user.author) {
                array.push(item);
              }
            }
          });
          setState((prev) => ({
            ...prev,
            modules: array,
            loading: false,
          }));
        }
      });
    }
  };

  const slugName = (selected) => {
    if (selected.value) {
      localStorage.setItem("study-module", selected.value.slug);
      localStorage.setItem("study-module_id", selected.value.id);
    } else {
      localStorage.setItem("study-module", selected.slug);
      localStorage.setItem("study-module_id", selected.id);
    }
    history.push({
      pathname: `/study-module/${selected.slug}`,
      state: { selected },
    });
  };

  const handleWhatsapp = () => {
    window.open("https://chat.whatsapp.com/IJ6MNDyfXx85omZc1JEAdC", "_blank");
  };

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      <div>
        <Container>
          <div className="pt-10 lg:pt-14 flex flex-row items-center">
            <p className="w-full text-center text-h5 md:text-h4 custom2:text-36 font-bold">
              Your content creator dashboard
            </p>
          </div>
          <div className="grid grid-cols-2 space-x-20">
            <div className="w-full">
              <div className="pb-4 md:pb-14 xl:pb-20 mt-20 w-full cursor-pointer">
                {state.modules?.length === 0 ? (
                  <div>
                    <p>You don't have any published modules</p>
                  </div>
                ) : (
                  <Slider
                    responsive={{
                      superLargeDesktop: {
                        autoPlay: true,
                        breakpoint: { max: 4000, min: 1920 },
                        items: 4,
                      },
                      desktop: {
                        breakpoint: { max: 1919, min: 1500 },
                        items: 2,
                      },
                      ipadPro: {
                        breakpoint: { max: 1501, min: 1201 },
                        items: 2,
                      },
                      tablet: {
                        breakpoint: { max: 993, min: 1199 },
                        items: 2,
                      },
                      tabletBelow: {
                        breakpoint: { max: 991, min: 768 },
                        items: 1,
                      },
                      mobile: {
                        breakpoint: { max: 767, min: 575 },
                        items: 1,
                      },
                    }}
                  >
                    {state?.modules.map((item, index) => (
                      <div className="space-x-4">
                        <StudyMaterials
                          key={index}
                          image={item?.image}
                          cardTitle={item?.title}
                          slug={item}
                          text={item?.short_description}
                          price={""}
                          handleClick={() => slugName(item)}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
              </div>

              <div
                className="px-4 custom2:px-12 flex flex-row pb-0 pt-16 md:py-16 items-center cursor-pointer justify-center "
                onClick={() => {
                  history.push("/content-creator/step-one");
                }}
              >
                <img className="w-3.5 h-auto" src={AddIcon} alt="add" />
                <span className="font-bold text-16 md:text-body-large text-primary ml-16">
                  Add more content
                </span>
              </div>
              <div className="py-20">
                <div className="flex justify-center items-center md:flex-row">
                  <div className="w-200 h-12 mb-10 md:mb-0">
                    <Button
                      filled
                      onClick={() =>
                        history.push({ pathname: "/content-receipts" })
                      }
                    >
                      <p className="text-center text-14 xl:text-body-large">
                        Check your earnings
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-20">
              <div>
                <p className="text-primary text-20 font-bold">
                  Share Your Content
                </p>
                <p>Copy the link to your content</p>
                <br />
                <p>
                  Share the link across your social media accounts and groups
                  where your potential customers hang out.
                </p>
                <br />
                <p>
                  Find and join relevant student groups on social media and
                  share your link regularly. One-time sharing isn't enough. You
                  should post every week for best results.
                </p>
                <br />
                <p className="text-primary text-20 font-bold">
                  Step 2: Build Your Personal Brand
                </p>
                <br />
                <p>
                  Creating a personal brand is the best way to skyrocket your
                  sales. Start by creating YouTube, TikTok, and LinkedIn
                  accounts. Aim to post 2-3 reels every day, each 60-90 seconds
                  long. In these reels, teach the subjects you're publishing
                  here. Include the link to the relevant study module in the
                  description so viewers can easily click and purchase your
                  content. When customers see your videos first, they are much
                  more likely to purchase your content. You will also be able to
                  sell tutoring sessions to your social media followers once you
                  build up significant viewership.
                </p>
                <br />
                <p className="text-primary text-20 font-bold">
                  Implement these two steps and watch your sales soar!
                </p>
              </div>
              <div className="py-20">
                <div className="flex flex-col md:flex-row">
                  <div className=" h-12 mb-10 md:mb-0">
                    <Button filled onClick={() => handleWhatsapp()}>
                      <p className="text-center text-14 xl:text-body-large">
                        Join the community of content creators
                      </p>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ContentCreatorDashboard;
