import * as t from '../types';
import {GetTutorSchedules} from '../../services'


export const getTutorSchedule = () => async (dispatch) => {

    dispatch({
        type: t.GET_TUTOR_SCHEDULE_REQUEST
    });

    return await GetTutorSchedules()

        .then((data) => {
            dispatch({
                type: t.GET_TUTOR_SCHEDULE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_TUTOR_SCHEDULE_FAILED,
                payload: err.response
            });
        });
};
