/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import FreeUsers from './FreeUsers';
import Container from '../../components/container';
import Breadcrumb from '../../components/BreadCrumb';
import {getApiKey, slugToText} from "../../helpers";
import PaidUserNotes from './PaidUserNotes';

const PreviewNotes = ({match, location}) => {
    const slug1 = match.params.slug1;
    const slug2 = match.params.slug2;

    const breadCrumbItem = [{title: 'Get study modules', link: '/'}, {
        title: slugToText(slug1),
        link: `/study-module/${slug1}`,
    }, {title: 'Notes', link: `/${slug1}/notes`}];

    return (
        <div className="bg-white  pl-[100px] pr-[100px]   " style={{paddingLeft: '100px', paddingRight: '100px'}}>
            <Container>
                {getApiKey() &&slug2 && !location?.state?.selected?.label ? <div className="hidden md:block md:pt-12  bd-show">
                    <Breadcrumb items={breadCrumbItem} current={slugToText(slug2)}/>
                </div> : null}

            </Container>
            {match.params.type === 'free' ? (
                <FreeUsers data={location?.state?.selected} slug={slug1}/>
            ) : (
                <PaidUserNotes data={location?.state?.selected} slug={slug2} studymodule={slug1}/>
            )}
        </div>
    );
};

export default PreviewNotes;
