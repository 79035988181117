export const closeAlert = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    return {
        type: 0,
    };
};

export const initialError = () => {
    return {
        type: 1,
    };
};

export const createErrorAlert = (status, title, message, statusTitle) => {
    return {
        type: status, title, message, status: statusTitle,
    };
};