import React, { useState, useEffect, useContext } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import {
  FaShoppingCart,
  FaEnvelope,
  FaBookOpen,
  FaUsers,
  FaSignOutAlt,
  FaChalkboardTeacher,
} from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { MdSpaceDashboard } from "react-icons/md";
import DefaultDp from "../../assets/images/defaultDp.webp";
import {
  getApiKey,
  getUser,
  removeCsrf,
  removeKey,
  removeUser,
} from "../../helpers";
import { useHistory, useLocation } from "react-router-dom";
import { Logout } from "../../services";
import { connect, useDispatch } from "react-redux";
import { createErrorAlert, deleteCsrf, deleteUser, getCatalogue } from "../../redux/actions";
import { catalogueData } from "../../redux/selectors";
import NavItemTooltip from "../InfoBox/NavItemTooltip.js";
import Context from "../../services/Context.js";
const SideNavComponent = ({ marginState, setMarginState, getCatalogue, dataCatalogue }) => {

  const [paidModules, setPaidModules] = useState([])

  const history = useHistory();
  const dispatch = useDispatch();
  const {value} = useContext(Context)
  const user = JSON.parse(getUser())

  const userCurrency = value?.userCurrency || user?.user_currency
  const userIsLaw = value?.is_law || user?.is_law

  useEffect(() => {
    for (let i = 0; i < value?.dataCatalogue?.length; i++) {
      if (value?.dataCatalogue[i]?.is_paid === true) {
        if (!paidModules.includes(value?.dataCatalogue[i]?.study_module?.slug)) {
          paidModules.push(value?.dataCatalogue[i]?.study_module?.slug);
        }
      }
    }
  }, [value?.dataCatalogue]);

  const logout = () => {
    Logout()
      .then(() => {
        removeCsrf();
        removeKey();
        removeUser();
        dispatch(deleteCsrf());
        dispatch(deleteUser());
      })
      .catch((error) => dispatch(createErrorAlert(2, "Error", "Try again", 2)));
      localStorage.clear()
      window.location.assign(`${window.location.origin}/login`);
  };

  const handleSelectedItem = (selected) => {
    if (selected === "join") {
      history.push("/content-creator");
    } else if (selected === "library") {
      history.push("/library");
    } else if (selected === "contact") {
      history.push("/contact-us");
    } else if (selected === "basket") {
      history.push("/shopping-basket");
    } else if (selected === "profile") {
      history.push("/profile");
    } else if (selected === "logout") {
      logout();
    } else if (selected === "dashboard") {
      history.push("/content-creator/my-dashboard");
    } else if (selected === "tutoring") {
      history.push("/boost-your-grades");
    } else if (selected === "study") {
      history.push("/");
    }
  };

  const handleToggle = (value) => {
    setMarginState(value);
  };

  const handleErrorImage = (e) => {
    e.target.src = DefaultDp;
  };

  return (
    <div className="w-16 md:w-20  xl:w-80 sideBarMain ml-3" style={{zIndex: "99999999"}}>
      <SideNav
        onSelect={(selected) => {
          handleSelectedItem(selected);
        }}
        onToggle={handleToggle}
        className="sidenavStyle"
        style={{zIndex: "99999999"}}
      >
        <SideNav.Toggle />
        <SideNav.Nav
          style={{ display: "flex", flexDirection: "column" }}
          className="logoutItem"
        >
          {user !== null && user?.author !== null && getApiKey() && (
            <NavItem eventKey="dashboard" className="navItem">
              <NavIcon>
                <MdSpaceDashboard
                  style={{ fontSize: "1.75em", margin: "13px 20px" }}
                />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text="Content Dashboard" />
              )}
              <NavText>Content Dashboard</NavText>
            </NavItem>
          )}
          <NavItem eventKey="join" className="navItem">
            <NavIcon>
              <FaUsers style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            {!marginState && (
              <NavItemTooltip text="Join Our Team" />
            )}
            <NavText>Join Our Team</NavText>
          </NavItem>
          {getApiKey() && paidModules.length > 0 && (
            <NavItem eventKey="library" className="navItem">
              <NavIcon>
                <FaBookOpen
                  style={{ fontSize: "1.75em", margin: "13px 20px" }}
                />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text="My Library" />
              )}
              <NavText>My Library</NavText>
            </NavItem>
          )}
          {userIsLaw === true && userCurrency === "UK" && <NavItem eventKey="tutoring" className="navItem">
            <NavIcon>
              <FaChalkboardTeacher
                style={{ fontSize: "1.75em", margin: "13px 20px" }}
              />
            </NavIcon>
            {!marginState && (
              <NavItemTooltip text="Tutoring" />
            )}
            <NavText>Tutoring</NavText>
          </NavItem>}
          
          {getApiKey() && (
            <NavItem eventKey="study" className="navItem">
              <NavIcon>
                <ImBooks style={{ fontSize: "1.75em", margin: "13px 20px" }} />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text="Study Materials" />
              )}
              <NavText>Study Materials</NavText>
            </NavItem>
          )}
          <NavItem eventKey="contact" className="navItem">
            <NavIcon>
              <FaEnvelope style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            {!marginState && (
              <NavItemTooltip text="Contact Us" />
            )}
            <NavText>Contact Us</NavText>
          </NavItem>
          {getApiKey() && (
            <NavItem eventKey="basket" className="navItem">
              <NavIcon>
                <FaShoppingCart
                  style={{ fontSize: "1.75em", margin: "13px 20px" }}
                />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text={user?.user_currency === "UK" ? "Basket" : "Cart"} />
              )}
              <NavText>{user?.user_currency === "UK" ? "Basket" : "Cart"}</NavText>
            </NavItem>
          )}
          {getApiKey() && (
            <NavItem eventKey="profile" className="sideBarProfileItem navItem">
              <NavIcon>
                <img
                  src={user?.image !== null ? user?.image : DefaultDp}
                  alt="Profile"
                  onError={handleErrorImage}
                  className="sideBarProfileImage"
                />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text="Profile" />
              )}
              <NavText>Profile</NavText>
            </NavItem>
          )}
          {getApiKey() && (
            <NavItem eventKey="logout" className="sideBarLogoutItem navItem">
              <NavIcon>
                <FaSignOutAlt
                  style={{ fontSize: "1.75em", margin: "13px 20px" }}
                />
              </NavIcon>
              {!marginState && (
                <NavItemTooltip text="Logout" />
              )}
              <NavText>Logout</NavText>
            </NavItem>
          )}
        </SideNav.Nav>
      </SideNav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavComponent);
