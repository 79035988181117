import apiRequest from "../../helpers/apiRequest";

export default async (value) => {
    return await apiRequest({
        method: 'post',
        url: `accounts/user/grades`,
        data: {
            'selected_value': value
        }
    })
}
