import * as t from '../types';
import {GetGroupParticipants, GetTutorGroupSessions} from '../../services'


export const getGroupParticipants = (slug, date) => async (dispatch) => {

    dispatch({
        type: t.GET_PARTICIPANTS_REQUEST
    });

    return await GetGroupParticipants(slug, date)

        .then((data) => {
            dispatch({
                type: t.GET_PARTICIPANTS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.GET_PARTICIPANTS_FAILED,
                payload: err.response
            });
        });
};
