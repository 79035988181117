import * as t from '../types';
import { GetCatalogue } from '../../services'

export const getCatalogue = (id) => async (dispatch) => {
    dispatch({
        type: t.GET_CATALOGUE_REQUEST
    });

    return await GetCatalogue(id)
        .then((data) => {
            dispatch({
                type: t.GET_CATALOGUE_SUCCESS,
                payload: data,
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CATALOGUE_FAILED,
                payload: err.response
            });
        });
};
