import * as t from '../types';
import {UpdateContentNote} from '../../services'


export const updateNoteAction = (id, formData) => async (dispatch) => {
    dispatch({
        type: t.UPDATE_CONTENT_NOTE_REQUEST
    });

    return await UpdateContentNote(id, formData)

        .then((data) => {
            dispatch({
                type: t.UPDATE_CONTENT_NOTE_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.UPDATE_CONTENT_NOTE_FAILED,
                payload: err.response
            });
        });
};
