import apiRequest from "../../helpers/apiRequest";

export default async (token) => {
    const res = await apiRequest({
        method: 'post',
        url: `accounts/rest-auth/google/`,
        data:{"access_token": token}
    });
    return res.data
}
