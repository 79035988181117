import * as t from '../types';

const initialState = {
    data: '', loading: false, error: ''
};

export function tutorSessionReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case t.GET_TUTOR_SESSION_REQUEST:
            return {
                ...state, loading: true
            };
        case t.GET_TUTOR_SESSION_SUCCESS:
            return {
                ...state, loading: false, data: payload.data
            };
        case t.GET_TUTOR_SESSION_FAILED:
            return {
                ...state, error: payload, loading: false
            };
        default:
            return state;
    }
}

const initialStateGroupSession = {
    data: '', loading: false, error: ''
};

export function tutorCreateGroupSessionReducer(state = initialStateGroupSession, action) {
    const {type, payload} = action;
    switch (type) {
        case t.CREATE_TUTOR_GROUP_SESSION_REQUEST:
            return {
                ...state, loading: true
            };
        case t.CREATE_TUTOR_GROUP_SESSION_SUCCESS:
            return {
                ...state, loading: false, data: payload.data
            };
        case t.CREATE_TUTOR_GROUP_SESSION_FAILED:
            return {
                ...state, error: payload, loading: false
            };
        default:
            return state;
    }
}

const initialStateOneToOneSession = {
    data: '', loading: false, error: ''
};

export function tutorCreateOneToOneSessionReducer(state = initialStateOneToOneSession, action) {
    const {type, payload} = action;
    switch (type) {
        case t.CREATE_TUTOR_ONE_TO_ONE_SESSION_REQUEST:
            return {
                ...state, loading: true
            };
        case t.CREATE_TUTOR_ONE_TO_ONE_SESSION_SUCCESS:
            return {
                ...state, loading: false, data: payload.data
            };
        case t.CREATE_TUTOR_ONE_TO_ONE_SESSION_FAILED:
            return {
                ...state, error: payload, loading: false
            };
        default:
            return state;
    }
}
