import * as t from '../types';
import {CreateEssay} from '../../services'


export const createEssayAction = (formData) => async (dispatch) => {
    dispatch({
        type: t.CREATE_ESSAY_REQUEST
    });

    return await CreateEssay(formData)

        .then((data) => {
            dispatch({
                type: t.CREATE_ESSAY_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            dispatch({
                type: t.CREATE_ESSAY_FAILED,
                payload: err.response
            });
        });
};
