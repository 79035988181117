import React from 'react'


const InfoBox = ({ text1, text2 }) => {
    return (
        <div className='realtive min-w-168 custom2:min-w-225 h-24 custom2:h-36  py-4 custom2:py-8 px-5 items-center bg-white text-15 custom2:text-body-large text-darkest rounded-xl' style={{ boxShadow: "0px 7px 25px rgba(0, 0, 0, 0.12)" }}>
            <p className="mb-4 custom2:mb-7 cursor-pointer">{text1}
            </p>
            <p className="cursor-pointer">{text2}</p>
        </div>
    )
}

export default InfoBox
