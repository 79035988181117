import * as t from '../types';
import {ConfirmAppointment} from '../../services'


export const confirmAppointmentAction = (obj) => async (dispatch) => {

    dispatch({
        type: t.POST_CONFIRM_APPOINTMENT_REQUEST
    });

    return await ConfirmAppointment(obj)
        .then((data) => {
            dispatch({
                type: t.POST_CONFIRM_APPOINTMENT_SUCCESS,
                payload: data
            });
            window.location.replace(data.data.checkout_url);
        })
        .catch((err) => {
            dispatch({
                type: t.POST_CONFIRM_APPOINTMENT_FAILED,
                payload: err.response
            });
            return err.response
        });
};
