export const createNoteLoading =  ({store})  => store.createNoteReducer.loading;

export const createNoteError =  ({store})  => store.createNoteReducer.error;

export const createNoteData =  ({store})  => store.createNoteReducer.data;


export const createEssayLoading =  ({store})  => store.createEssayReducer.loading;

export const createEssayError =  ({store})  => store.createEssayReducer.error;

export const createEssayData =  ({store})  => store.createEssayReducer.data;


export const createModuleLoading =  ({store})  => store.createModuleReducer.loading;

export const createModuleError =  ({store})  => store.createModuleReducer.error;

export const createModuleData =  ({store})  => store.createModuleReducer.data;


export const getContentEssayLoading =  ({store})  => store.getContentEssayReducer.loading;

export const getContentEssayError =  ({store})  => store.getContentEssayReducer.error;

export const getContentEssayData =  ({store})  => store.getContentEssayReducer.data;


export const getContentNoteLoading =  ({store})  => store.getContentNoteReducer.loading;

export const getContentNoteError =  ({store})  => store.getContentNoteReducer.error;

export const getContentNoteData =  ({store})  => store.getContentNoteReducer.data;


export const deleteContentNoteLoading =  ({store})  => store.deleteContentNoteReducer.loading;

export const deleteContentNoteError =  ({store})  => store.deleteContentNoteReducer.error;

export const deleteContentNoteData =  ({store})  => store.deleteContentNoteReducer.data;


export const deleteContentEssayLoading =  ({store})  => store.deleteContentEssayReducer.loading;

export const deleteContentEssayError =  ({store})  => store.deleteContentEssayReducer.error;

export const deleteContentEssayData =  ({store})  => store.deleteContentEssayReducer.data;


export const deleteContentModuleLoading =  ({store})  => store.deleteContentModuleReducer.loading;

export const deleteContentModuleError =  ({store})  => store.deleteContentModuleReducer.error;

export const deleteContentModuleData =  ({store})  => store.deleteContentModuleReducer.data;


export const updateContentNoteLoading =  ({store})  => store.updateContentNoteReducer.loading;

export const updateContentNoteError =  ({store})  => store.updateContentNoteReducer.error;

export const updateContentNoteData =  ({store})  => store.updateContentNoteReducer.data;


export const updateContentEssayLoading =  ({store})  => store.updateContentEssayReducer.loading;

export const updateContentEssayError =  ({store})  => store.updateContentEssayReducer.error;

export const updateContentEssayData =  ({store})  => store.updateContentEssayReducer.data;


export const updateContentModuleLoading =  ({store})  => store.updateContentModuleReducer.loading;

export const updateContentModuleError =  ({store})  => store.updateContentModuleReducer.error;

export const updateContentModuleData =  ({store})  => store.updateContentModuleReducer.data;


export const submitContentModuleLoading =  ({store})  => store.submitContentModuleReducer.loading;

export const submitContentModuleError =  ({store})  => store.submitContentModuleReducer.error;

export const submitContentModuleData =  ({store})  => store.submitContentModuleReducer.data;

