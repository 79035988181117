import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import Breadcrumb from "../../components/BreadCrumb";
import FacebookIcon from "../../assets/images/facebookIcon.png";
import TwitterIcon from "../../assets/images/twitterIcon.png";
import Video2Image from "../../assets/images/video2.webp";
import PlayButton from "../../assets/images/playButtonUnpaid.png";
import LazyImage from "../../components/LazyImage";

import { createErrorAlert, getTutorialVideo } from "../../redux/actions";
import {
  tutorialVideosData,
  tutorialVideosError,
  tutorialVideosLoading,
} from "../../redux/selectors";
import Pagination from "../../components/Pagination";
import FullLoader from "../../components/Loader/FullLoader";
import { getApiKey, getUser, slugToText } from "../../helpers";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";

const moment = require("moment");

const StudyMaterials = ({ data }) => {
  return (
    <div
      className="bg-white w-full h-full "
      style={{
        boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
        borderRadius: "25px",
        paddingBottom: "3rem",
      }}
    >
      <div
        className="relative tutorialImgWrap cursor-pointer"
        onClick={() => {
          window.open(data?.url, "_blank");
        }}
      >
        <LazyImage src={Video2Image} alt="image" />
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-70 custom2:w-100"
          src={PlayButton}
          alt="imagefb"
        />
      </div>
      <div className="p-6 ">
        <p className="font-bold md:text-h5 xl:text-h4">{data?.title}</p>
        <p
          className="custom2:text-16 xl:text-body-large  mt-5 mb-11"
          style={{ userSelect: "none" }}
        >
          {data?.caption}
        </p>
        <div
          className="flex justify-between items-center"
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: 0,
            padding: "1.5rem",
          }}
        >
          <p>{moment(data?.updated_at).format("MMMM Do, YYYY")}</p>
        </div>
      </div>
    </div>
  );
};

const TutorialVideoFreeUsers = ({ getTutorialVideo, loading, data }) => {
  const param = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(getUser());
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    currentPage: "Tutorial videos",
    pageState: {
      offset: 0,
      perPage: 3,
      currentPage: 1,
      pageCount: 0,
    },
    video: [],
    breadCrumbItem: [
      { title: "Get study modules", link: "/" },
      {
        title: slugToText(param.slug1),
        link: `/study-module/${param.slug1}`,
      },
    ],
  });

  useEffect(() => {
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Tutorial Videos please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
      setTimeout(() => {
        history.push("/");
      }, 9000);
    } else if (user?.user_type === "STUDENT") {
      if (
        user?.subscription_name === "Basic Subscription" &&
        user?.free_trial === false &&
        !location.state.paidModules.includes(param.slug1)
      ) {
        dispatch(
          createErrorAlert(
            203,
            "",
            "To view Tutorial Videos you must have a Premium subscription.",
            203
          )
        );
        setTimeout(() => {
          history.push("/");
        }, 9000);
      } else {
        getTutorialVideo(null, param.slug1);
      }
    } else {
      getTutorialVideo(null, param.slug1);
    }
  }, []);
  useEffect(() => {
    if (data?.results?.length) {
      state.pageState.pageCount = Math.ceil(
        data.count / state.pageState.perPage
      );
      setState((prev) => ({
        ...prev,
        video: data.results,
        pageState: state.pageState,
      }));
    }
  }, [data]);

  const pageHandler = (page) => {
    const offset = page.selected * state.pageState.perPage;
    state.pageState.currentPage = page.selected;
    state.pageState.offset = offset;
    setState((prev) => ({
      ...prev,
      pageState: state.pageState,
    }));
    getTutorialVideo(page.selected + 1, param.slug1);
  };

  return (
    <>
      {loading ? <FullLoader></FullLoader> : null}
      <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Simple Studying | {slugToText(param.slug1)} | Tutorial Videos
          </title>
          <meta name="description" content="Profile Description" />
          <link rel="canonical" href="http://localhost:3000/account/profile" />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Simple Studying | Profile" />
          <meta property="og:description" content="Profile Description" />
          <meta
            property="og:url"
            content="http://localhost:3000/account/profile"
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          <div className="py-2 md:py-8 lg:py-12">
            <Breadcrumb
              items={state.breadCrumbItem}
              current={state.currentPage}
            />
          </div>
          <p className="tutorialVidText text-36 font-bold">Tutorial videos</p>
          {data?.results?.length !== 0 ? (
            <div className="pt-12 grid md:grid-cols-2 lg:grid-cols-3 gap-9">
              {state?.video?.length > 0 &&
                state?.video?.map((item, index) => (
                  <div className="w-full relative">
                    <StudyMaterials data={item} />
                  </div>
                ))}
            </div>
          ) : (
            <p style={{ textAlign: "center", marginTop: "50px" }}>
              No Results Found
            </p>
          )}
          {data?.results?.length > 12 ? (
            <div className="pt-14 flex justify-center">
              <Pagination
                onPageChange={pageHandler}
                pageCount={state.pageState.pageCount}
              />
            </div>
          ) : null}

          <div className="pt-12 md:pt-18 lg:pt-24 pb-12 md:pb-18  lg:pb-24">
            <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20"></div>
          </div>
        </Container>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  error: tutorialVideosError(state),
  loading: tutorialVideosLoading(state),
  data: tutorialVideosData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getTutorialVideo: (page, slug) => dispatch(getTutorialVideo(page, slug)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TutorialVideoFreeUsers);
