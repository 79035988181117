import React, { useEffect, useState } from "react";
import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import FullLoader from "../../components/Loader/FullLoader";
import { apiRequest, getUser } from "../../helpers";
import DateRangeSelect from "../../pages/TutorDashboard/DateRangeSelect";
import { Modal } from "react-responsive-modal";

const ContentReceipt = () => {
  const [state, setState] = useState({
    user: JSON.parse(getUser()),
    modules: [],
    loading: false,
    sales: "",
    earnings: "",
    filteredModules: [],
    dateRangeFilter: false,
    dateValue: {
      startDate: new Date(),
      endDate: new Date().setMonth(11)
    },
    filterSet: false,
    maxDate: null
  });

  useEffect(() => {
    handleGetLibrary();
  }, []);

  const handleSelect = (start) => {
    filterByDateRange(start.start._d, start.end._d);
    setState((prev) => ({
      ...prev,
      filterSet: true
    }))
  };

  const filterByDateRange = (startDate, endDate) => {
    const filteredModules = state.modules.filter(item => {
      const itemDate = new Date(item.updated_at);
      return itemDate >= startDate && itemDate <= endDate;
    });
    setState((prev) => ({
      ...prev,
      filteredModules,
      dateRangeFilter: false
    }));
  };

  const handleCancelFilter = () => {
    setState((prev) => ({
      ...prev,
      filteredModules: state.modules,
      dateRangeFilter: false,
      filterSet: false
    }));
  };

  const handleGetLibrary = async () => {
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    await apiRequest({
      method: "get",
      url: `studies/dashboard/studymodules/`,
    }).then((res) => {
      if (res.status === 200) {
        let array = [];
        res.data.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            if (item.author === state.user.author) {
              array.push(item);
            }
          }
        });
        setState((prev) => ({
          ...prev,
          modules: array,
          filteredModules: array,
          loading: false,
        }));
      }
      const totalSales = state.modules.reduce(
        (acc, module) => acc + module.sales,
        0
      );
      const totalEarning = state.modules.reduce((acc, module) => acc + module.sales * module.price, 0);
      setState((prev) => ({
        ...prev,
        sales: totalSales,
        earnings: totalEarning
      }));
    });
  };
  return (
    <div className="bg-offWhiter">
      {state.loading && <FullLoader />}
      <Container>
        <div className="flex justify-between">
          <p className="text-h5 custom2:text-h3 text-primary font-bold mt-24 mb-9">
            My Receipts
          </p>
          <div className="flex space-x-4">
            <div className="w-36 h-12 mt-20">
              <Button filled onClick={() => setState((prev) => ({
                ...prev,
                dateRangeFilter: true
              }))}>
                <p className="text-center text-14 xl:text-body-large">Filter</p>
              </Button>
            </div>
            {state.filterSet && <div className="w-36 h-12 mt-20">
              <Button onClick={handleCancelFilter}>
                <p className="text-center text-14 xl:text-body-large">Cancel Filter</p>
              </Button>
            </div>}
            
          </div>
        </div>
        <div>
          <div className="flex space-x-8">
            <div className="bg-white w-80 h-20 rounded-md p-6">
              <div className="flex justify-between">
                <p className="text-16 font-bold">Total Study Modules</p>
                <p className="text-24 text-primary font-bold">
                  {state.filteredModules.length}
                </p>
              </div>
            </div>
            <div className="bg-white w-80 h-20 rounded-md p-6">
              <div className="flex justify-between">
                <div className="flex-col gap-y-2 ">
                  {" "}
                  <p className="text-xs font-bold">Total Sales</p>
                  <p className="text-11">from Jan 2024 to Dec 2024</p>
                </div>
                <p className="text-20 text-primary font-bold">{state.sales}</p>
              </div>
            </div>
            <div className="bg-white w-80 h-20 rounded-md p-6">
              <div className="flex justify-between">
                <div className="flex-col gap-y-2 ">
                  {" "}
                  <p className="text-xs font-bold">Total Earning</p>
                  <p className="text-11">from Jan 2024 to Dec 2024</p>
                </div>
                <p className="text-20 text-primary font-bold">£{state.earnings}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mb-12 lg:mb-20">
            <div className="overflow-x-auto min-w-full ">
              <div className="py-4 inline-block min-w-full w-full">
                <div
                  className="
                    overflow-y-hidden 
                    sm:overflow-x-scroll 
                    "
                >
                  <table className=" memberShipTable min-w-full text-center w-full">
                    <thead className="border-b border-t borderClr bg-white text-white bg-primary">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4"
                        >
                          Study Module
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4 "
                        >
                          Sold
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4"
                        >
                          Earning
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-2 lg:py-4"
                        >
                          Payout Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.filteredModules.map((item) => {
                        return (
                          <tr className="bg-white border-b borderClr">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {/* {headingHandle(user?.subscription_name)} */}
                              {item.title}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.price}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.sales !== null ? item.sales : 0}
                            </td>
                            <td className="text-sm  font-light px-6 py-4 whitespace-nowrap ">
                              {item.sales * item.price}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {new Date(item.updated_at).toLocaleDateString(
                                "en-UK",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                              {item.is_paid ? item.is_paid === true ? 'Completed' : 'Pending' : 'N/A'}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col w-full ">
                <div className=" min-w-full ">
                  <div className="py-4 inline-block min-w-full w-full">
                    <div className="overflow-y-hidden	sm:overflow-x-scroll xl:overflow-x-hidden">
                      <table className=" memberShipTable min-w-full text-center w-full">
                        <thead className="border-b border-t bg-white text-white bg-primary borderClr">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Invoice #
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Membership
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Amount
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Payment Status
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium  px-4 py-4"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) => {
                            return (
                              <tr className="bg-white border-b borderClr	">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item?.invoice?.id}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {handlePastHeading(item?.plan)}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  £ {item?.invoice?.total / 100}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                                  {item?.invoice?.status}
                                </td>
                                <td className="text-sm  font-light px-4 py-4 whitespace-nowrap ">
                                  {item?.created_at}
                                </td>
                                <td className="text-sm  font-light px-4  py-4 xl:whitespace-nowrap ">
                                  <button
                                    className="border-b border-t bg-white text-white bg-primary borderClr"
                                    style={{
                                      cursor: "pointer",
                                      borderRadius: "0px",
                                      padding: "0 20px 0 20px",
                                    }}
                                    onClick={() => modalView(item)}
                                  >
                                    View Receipt
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}
        </div>
      </Container>
      <Modal
        open={state.dateRangeFilter}
        onClose={() => setState((prev) => ({
          ...prev,
          dateRangeFilter: false
        }))}
        focusTrapped={false}
        center
        classNames={{
          overlay: 'custom-overlay-class',
          modal: 'react-modal-class2',
        }}
      >
        <div className="justify-center flex">
          <DateRangeSelect onChange={handleSelect} maxDate={state.maxDate} value={state.dateValue} />
        </div>
      </Modal>
    </div>
  );
};

export default ContentReceipt;
