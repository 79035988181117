import * as t from '../types';
import { GetQuizResults } from '../../services';

export const quizResults = (single, multi, matches, quiz_ids) => async (dispatch) => {

    dispatch({
        type: t.GET_QUIZ_RESULTS_REQUEST,
    });

    return await GetQuizResults(single, multi, matches, quiz_ids)

        .then((data) => {
            dispatch({
                type: t.GET_QUIZ_RESULTS_SUCCESS,
                payload: data,
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_QUIZ_RESULTS_FAILED,
                payload: err.response,
            });
        });
};
