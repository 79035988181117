import React from 'react'

import BoxArrow from '../../assets/icons/box-arrow.svg'

const InfoBox = ({ text }) => {
    return (
        <div className='toolTipSubSec realtive w-64 h-auto p-6 bg-white text-small-text text-darkGrey rounded-xl' style={{boxShadow: "0px 7px 25px rgba(0, 0, 0, 0.12)"}}>
            <div className='absolute -top-2 h-10 w-10'>
                <img src={BoxArrow} alt='box'/>
            </div>
            <div dangerouslySetInnerHTML={{__html: text}} className='qblockBox'></div>
        </div>
    )
}

export default InfoBox
