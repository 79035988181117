import apiRequest from "../../helpers/apiRequest";

export default async (body) => {
    
        return await apiRequest({
            method: 'post',
            url: `content/create/slots/`,
            data: body
        })
}
