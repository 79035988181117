import * as t from '../types';
import { CheckTimeSlot } from '../../services'
import { createErrorAlert } from './errorAlertAction';

export const checkTimeAction = (user, date) => async (dispatch) => {
    
    dispatch({
        type: t.GET_CHECK_TIME_REQUEST
    });

    return await CheckTimeSlot(user, date)
        .then((data) => {
            dispatch({
                type: t.GET_CHECK_TIME_SUCCESS,
                payload: data.data,
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CHECK_TIME_FAILED,
                payload: err.response
            });
            return err
        });
};
