import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { teamData } from "../../redux/selectors";
import { getTeam } from "../../redux/actions/teamAction";
import { connect } from "react-redux";

const AboutUs = ({ getTeam, teamData }) => {

    useEffect(() => {
        timeLine()
        getTeam()
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        if (teamData.length) {
        }
    }, [teamData])

    const timeLine = () => {
        "use strict";

        var items = document.querySelectorAll(".timeline li");

        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    items[i].classList.add("in-view");
                }
            }
        }

        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    };


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Simple Studying | About Us</title>
                <meta name="description" content="About Us Description" />
                <link rel="canonical" href="https://simplestudying.com/about-us" />
                <meta name="robots" content="index, follow" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:locale:alternate" content="en_gb" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Simple Studying | About Us" />
                <meta property="og:description" content="About Us Description" />
                <meta property="og:url" content="https://simplestudying.com/about-us" />
                <meta property="og:site_name" content="Simple Studying" />
            </Helmet>
            <div className="my-4 sm:my-8 md:my-10 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="ourStorytext tipsHeadText text-large-small font-bold text-darkest md:text-large-medium">
                        Our Story
                    </div>
                    <div
                        className=" bg-subscription-upper bg-white bg-contain bg-no-repeat md:bg-offWhite pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
                        <p className="aboutUsCont" style={{ fontSize: "20px", marginTop: "-30px" }}>
                            In the US and UK, 40-45% of undergraduate university students drop out, losing hundreds of thousands of dollars in tuition fees and associated costs. Additionally, 20-40% of graduates in both countries struggle to build high-income, successful careers. We address this problem with AI, aiming to reduce the high dropout rate and help graduates build successful, high-income careers. <br />
                            <br />
                            Our story began at City University of London in the UK, where our founder studied. After witnessing the majority of her classmates drop out of university and lose hundreds of thousands of dollars, she became determined to fix the broken system. During her time at university, she closely observed the problems students faced and started building SimpleStudying after she graduated.<br />
                            <br />
                        </p>
                    </div>
                    <br />
                </div>
            </div>
            {/* our portfolio sec  */}
            <div className="portfolioSec my-12 px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                <section class="text-gray-600 body-font overflow-hidden">
                    <div class="container px-5 mx-auto">
                        <div class="flex flex-wrap -m-12">
                            <div class="p-12 md:w-1/2  portLeft bg-white py-10 md:py-18 lg:py-28 px-12 md:px-18 lg:px-24 ">
                                <div class="portLeftSubSec flex items-start gap-5 mb-8">
                                    <div className="portLLeft">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Capa_1"
                                            height="512"
                                            viewBox="0 0 512 512"
                                            width="512"
                                        >
                                            <g>
                                                <path
                                                    d="m178.352 125.253h59.436c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-59.436c-4.598 0-8.914-1.789-12.16-5.042-3.244-3.244-5.031-7.562-5.031-12.16 0-9.485 7.712-17.203 17.191-17.203h13.519c4.142 0 7.499-3.357 7.5-7.499.001-9.981 8.123-18.102 18.105-18.102.424 0 .871.018 1.367.055 3.002.219 5.831-1.36 7.214-4.023 8.295-15.972 24.614-25.894 42.59-25.894 26.453 0 47.974 21.516 47.974 47.962 0 4.142 3.357 7.5 7.5 7.5h9.518c4.598 0 8.914 1.789 12.159 5.042 3.252 3.252 5.043 7.57 5.043 12.161 0 9.485-7.717 17.202-17.202 17.202h-60.851c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h60.851c17.756 0 32.202-14.446 32.202-32.202 0-8.597-3.351-16.682-9.43-22.761-6.074-6.089-14.161-9.442-22.772-9.442h-2.462c-3.719-31.197-30.343-55.462-62.529-55.462-22.045 0-42.19 11.365-53.621 29.951-14.58 1.072-26.567 11.631-29.798 25.511h-6.876c-17.75 0-32.191 14.446-32.191 32.203 0 8.604 3.348 16.69 9.419 22.76 6.072 6.09 14.159 9.443 22.771 9.443z"></path>
                                                <path
                                                    d="m301.847 358.308-14.49-8.367c-9.715-5.608-22.179-2.267-27.785 7.446l-49.597 85.904c-4.638 8.032-7.979 23.632-9.935 46.365-.523 6.094 2.443 11.781 7.744 14.841 2.45 1.415 5.139 2.117 7.816 2.117 3.113 0 6.212-.949 8.909-2.832 18.711-13.061 30.55-23.755 35.186-31.786l49.597-85.904c2.717-4.706 3.438-10.187 2.032-15.435s-4.772-9.633-9.477-12.349zm-21.991 4.624 14.49 8.366c1.235.713 2.119 1.865 2.488 3.243.369 1.377.18 2.816-.533 4.052l-5.9 10.219-23.74-13.706 5.9-10.219c1.473-2.55 4.747-3.426 7.295-1.955zm-28.444 93.413-23.74-13.706 31.49-54.543 23.74 13.706zm-35.487 35.137c-.132.092-.306.217-.641.024-.331-.191-.313-.408-.299-.566 1.314-15.285 3.366-27.51 5.803-34.956l22.507 12.994c-5.23 5.834-14.79 13.723-27.37 22.504z"></path>
                                                <path
                                                    d="m128.16 351.429h-105.357c-12.574 0-22.803 10.23-22.803 22.803v105.357c0 12.574 10.229 22.803 22.803 22.803h105.357c12.573 0 22.803-10.229 22.803-22.803v-105.357c0-12.573-10.23-22.803-22.803-22.803zm7.803 128.16c0 4.303-3.5 7.803-7.803 7.803h-105.357c-4.303 0-7.803-3.5-7.803-7.803v-105.357c0-4.302 3.5-7.803 7.803-7.803h105.357c4.303 0 7.803 3.5 7.803 7.803z"></path>
                                                <path
                                                    d="m105.251 414.318-44.552-27.45c-3.731-2.3-8.428-2.4-12.255-.264-3.827 2.137-6.205 6.188-6.205 10.572v54.902c0 4.384 2.378 8.435 6.205 10.572 1.835 1.024 3.87 1.535 5.902 1.535 2.206 0 4.41-.602 6.353-1.798l44.553-27.451c3.604-2.221 5.755-6.075 5.755-10.309 0-4.233-2.152-8.087-5.756-10.309zm-48.012 32.582v-44.545l36.147 22.272z"></path>
                                                <path
                                                    d="m496.357 349.408h-101.864c-8.625 0-15.643 7.017-15.643 15.642v18.21c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-18.21c0-.354.288-.642.643-.642h101.864c.354 0 .643.288.643.642v123.72c0 .354-.288.642-.643.642h-101.864c-.354 0-.643-.288-.643-.642v-70.51c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v70.51c0 8.625 7.018 15.642 15.643 15.642h101.864c8.625 0 15.643-7.017 15.643-15.642v-123.72c0-8.625-7.018-15.642-15.643-15.642z"></path>
                                                <path
                                                    d="m419.615 400.339h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 434.411h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 468.481h20.103c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-20.103c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m253.819 195.186c-2.787.522-5.229 2.736-5.89 5.89-.891 4.764 2.687 8.96 7.36 8.96 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3s-.8-3.91-2.2-5.3c-1.739-1.74-4.329-2.54-6.77-2.05z"></path>
                                                <path
                                                    d="m255.29 165.226c-4.141 0-7.5 3.36-7.5 7.51 0 4.14 3.359 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.15-3.36-7.51-7.5-7.51z"></path>
                                                <path
                                                    d="m260.59 137.626c-1.74-1.74-4.33-2.54-6.771-2.05-2.789.525-5.214 2.723-5.89 5.88-1.391 7.585 7.643 11.824 12.66 6.77 2.961-2.939 2.894-7.787.001-10.6z"></path>
                                                <path
                                                    d="m65.148 313.189c4.143 0 7.5-3.358 7.5-7.5v-39.498c0-1.378 1.121-2.5 2.5-2.5h153.709c2.595 9.13 9.799 16.334 18.929 18.929v23.069c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-23.069c9.13-2.595 16.334-9.798 18.928-18.928h153.709c1.379 0 2.5 1.122 2.5 2.5v39.498c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.498c0-9.649-7.851-17.5-17.5-17.5h-153.707c-3.273-11.516-13.877-19.978-26.428-19.978s-23.156 8.462-26.429 19.978h-153.711c-9.649 0-17.5 7.851-17.5 17.5v39.498c0 4.141 3.358 7.499 7.5 7.499zm190.139-69.476c6.88 0 12.478 5.598 12.478 12.478s-5.598 12.478-12.478 12.478c-6.881 0-12.479-5.597-12.479-12.478s5.598-12.478 12.479-12.478z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="portLRight">
                                        <h2 class="sm:text-3xl text-2xl title-font text-gray-900 mt-4 mb-2">
                                            Technology
                                        </h2>
                                        <p class="leading-relaxed mb-8">
                                            Technology where students can find
                                            any specific information they need
                                            in seconds instead of reading
                                            thousands of pages in dozens of
                                            complicated textbooks.
                                        </p>
                                    </div>
                                </div>
                                <div class="portLeftSubSec flex items-start gap-5 mb-8">
                                    <div className="portLLeft">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Capa_1"
                                            height="512"
                                            viewBox="0 0 512 512"
                                            width="512"
                                        >
                                            <g>
                                                <path
                                                    d="m178.352 125.253h59.436c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-59.436c-4.598 0-8.914-1.789-12.16-5.042-3.244-3.244-5.031-7.562-5.031-12.16 0-9.485 7.712-17.203 17.191-17.203h13.519c4.142 0 7.499-3.357 7.5-7.499.001-9.981 8.123-18.102 18.105-18.102.424 0 .871.018 1.367.055 3.002.219 5.831-1.36 7.214-4.023 8.295-15.972 24.614-25.894 42.59-25.894 26.453 0 47.974 21.516 47.974 47.962 0 4.142 3.357 7.5 7.5 7.5h9.518c4.598 0 8.914 1.789 12.159 5.042 3.252 3.252 5.043 7.57 5.043 12.161 0 9.485-7.717 17.202-17.202 17.202h-60.851c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h60.851c17.756 0 32.202-14.446 32.202-32.202 0-8.597-3.351-16.682-9.43-22.761-6.074-6.089-14.161-9.442-22.772-9.442h-2.462c-3.719-31.197-30.343-55.462-62.529-55.462-22.045 0-42.19 11.365-53.621 29.951-14.58 1.072-26.567 11.631-29.798 25.511h-6.876c-17.75 0-32.191 14.446-32.191 32.203 0 8.604 3.348 16.69 9.419 22.76 6.072 6.09 14.159 9.443 22.771 9.443z"></path>
                                                <path
                                                    d="m301.847 358.308-14.49-8.367c-9.715-5.608-22.179-2.267-27.785 7.446l-49.597 85.904c-4.638 8.032-7.979 23.632-9.935 46.365-.523 6.094 2.443 11.781 7.744 14.841 2.45 1.415 5.139 2.117 7.816 2.117 3.113 0 6.212-.949 8.909-2.832 18.711-13.061 30.55-23.755 35.186-31.786l49.597-85.904c2.717-4.706 3.438-10.187 2.032-15.435s-4.772-9.633-9.477-12.349zm-21.991 4.624 14.49 8.366c1.235.713 2.119 1.865 2.488 3.243.369 1.377.18 2.816-.533 4.052l-5.9 10.219-23.74-13.706 5.9-10.219c1.473-2.55 4.747-3.426 7.295-1.955zm-28.444 93.413-23.74-13.706 31.49-54.543 23.74 13.706zm-35.487 35.137c-.132.092-.306.217-.641.024-.331-.191-.313-.408-.299-.566 1.314-15.285 3.366-27.51 5.803-34.956l22.507 12.994c-5.23 5.834-14.79 13.723-27.37 22.504z"></path>
                                                <path
                                                    d="m128.16 351.429h-105.357c-12.574 0-22.803 10.23-22.803 22.803v105.357c0 12.574 10.229 22.803 22.803 22.803h105.357c12.573 0 22.803-10.229 22.803-22.803v-105.357c0-12.573-10.23-22.803-22.803-22.803zm7.803 128.16c0 4.303-3.5 7.803-7.803 7.803h-105.357c-4.303 0-7.803-3.5-7.803-7.803v-105.357c0-4.302 3.5-7.803 7.803-7.803h105.357c4.303 0 7.803 3.5 7.803 7.803z"></path>
                                                <path
                                                    d="m105.251 414.318-44.552-27.45c-3.731-2.3-8.428-2.4-12.255-.264-3.827 2.137-6.205 6.188-6.205 10.572v54.902c0 4.384 2.378 8.435 6.205 10.572 1.835 1.024 3.87 1.535 5.902 1.535 2.206 0 4.41-.602 6.353-1.798l44.553-27.451c3.604-2.221 5.755-6.075 5.755-10.309 0-4.233-2.152-8.087-5.756-10.309zm-48.012 32.582v-44.545l36.147 22.272z"></path>
                                                <path
                                                    d="m496.357 349.408h-101.864c-8.625 0-15.643 7.017-15.643 15.642v18.21c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-18.21c0-.354.288-.642.643-.642h101.864c.354 0 .643.288.643.642v123.72c0 .354-.288.642-.643.642h-101.864c-.354 0-.643-.288-.643-.642v-70.51c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v70.51c0 8.625 7.018 15.642 15.643 15.642h101.864c8.625 0 15.643-7.017 15.643-15.642v-123.72c0-8.625-7.018-15.642-15.643-15.642z"></path>
                                                <path
                                                    d="m419.615 400.339h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 434.411h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 468.481h20.103c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-20.103c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m253.819 195.186c-2.787.522-5.229 2.736-5.89 5.89-.891 4.764 2.687 8.96 7.36 8.96 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3s-.8-3.91-2.2-5.3c-1.739-1.74-4.329-2.54-6.77-2.05z"></path>
                                                <path
                                                    d="m255.29 165.226c-4.141 0-7.5 3.36-7.5 7.51 0 4.14 3.359 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.15-3.36-7.51-7.5-7.51z"></path>
                                                <path
                                                    d="m260.59 137.626c-1.74-1.74-4.33-2.54-6.771-2.05-2.789.525-5.214 2.723-5.89 5.88-1.391 7.585 7.643 11.824 12.66 6.77 2.961-2.939 2.894-7.787.001-10.6z"></path>
                                                <path
                                                    d="m65.148 313.189c4.143 0 7.5-3.358 7.5-7.5v-39.498c0-1.378 1.121-2.5 2.5-2.5h153.709c2.595 9.13 9.799 16.334 18.929 18.929v23.069c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-23.069c9.13-2.595 16.334-9.798 18.928-18.928h153.709c1.379 0 2.5 1.122 2.5 2.5v39.498c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.498c0-9.649-7.851-17.5-17.5-17.5h-153.707c-3.273-11.516-13.877-19.978-26.428-19.978s-23.156 8.462-26.429 19.978h-153.711c-9.649 0-17.5 7.851-17.5 17.5v39.498c0 4.141 3.358 7.499 7.5 7.499zm190.139-69.476c6.88 0 12.478 5.598 12.478 12.478s-5.598 12.478-12.478 12.478c-6.881 0-12.479-5.597-12.479-12.478s5.598-12.478 12.479-12.478z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="portLRight">
                                        <h2 class="sm:text-3xl text-2xl title-font text-gray-900 mt-4 mb-2">
                                            High Quality Content
                                        </h2>
                                        <p class="leading-relaxed mb-8">
                                            All our study materials are created
                                            by successful students and graduates
                                            who have achieved at least upper
                                            second or 1st Class grades.
                                        </p>
                                    </div>
                                </div>
                                <div class="portLeftSubSec flex items-start gap-5 mb-8">
                                    <div className="portLLeft">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            id="Capa_1"
                                            height="512"
                                            viewBox="0 0 512 512"
                                            width="512"
                                        >
                                            <g>
                                                <path
                                                    d="m178.352 125.253h59.436c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-59.436c-4.598 0-8.914-1.789-12.16-5.042-3.244-3.244-5.031-7.562-5.031-12.16 0-9.485 7.712-17.203 17.191-17.203h13.519c4.142 0 7.499-3.357 7.5-7.499.001-9.981 8.123-18.102 18.105-18.102.424 0 .871.018 1.367.055 3.002.219 5.831-1.36 7.214-4.023 8.295-15.972 24.614-25.894 42.59-25.894 26.453 0 47.974 21.516 47.974 47.962 0 4.142 3.357 7.5 7.5 7.5h9.518c4.598 0 8.914 1.789 12.159 5.042 3.252 3.252 5.043 7.57 5.043 12.161 0 9.485-7.717 17.202-17.202 17.202h-60.851c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h60.851c17.756 0 32.202-14.446 32.202-32.202 0-8.597-3.351-16.682-9.43-22.761-6.074-6.089-14.161-9.442-22.772-9.442h-2.462c-3.719-31.197-30.343-55.462-62.529-55.462-22.045 0-42.19 11.365-53.621 29.951-14.58 1.072-26.567 11.631-29.798 25.511h-6.876c-17.75 0-32.191 14.446-32.191 32.203 0 8.604 3.348 16.69 9.419 22.76 6.072 6.09 14.159 9.443 22.771 9.443z"></path>
                                                <path
                                                    d="m301.847 358.308-14.49-8.367c-9.715-5.608-22.179-2.267-27.785 7.446l-49.597 85.904c-4.638 8.032-7.979 23.632-9.935 46.365-.523 6.094 2.443 11.781 7.744 14.841 2.45 1.415 5.139 2.117 7.816 2.117 3.113 0 6.212-.949 8.909-2.832 18.711-13.061 30.55-23.755 35.186-31.786l49.597-85.904c2.717-4.706 3.438-10.187 2.032-15.435s-4.772-9.633-9.477-12.349zm-21.991 4.624 14.49 8.366c1.235.713 2.119 1.865 2.488 3.243.369 1.377.18 2.816-.533 4.052l-5.9 10.219-23.74-13.706 5.9-10.219c1.473-2.55 4.747-3.426 7.295-1.955zm-28.444 93.413-23.74-13.706 31.49-54.543 23.74 13.706zm-35.487 35.137c-.132.092-.306.217-.641.024-.331-.191-.313-.408-.299-.566 1.314-15.285 3.366-27.51 5.803-34.956l22.507 12.994c-5.23 5.834-14.79 13.723-27.37 22.504z"></path>
                                                <path
                                                    d="m128.16 351.429h-105.357c-12.574 0-22.803 10.23-22.803 22.803v105.357c0 12.574 10.229 22.803 22.803 22.803h105.357c12.573 0 22.803-10.229 22.803-22.803v-105.357c0-12.573-10.23-22.803-22.803-22.803zm7.803 128.16c0 4.303-3.5 7.803-7.803 7.803h-105.357c-4.303 0-7.803-3.5-7.803-7.803v-105.357c0-4.302 3.5-7.803 7.803-7.803h105.357c4.303 0 7.803 3.5 7.803 7.803z"></path>
                                                <path
                                                    d="m105.251 414.318-44.552-27.45c-3.731-2.3-8.428-2.4-12.255-.264-3.827 2.137-6.205 6.188-6.205 10.572v54.902c0 4.384 2.378 8.435 6.205 10.572 1.835 1.024 3.87 1.535 5.902 1.535 2.206 0 4.41-.602 6.353-1.798l44.553-27.451c3.604-2.221 5.755-6.075 5.755-10.309 0-4.233-2.152-8.087-5.756-10.309zm-48.012 32.582v-44.545l36.147 22.272z"></path>
                                                <path
                                                    d="m496.357 349.408h-101.864c-8.625 0-15.643 7.017-15.643 15.642v18.21c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-18.21c0-.354.288-.642.643-.642h101.864c.354 0 .643.288.643.642v123.72c0 .354-.288.642-.643.642h-101.864c-.354 0-.643-.288-.643-.642v-70.51c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v70.51c0 8.625 7.018 15.642 15.643 15.642h101.864c8.625 0 15.643-7.017 15.643-15.642v-123.72c0-8.625-7.018-15.642-15.643-15.642z"></path>
                                                <path
                                                    d="m419.615 400.339h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 434.411h51.62c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-51.62c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m419.615 468.481h20.103c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-20.103c-4.143 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5z"></path>
                                                <path
                                                    d="m253.819 195.186c-2.787.522-5.229 2.736-5.89 5.89-.891 4.764 2.687 8.96 7.36 8.96 1.97 0 3.91-.8 5.3-2.2 1.4-1.39 2.2-3.33 2.2-5.3s-.8-3.91-2.2-5.3c-1.739-1.74-4.329-2.54-6.77-2.05z"></path>
                                                <path
                                                    d="m255.29 165.226c-4.141 0-7.5 3.36-7.5 7.51 0 4.14 3.359 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.15-3.36-7.51-7.5-7.51z"></path>
                                                <path
                                                    d="m260.59 137.626c-1.74-1.74-4.33-2.54-6.771-2.05-2.789.525-5.214 2.723-5.89 5.88-1.391 7.585 7.643 11.824 12.66 6.77 2.961-2.939 2.894-7.787.001-10.6z"></path>
                                                <path
                                                    d="m65.148 313.189c4.143 0 7.5-3.358 7.5-7.5v-39.498c0-1.378 1.121-2.5 2.5-2.5h153.709c2.595 9.13 9.799 16.334 18.929 18.929v23.069c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-23.069c9.13-2.595 16.334-9.798 18.928-18.928h153.709c1.379 0 2.5 1.122 2.5 2.5v39.498c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.498c0-9.649-7.851-17.5-17.5-17.5h-153.707c-3.273-11.516-13.877-19.978-26.428-19.978s-23.156 8.462-26.429 19.978h-153.711c-9.649 0-17.5 7.851-17.5 17.5v39.498c0 4.141 3.358 7.499 7.5 7.499zm190.139-69.476c6.88 0 12.478 5.598 12.478 12.478s-5.598 12.478-12.478 12.478c-6.881 0-12.479-5.597-12.479-12.478s5.598-12.478 12.479-12.478z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="portLRight">
                                        <h2 class="sm:text-3xl text-2xl title-font text-gray-900 mt-4 mb-2">
                                            All-In-One
                                        </h2>
                                        <p class="leading-relaxed mb-16">
                                            Advanced search, high quality
                                            content and a community to connect
                                            to millions of other students
                                            worldwide and study together.
                                        </p>
                                        <p class="leading-relaxed mb-8">
                                            We provide all in one platform where
                                            students find everything they need
                                            in one platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="p-12 md:w-1/2 flex flex-col items-start portRight  py-28 px-18">
                                <h2 class="sm:text-3xl text-2xl title-font font-medium text-gray-900 mt-4 mb-4 prHeading">
                                    What is SimpleStudying?
                                </h2>
                                <p class="leading-relaxed mb-8">
                                    So we started SimpleStudying to fix the
                                    broken industry!
                                </p>
                                <p class="leading-relaxed mb-10">
                                    Our digital platform is significantly
                                    decreasing the dropout rates and improve
                                    students’ grades in the UK universities.
                                </p>
                                <iframe
                                    class="elementor-video"
                                    frameborder="0"
                                    allowfullscreen="1"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    title="SimpleStudying explained"
                                    width="100%"
                                    height="360"
                                    src="https://www.youtube.com/embed/sIy7mQHG2Zs?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fsimplestudying.com&amp;widgetid=1"
                                    id="widget2"
                                ></iframe>

                                <div class=" text-white sm:mt-6 lg:mt-20 learnMoreBox ">
                                    <hr class="mb-2 md:mb-3 lg:mb-4" />
                                    <a
                                        href="#"
                                        class="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0 flex"
                                    >
                                        Learn More
                                        <svg
                                            class="w-8 h-4 ml-2"
                                            viewBox="0 0 28 28"
                                            stroke="currentColor"
                                            stroke-width="3"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path d="M5 12h14"></path>
                                            <path d="M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* portfolio end */}


            {/* how to create material  */}

            {/* meet our team  */}


            <div className="mt-10 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 bg-white pb-6 md:pb-14 lg:pb-20 ">
                    <div className="cMatrialText text-large-small text-center py-4 md:py-10 lg:py-16 font-bold text-darkest md:text-large-medium">
                        How We Create Materials

                    </div>

                    {/* time line  */}
                    <section class="timeline">
                        <ul>
                            <li class="timeBoxOne">
                                <div>
                                    Our team of content developers combine all of their study materials, do additional
                                    reading and produce condensed, simple materials within established format of
                                    SimpleStudying;
                                </div>
                            </li>
                            <li class="timeBoxTwo">
                                <div>
                                    We collaborate with current exceptionally talented law students with high 2:1 and
                                    1st class grades who further assist us to improve our materials and keep the content
                                    up to date

                                </div>
                            </li>
                            <li class="timeBoxThree">
                                <div>
                                    We compare our materials with the most recent lecture outlines, research any law
                                    reforms and update the content accordingly;


                                </div>
                            </li>
                            <li class="timeBoxFour">
                                <div>
                                    Result: simple, updated, high quality materials which includes all the necessary
                                    information to succeed in your exams. Generally, the content gets updated every
                                    couple of years. If there is an important change in curriculum, we update as soon as possible.

                                </div>
                            </li>
                        </ul>
                    </section>


                    {/* time line end */}

                </div>
            </div>


            {/* how to create material end  */}


            {/* Our values Sec  */}


            {/* our values section start  */}
            <div className="mt-8 md:mt-16 lg:mt-20 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="valueText text-large-small font-bold text-darkest md:text-large-medium text-center">
                        Our Values
                    </div>
                    <div
                        className=" overValMain bg-subscription-upper bg-white bg-contain bg-no-repeat md:bg-offWhite pt-6 md:pt-8 lg:pt-10 px-0 md:px-0 lg:px-0  xl:px-60">
                        <section
                            class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-6 md:mt-8 lg:mt-10 mb-5">
                            {/* <!--   ✅ box 1  - Starts Here 👇 --> */}
                            <div class="w-72">
                                <div class="px-4 py-3 w-72 text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Creativity
                                    </h1>
                                    <p class="valueP text-lg  block capitalize">
                                        Our team is constantly encouraged to
                                        innovate. We organise regular
                                        brainstorming sessions where we discuss
                                        new ideas to improve our strategies,
                                        product and services.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 2  - Starts Here 👇 --> */}
                            <div class="w-72 ">
                                <div class="px-4 py-3 w-72 text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">

                                    </h1>
                                    <p class=" valueP text-lg  block capitalize">
                                        On average, SimpleStudying saves students 800 hours of reading time per year compared to the textbooks. That's 2300 LESS pages to read per year.<br />
                                        <br />
                                        Students can use the time we save them to get work experience and be more employable.<br />
                                        <br />
                                        SimpleStudying premium is just £90/year instead of buying at least 4 textbooks/year students would need without SimpleStudying premium. 4 textbooks should cost students on average £160.<br />
                                        <br />
                                        SimpleStudying saves students at least £70/year and 800 hours not to mention maximising students' chances of getting high grades.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 3  - Starts Here 👇 --> */}
                            <div class="w-72  ">
                                <div class="px-4 py-3 w-72 text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Passion
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        We are passionate about our mission –
                                        bringing digital revolution to higher
                                        education to solve the high dropout
                                        rates and improve academic performance
                                        of students.
                                    </p>
                                </div>
                            </div>
                        </section>
                        {/* our value first row end */}

                        {/* our value second row start  */}

                        <section
                            class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 justify-items-center justify-center gap-y-20 gap-x-16 mt-6 md:mt-8 lg:mt-10 mb-5">
                            {/* <!--   ✅ box 1  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Fast is better than slow
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        Although we focus on quality, we always
                                        try to deliver our development projects
                                        as fast as possible while guaranteeing a
                                        high quality.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 2  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Quality
                                    </h1>
                                    <p class="text-lg  block capitalize">
                                        We aim to create the best quality
                                        content and technology to transform the
                                        higher education for good.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 3  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-4 py-2 w-full text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Customer Satisfaction
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        We believe that high customer
                                        satisfaction is key to building a
                                        successful business.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 4  - Starts Here 👇 --> */}
                            <div class="w-64  ">
                                <div class="px-2 py-3 w-full text-center valBox">
                                    <h1 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Diversity and Inclusion
                                    </h1>
                                    <p class="text-lg   block capitalize">
                                        We value talent from all over the world
                                        to bring the international minds
                                        together today to build the business of
                                        tomorrow.
                                    </p>
                                </div>
                            </div>
                        </section>

                        {/* our value second row end  */}
                    </div>
                    <br />
                </div>
            </div>
            {/* Our values Sec end  */}
            {/* Digital Platform sec */}
            <div className="mt-14 md:mt-22 lg:mt-28  digitalMain">
                <div className=" px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 py-4 md:py-10 lg:py-16 ">
                    <div className=" overValMain  pt-10 px-0 md:px-0 lg:px-0  xl:px-60">
                        <section
                            class="w-fit mx-auto grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 justify-items-center justify-start md:gap-y-20 md:gap-x-14 mt-10 mb-5">
                            {/* <!--   ✅ box 1  - Starts Here 👇 --> */}
                            <div class="w-12/12	  ">
                                <div class="px-4 py-3 w-full  valBox digBoxLeft">
                                    <h1 class="digiH1Text text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">
                                        Why do we need a digital platform for
                                        students?
                                    </h1>
                                    <p class="text-lg  block capitalize">
                                        Currently, students are required to
                                        research in many different books and
                                        read thousands of pages to be able to
                                        find information they need to
                                        successfully pass their exams. There is
                                        no way to find the needed information
                                        fast. After interviewing over 200
                                        students, we have estimated that
                                        students spend around 800 hours per
                                        academic year for searching information.
                                        How this was calculated:
                                    </p>
                                    <p class="text-lg   block capitalize">
                                        Students have on average 4-6 study
                                        modules to study per academic year:
                                    </p>

                                    <p class="text-lg   block capitalize">
                                        Students have around 500 pages to read
                                        per study module from where they have to
                                        gather the information they need to pass
                                        exams. Thus, 2000-3000 pages in total
                                        per academic year. From our research,
                                        students have noted that they can read
                                        3-5 pages in an hour (due to the
                                        textbooks being too complex). In total
                                        estimated 800 hours of research.
                                    </p>
                                </div>
                            </div>
                            {/* <!--   ✅ box 2  - Starts Here 👇 --> */}
                            <div class=" w-4/4 md:w-3/4	">
                                <div class="px-4 py-3 w-full  valBox digBoxRight">
                                    <h2 class="text-gray-400 font-bold text-xl mr-3 uppercase text-xs mb-4 text-darkest">

                                    </h2>
                                    <p class="text-lg  block capitalize">
                                        On average, SimpleStudying saves students 800 hours of reading time per year compared to the textbooks. That's 2300 LESS pages to read per year.<br />
                                        <br />
                                        Students can use the time we save them to get work experience and be more employable.<br />
                                        <br />
                                        SimpleStudying premium is just £90/year instead of buying at least 4 textbooks/year students would need without SimpleStudying premium. 4 textbooks should cost students on average £160.<br />
                                        <br />
                                        SimpleStudying saves students at least £70/year and 800 hours not to mention maximising students' chances of getting high grades.
                                    </p>
                                </div>
                            </div>
                        </section>
                        {/* our value first row end */}
                    </div>
                    <br />
                </div>
            </div>

            {/* Digital Platform sec end*/}


            {/* meet our team  */}


            <div className="mt-2 md:mt-4 lg:mt-6 bg-white">
                <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
                    <div className="meetTeamtext text-large-small text-center py-2 md:py-6 lg:py-10 font-bold text-darkest md:text-large-medium">
                        Meet the Team
                    </div>
                    <div class="container my-4 md:my-8 lg:my-12 mx-auto px-0 md:px-2">
                        <div class="flex flex-wrap -mx-1 lg:-mx-4 justify-between">

                            {/* <!-- Column --> */}
                            {teamData?.length ? teamData.map((item, index) => {
                                return (
                                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-3/3">

                                        {/* <!-- Article --> */}
                                        <article className="overflow-hidden rounded-lg shadow-lg bg-white">


                                            <header className="meetTeamHeader flex items-start justify-between leading-tight">
                                                <div className="md:w-2/4">

                                                    <img alt="Placeholder" className="block" src={item?.image} />

                                                </div>
                                                <div className="md:w-2/4 p-2 md:p-4 lg:p-8 lg:pb-0 md:pb-0 teamInfoSec">
                                                    <h3 className="employeName"> {item?.name}
                                                        <span className="block">{item?.designation}</span>
                                                    </h3>
                                                    <p className="text-grey-darker text-sm">

                                                        {item?.member_detail}

                                                    </p>
                                                </div>
                                            </header>


                                        </article>
                                        {/* <!-- END Article --> */}

                                    </div>
                                )
                            }) : null}
                        </div>
                    </div>

                </div>
            </div>


            {/* meet our team end */}
        </div>
    );
};
const mapStateToProps = (state) => ({
    teamData: teamData(state),
});

const mapDispatchToProps = (dispatch) => ({
    getTeam: () =>
        dispatch(getTeam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
