import React from 'react'

import Container from '../container'
import LazyImage from '../LazyImage'

import Logo from '../../assets/images/study_small.png'

const ContentCreatorStepper = ({ step, exit, totalSteps }) => {
    return (
        <Container>
            <div className='py-5 flex items-center'>
                <div className='md:mr-10 xl:mr-14 hidden md:block'>
                    {window.location.pathname !== '/tutoring/tutor-select/one-2-one' &&
                        window.location.pathname !== '/book-tutorials/appointment/one-2-one' &&
                        window.location.pathname !== '/book-tutorials/one-to-one-payment' && <LazyImage src={Logo} className="w-36 h-36 mt-8" alt='Logo' />}
                </div>
                <div className='flex flex-col w-full'>
                    <p className='mb-2 text-body-large'>
                        Step <span className='font-bold'>{step} of {totalSteps}</span>
                    </p>
                    <div className='w-full flex flex-row'>
                        {Array.from({ length: totalSteps }, (_, index) => (
                            <div
                                key={index}
                                className={`flex-1 h-1 rounded-sm ${index < step ? 'linear-background' : 'bg-lightGray'}`}
                            />
                        ))}
                    </div>
                </div>
                <div onClick={exit} className='ml-5 custom:ml-12 flex items-center'>
                    <p className='font-bold text-primary text-body-large cursor-pointer pt-6'>Exit</p>
                </div>
            </div>


        </Container>
    )
}

export default ContentCreatorStepper
