import React, { useState } from "react";
import Container from "../../components/container";
import facebook from "../../assets/images/facebook.png";
// import PayPal from '../../assets/images/paypal-logo-512x512.png'
import { Modal } from "react-responsive-modal";
import Input from "../../components/Input/InputForm";
import Button from "../../components/EmptyButton";
import { FaTrash, FaPlusCircle } from "react-icons/fa";
import { BsBank } from "react-icons/bs";

const PaymentMethod = () => {
  const [state, setState] = useState({
    paymentModal: false,
    username: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    methodName: "",
    checked: {
      first: true,
      second: false
    },
    methods: [{ username: "Umar", cardNumber: "1234567891234567" }],
  });

  const handleAddPayment = () => {
    state.methods.push({
      username: state.username,
      cardNumber: state.cardNumber,
      expiryDate: state.expiryDate,
      cvc: state.cvc,
    });
    setState((prev) => ({
      ...prev,
      paymentModal: false,
    }));
  };

  const handleDeletePayment = (index) => {
    state.methods.splice(index, 1);
  };

  const handleRadioChange = () => {
    if(state.checked.first === true){
      state.checked.first = false
      state.checked.second = true
    } else{
      state.checked.first = true
      state.checked.second = false
    }
  }

  return (
    <div className="bg-offWhiter">
      {/* {state.loading && <FullLoader />} */}
      <Container>
        <p className="text-h5 custom2:text-h3 text-primary font-bold mt-24 mb-2">
          Payment Method
        </p>
        <p className="">
          In order to be paid for your lessons, you must register a payment
          method.
        </p>
        <div className="p-8">
          {state.methods.length > 0 &&
            state.methods.map((item, index) => {
              let firstFour = item.cardNumber.slice(0, 4);
              let lastFour = item.cardNumber.substring(
                item.cardNumber.length - 4
              );
              let maskedString = firstFour + "************" + lastFour;
              return (
                <div className="bg-white flex rounded-md p-4 mt-4 justify-between">
                  <div className="flex">
                    {/* <img src={PayPal} className="w-10 h-10 mt-1" /> */}
                    <div className="ml-4">
                      <p className="font-bold">{item.username}</p>
                      <p className="text-8">{maskedString}</p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <FaTrash
                      className="trashcan"
                      onClick={() => handleDeletePayment(index)}
                    />
                  </div>
                </div>
              );
            })}
          <div
            className="flex rounded-md p-4 mt-4 justify-between cursor-pointer"
            onClick={() => {
              setState((prev) => ({
                ...prev,
                paymentModal: true,
              }));
            }}
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <div className="flex items-center">
              <BsBank className="w-10 h-10 bank-icon-border" />
              <div className="ml-4">
                <p className="font-bold">Add a method to collect payment</p>
              </div>
            </div>
            <div className="flex items-center">
              <FaPlusCircle className="trashcan" />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-md p-4">
          <p className="font-bold">I wish to be paid:</p>
          <div className="bg-primary rounded-md mt-4 p-4">
            <div className="flex items-center">
              <input type="radio" onChange={handleRadioChange} checked={state.checked.first}/>
              <p className="text-white ml-4">
                At the end of the month (as soon as my balance is more than £30)
              </p>
            </div>
          </div>
          <div
            className="rounded-md mt-4 p-4"
            style={{ backgroundColor: "#F5F5F5" }}
          >
            <div className="flex items-center">
              <input type="radio" onChange={handleRadioChange} checked={state.checked.second}/>
              <p className="ml-4">
                After each lesson (as soon as my balance is more than £30)
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        open={state.paymentModal}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            paymentModal: false,
          }))
        }
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class2",
        }}
      >
        <form className="md:flex  flex-col gap-x-6 gap-y-9">
          <div className="gap-x-1.5">
            <div className="flex gap-2 mb-3">
              <div className="w-1/2">
                <label className="mb-8 md:text-body-small">Username</label>
                <Input
                  type="text"
                  value={state.username}
                  placeholder="Enter card username"
                  setValue={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      username: value,
                    }));
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="mb-8 md:text-body-small">Card Number</label>
                <Input
                  type="number"
                  value={state.cardNumber}
                  pattern={"[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}"}
                  setValue={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      cardNumber: value,
                    }));
                  }}
                  placeholder="Enter card number"
                />
              </div>
            </div>
            <div className="flex gap-2 mb-3">
              <div className="w-1/2">
                <label className="mb-8 md:text-body-small">Expiry Date</label>
                <Input
                  type="date"
                  value={state.expiryDate}
                  setValue={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      expiryDate: value,
                    }));
                  }}
                />
              </div>
              <div className="w-1/2">
                <label className="mb-8 md:text-body-small">CVC</label>
                <Input
                  type="number"
                  value={state.cvc}
                  setValue={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      cvc: value,
                    }));
                  }}
                  placeholder="Enter CVC"
                />
              </div>
            </div>
          </div>
          <Button filled onClick={handleAddPayment}>
            <p>Add</p>
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default PaymentMethod;
