import * as t from '../types';
import {CancelSubscription} from '../../services'


export const cancelSubscriptionAction = (sub_name) => async (dispatch) => {
    dispatch({
        type: t.POST_CANCEL_SUBSCRIPTION_REQUEST
    });

    return await CancelSubscription(sub_name)
        .then((data) => {
            dispatch({
                type: t.POST_CANCEL_SUBSCRIPTION_SUCCESS,
                payload: data
            });
            return data
        })
        .catch((err) => {
            dispatch({
                type: t.POST_CANCEL_SUBSCRIPTION_FAILED,
                payload: err.response
            });
            return err.response
        });
};
