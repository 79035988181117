export default function timeCheck(e) {
  if (e === "01:00 PM") {
    e = "13:00 PM";
  } else if (e === "02:00 PM") {
    e = "14:00 PM";
  } else if (e === "03:00 PM") {
    e = "15:00 PM";
  } else if (e === "04:00 PM") {
    e = "16:00 PM";
  } else if (e === "05:00 PM") {
    e = "17:00 PM";
  } else if (e === "06:00 PM") {
    e = "18:00 PM";
  } else if (e === "07:00 PM") {
    e = "19:00 PM";
  } else if (e === "08:00 PM") {
    e = "20:00 PM";
  } else if (e === "09:00 PM") {
    e = "21:00 PM";
  } else if (e === "10:00 PM") {
    e = "22:00 PM";
  } else if (e === "11:00 PM") {
    e = "23:00 PM";
  } else if (e === "12:00 AM") {
    e = "00:00 AM";
  } else if (e === "01:00 AM") {
    e = "01:00 AM";
  } else if (e === "02:00 AM") {
    e = "02:00 AM";
  } else if (e === "03:00 AM") {
    e = "03:00 AM";
  } else if (e === "04:00 AM") {
    e = "04:00 AM";
  } else if (e === "05:00 AM") {
    e = "05:00 AM";
  } else if (e === "06:00 AM") {
    e = "06:00 AM";
  } else if (e === "07:00 AM") {
    e = "07:00 AM";
  } else if (e === "08:00 AM") {
    e = "08:00 AM";
  } else if (e === "09:00 AM") {
    e = "09:00 AM";
  } else if (e === "10:00 AM") {
    e = "10:00 AM";
  } else if (e === "11:00 AM") {
    e = "11:00 AM";
  } else if (e === "12:00 PM") {
    e = "12:00 PM";
  } else if (e === '01:30 PM') {
    e = '13:30 PM'
  } else if (e === '02:30 PM') {
    e = '14:30 PM'
  } else if (e === '03:30 PM') {
    e = '15:30 PM'
  } else if (e === '04:30 PM') {
    e = '16:30 PM'
  } else if (e === '05:30 PM') {
    e = '17:30 PM'
  } else if (e === '06:30 PM') {
    e = '18:30 PM'
  } else if (e === '07:30 PM') {
    e = '19:30 PM'
  } else if (e === '08:30 PM') {
    e = '20:30 PM'
  } else if (e === '09:30 PM') {
    e = '21:30 PM'
  } else if (e === '10:30 PM') {
    e = '22:30 PM'
  } else if (e === '11:30 PM') {
    e = '23:30 PM'
  } else if (e === '12:30 AM') {
    e = '00:30 AM'
  } else if (e === '01:30 AM') {
    e = '01:30 AM'
  } else if (e === '02:30 AM') {
    e = '02:30 AM'
  } else if (e === '03:30 AM') {
    e = '03:30 AM'
  } else if (e === '04:30 AM') {
    e = '04:30 AM'
  } else if (e === '05:30 AM') {
    e = '05:30 AM'
  } else if (e === '06:30 AM') {
    e = '06:30 AM'
  } else if (e === '07:30 AM') {
    e = '07:30 AM'
  } else if (e === '08:30 AM') {
    e = '08:30 AM'
  } else if (e === '09:30 AM') {
    e = '09:30 AM'
  } else if (e === '10:30 AM') {
    e = '10:30 AM'
  } else if (e === '11:30 AM') {
    e = '11:30 AM'
  } else if (e === '12:30 PM') {
    e = '12:30 PM'
  }
  return e;
}
